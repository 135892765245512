import { ReactElement } from 'react';
import { CourseChaptersData } from '../../models/absorb/course';
import { LessonsData } from '../../models/absorb/lessons';
import { LessonCard } from './LessonCard';

interface Props {
  courseChapter: CourseChaptersData;
  openPlayer: (id: string) => void;
}
export function ChapterView({ courseChapter, openPlayer }: Props): ReactElement {
  return (
    <>
      <div className="chapterView">
        {courseChapter.returnedItems > 0 && (
          <div className="chapterName" title={courseChapter.name}>
            {courseChapter.name}
          </div>
        )}

        {courseChapter.lessons.map((lesson: LessonsData) => (
          <LessonCard
            key={lesson.id}
            lesson={lesson}
            progress={lesson.enrollment?.progress || 0}
            openPlayer={openPlayer}
          />
        ))}
      </div>
    </>
  );
}

import { apiRequest } from '../utils/axios';
import { CatalogCourseData, CatalogCoursesData, CategoriesData } from '../models/absorb/catalog';
import { SearchFilterParams } from '../models/absorb/search';
import { formatUrl } from '../utils/helper';
import { COURSES_PER_PAGE } from '../constants/common';

export async function fetchMyCatalogCourses(params: SearchFilterParams, offset = 0): Promise<CatalogCoursesData> {
  const parameters = params;
  if (parameters.sort) {
    delete parameters.sort;
  }
  const url = formatUrl(`/my-catalog?_offset=${offset}`, parameters);
  return apiRequest('GET', url);
}

// export async function fetchMyCatalogTags(): Promise<TagData[]> {
//   return apiRequest('GET', '/my-catalog/tags?_limit=1000').then((data) => data.tags);
// }

export async function fetchMyCatalogCourse(courseId: string): Promise<CatalogCourseData> {
  return apiRequest('GET', `/my-catalog/${courseId}`);
}

export async function fetchMyCatalogCourseCategories(params: SearchFilterParams): Promise<CategoriesData[]> {
  const parameters = params;
  if (parameters.sort) {
    delete parameters.sort;
  }
  const url = formatUrl(`/my-catalog-course-categories?_limit=${COURSES_PER_PAGE}`, parameters);
  return apiRequest('GET', url).then((data) => data.categories);
}

import { ReactElement, useState, ChangeEvent, useEffect, FormEvent } from 'react';
import Avatar from '@mui/material/Avatar';
import { lightBlue } from '@mui/material/colors';
import { useTranslation } from 'react-i18next';
import { useProfileContext } from '../../contexts/profileContext';
import { useShoppingContext } from '../../contexts/shoppingContext';
import { CHECKOUT_ALLOWED } from '../../constants/common';
import { Spinner } from '../controls/Spinner';
import { HtmlDropdown } from '../controls/HtmlDropdown';
import { HtmlTextBox } from '../controls/HtmlTextBox';
import { CountryData } from '../../models/absorb/country';
import { ProvinceData } from '../../models/absorb/province';
import { BillingDetailsStateData } from '../../models/stripe/billingDetails';
import { fetchCountryList, fetchProvincesList } from '../../services/region';
import { errorHandler } from '../../utils/helper';
import { PageTitle } from '../global/PageTitle';
import './BillingDetails.css';

interface Props {
  goToNextStep: () => void;
  storeBillingDetails: (billingDetails: BillingDetailsStateData) => void;
}

export function BillingDetails({ goToNextStep, storeBillingDetails }: Props): ReactElement {
  const { t } = useTranslation();

  const { profile } = useProfileContext();
  const { billingDetailsState } = useShoppingContext();

  const [formValid, setFormValid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [countryData, setCountryData] = useState<CountryData[]>([]);
  const [provinceData, setProvinceData] = useState<ProvinceData[]>([]);

  const [billingDetailState, setBillingDetailState] = useState<BillingDetailsStateData>({
    firstName: `${billingDetailsState?.firstName}` || `${profile?.firstName}` || '',
    lastName: `${billingDetailsState?.lastName}` || `${profile?.lastName}` || '',
    email: `${billingDetailsState?.email}` || `${profile?.emailAddress}` || '',
    phone: `${billingDetailsState.phone}` || '',
    addressLine1: `${billingDetailsState.addressLine1}` || '',
    addressLine2: `${billingDetailsState.addressLine2}` || '',
    countryCode: `${billingDetailsState.countryCode}` || '',
    city: `${billingDetailsState.city}` || '',
    provinceCode: `${billingDetailsState.provinceCode}` || '',
    postalCode: `${billingDetailsState.postalCode}` || '',
  });

  useEffect(() => {
    setIsLoading(true);

    fetchCountryList()
      .then(setCountryData)
      .catch(errorHandler)
      .finally(() => setIsLoading(false));
  }, []);

  useEffect(() => {
    const billingDetailsObj = { ...billingDetailState };
    delete billingDetailsObj.addressLine2;
    const billingDetails = Object.entries(billingDetailsObj);
    const allInputsValid = billingDetails.every((item) => item[1]?.trim() !== '');
    setFormValid(allInputsValid);
  }, [billingDetailState]);

  useEffect(() => {
    if (billingDetailState.countryCode) {
      fetchProvincesList(billingDetailState.countryCode).then(setProvinceData).catch(errorHandler);
    }
  }, [billingDetailState.countryCode]);

  function onTextInputChange(e: ChangeEvent<HTMLInputElement>) {
    setBillingDetailState({ ...billingDetailState, [e.target.name]: e.target.value });
  }

  function onCountryChange(e: ChangeEvent<HTMLSelectElement>) {
    setBillingDetailState({ ...billingDetailState, [e.target.name]: e.target.value, provinceCode: '' });
  }

  function onProvinceChange(e: ChangeEvent<HTMLSelectElement>) {
    setBillingDetailState({ ...billingDetailState, [e.target.name]: e.target.value });
  }

  function onFormSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();
    storeBillingDetails(billingDetailState);
    goToNextStep();
  }

  const { firstName, lastName, phone, email, addressLine1, addressLine2, city, countryCode, provinceCode, postalCode } =
    billingDetailState;

  return (
    <div className="checkoutWrapper">
      <PageTitle title={t('ShippingInformation')} />
      {isLoading ? (
        <Spinner />
      ) : (
        <form onSubmit={onFormSubmit}>
          <div className="checkoutForm">
            <div className="checkoutFormPersonal">
              <div className="checkoutFormPersonalIntro">
                <Avatar sx={{ bgcolor: lightBlue[500], width: 24, height: 24 }}>1</Avatar>
                <div className="checkoutFormPersonalIntroText">What&#39;s your contact information?</div>
              </div>

              <div className="checkoutFormPersonalInfoNameFields">
                <div className="checkoutFormPersonalInfoNameFieldsFirst">
                  <HtmlTextBox
                    labelName="First name"
                    name="firstName"
                    value={firstName}
                    id="reduxFormInputField88"
                    errorId="errorDiv89"
                    required
                    halfSize
                    onChange={onTextInputChange}
                    autoComplete="given-name"
                  />
                </div>
                <div className="checkoutFormPersonalInfoNameFieldsLast">
                  <HtmlTextBox
                    labelName="Last name"
                    name="lastName"
                    value={lastName}
                    id="reduxFormInputField90"
                    errorId="errorDiv91"
                    required
                    halfSize
                    onChange={onTextInputChange}
                    autoComplete="family-name"
                  />
                </div>
              </div>
              <div className="clearFloat" />

              <HtmlTextBox
                labelName="Mobile number"
                name="phone"
                value={phone || '+1'}
                id="reduxFormInputField94"
                required
                halfSize={false}
                errorId="errorDiv95"
                onChange={onTextInputChange}
                autoComplete="tel-local"
              />

              <HtmlTextBox
                labelName="Email"
                name="email"
                value={email}
                id="reduxFormInputField114"
                required
                halfSize={false}
                errorId="errorDiv114"
                onChange={onTextInputChange}
                autoComplete="email"
              />

              <HtmlTextBox
                labelName="Address line 1"
                name="addressLine1"
                value={addressLine1}
                id="reduxFormInputField96"
                required
                halfSize={false}
                errorId="errorDiv97"
                onChange={onTextInputChange}
                autoComplete="address-line1"
              />

              <HtmlTextBox
                labelName="Address line 2"
                name="addressLine2"
                value={addressLine2 || ''}
                id="reduxFormInputField98"
                required={false}
                halfSize={false}
                errorId="errorDiv99"
                onChange={onTextInputChange}
                autoComplete="address-line2"
              />

              <div className="checkoutFormPersonalInfoRegionFields">
                <div className="checkoutFormBillingLocationItem">
                  <HtmlDropdown
                    labelName="Country"
                    name="countryCode"
                    onChange={onCountryChange}
                    countryData={countryData}
                    required
                    selectedValue={countryCode}
                    id="reduxFormSelectField100"
                    errorId="errorDiv101"
                    type={t('SelectCountry')}
                  />
                </div>
                <div className="checkoutFormPersonalInfoRegionFieldsArea">
                  <HtmlDropdown
                    labelName="Province/State"
                    name="provinceCode"
                    onChange={onProvinceChange}
                    provinceData={provinceData}
                    required
                    selectedValue={provinceCode}
                    id="reduxFormSelectField102"
                    errorId="errorDiv103"
                    type={t('SelectProvince')}
                    disabled={provinceData.length === 0 && !provinceCode}
                  />
                </div>
              </div>
              <div className="clearFloat" />
            </div>

            <div className="checkoutFormBilling">
              <div className="checkoutFormPersonalInfoRegionFieldsCity">
                <HtmlTextBox
                  labelName="City"
                  name="city"
                  value={city}
                  id="reduxFormInputField104"
                  required
                  halfSize
                  errorId="errorDiv105"
                  onChange={onTextInputChange}
                />
              </div>
              <div className="checkoutFormBillingLocationPostal">
                <HtmlTextBox
                  labelName="Postal/Zip Code"
                  name="postalCode"
                  value={postalCode}
                  id="reduxFormInputField106"
                  required
                  halfSize
                  errorId="errorDiv107"
                  onChange={onTextInputChange}
                  autoComplete="postal-code"
                />
              </div>
              <div className="clearFloat" />
            </div>
          </div>
          <div className="checkoutFormSubmit">
            <button
              type="submit"
              title="Continue"
              className={
                formValid && CHECKOUT_ALLOWED ? 'checkoutFormSubmitButtonEnabled' : 'checkoutFormSubmitButtonDisabled'
              }
              disabled={!formValid || !CHECKOUT_ALLOWED}
            >
              Continue
            </button>
          </div>
        </form>
      )}
    </div>
  );
}

import { ReactElement } from 'react';
import Avatar from '@mui/material/Avatar';
import { Link } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { Popup } from 'reactjs-popup';
import { ScreenBreakpoints } from '../../../constants/common';
import { DASHBOARD_NAME_BREAKPOINT } from '../../../constants/styling';
import { useLoginContext } from '../../../contexts/loginContext';
import { useProfileContext } from '../../../contexts/profileContext';
import { useMessageContext } from '../../../contexts/messageContext';

export function HeaderProfileAccess(): ReactElement {
  const { profile } = useProfileContext();
  const { signOut } = useLoginContext();
  const { unreadMessages } = useMessageContext();

  const firstName = profile?.firstName ? profile.firstName : '';
  const lastName = profile?.lastName ? profile.lastName : '';
  const fullName = firstName.concat(' ', lastName);

  const isTablet = useMediaQuery({
    minWidth: ScreenBreakpoints.isTablet,
    maxWidth: ScreenBreakpoints.isDesktop - 0.001,
  });
  const shrinkProfileName = useMediaQuery({ maxWidth: 1050 });

  const candidateForSmallNameFont =
    (profile?.firstName?.length ?? 0) + (profile?.lastName?.length ?? 0) >= DASHBOARD_NAME_BREAKPOINT;

  return (
    <div id="headerPrimaryNavigation">
      <Popup
        on={['hover', 'focus', 'click', 'right-click']}
        position="bottom center"
        className="hostControl"
        arrowStyle={{ color: 'transparent' }}
        offsetX={0}
        offsetY={-21}
        trigger={
          <div
            style={{
              fontSize: candidateForSmallNameFont && shrinkProfileName ? '8pt' : '',
              whiteSpace: candidateForSmallNameFont ? 'normal' : 'nowrap',
            }}
            className="headerRightProfileMenuDropdown"
          >
            <div className="headerProfileNameTriggerWrapper">
              <div className="headerProfileNameTriggerText">{fullName}</div>
              <div className="headerProfileNameTriggerIcon">
                <i className="downArrow" />
              </div>
            </div>
          </div>
        }
      >
        <div className="headerContentDropdown">
          {isTablet ? (
            <Link className="headerContentDropdownItemWrapper" title="Profile" to="/cart">
              <div className="headerContentDropdownItem">
                <div className="headerContentDropdownItemLinkText">Checkout</div>
              </div>
            </Link>
          ) : (
            ''
          )}
          <Link className="headerContentDropdownItemWrapper" title="Profile" to="/profile">
            <div className="headerContentDropdownItem">
              <div className="headerContentDropdownItemLinkText">Profile</div>
            </div>
          </Link>
          <Link className="headerContentDropdownItemWrapper" title="My Courses" to="/transcript">
            <div className="headerContentDropdownItem">
              <div className="headerContentDropdownItemLinkText">Transcript</div>
            </div>
          </Link>
          <Link className="headerContentDropdownItemWrapper" title="Notifications" to="/notifications">
            <div className="headerContentDropdownItemContainer">
              <div className="headerContentDropdownItemLinkText">Notifications</div>
              <div className="headerContentDropdownItemLinkTextSupplemental">
                {unreadMessages ? (
                  <Avatar sx={{ bgcolor: '#E4333E', width: 24, height: 24 }}>{unreadMessages}</Avatar>
                ) : (
                  ''
                )}
              </div>
            </div>
          </Link>
          <button type="button" className="headerContentDropdownItemLinkButton" title="Sign out" onClick={signOut}>
            <div className="headerContentDropdownItem">
              <div className="headerContentDropdownItemLink">Sign out</div>
            </div>
          </button>
        </div>
      </Popup>
    </div>
  );
}

import { ReactElement } from 'react';
import { CatalogCourseData } from '../../models/absorb/catalog';
import { CurriculumGroupData } from '../../models/absorb/curriculum';
import { CourseListCard } from './CourseListCard';
import './CurriculumGroup.css';

interface Props {
  curriculumGroup: CurriculumGroupData;
  curriculumId?: string;
}

export function CurriculumGroup({ curriculumGroup, curriculumId }: Props): ReactElement {
  return (
    <>
      <div className="curriculumGroupView">
        <div className="curriculumGroupName" title={curriculumGroup.name}>
          {curriculumGroup.name}
        </div>
        {curriculumGroup.courses.map((course: CatalogCourseData) => (
          <CourseListCard
            key={course.id}
            curriculum={curriculumId}
            course={course}
            progress={course.progress}
            // isEnrollmentLocked={curriculumGroup?.enrollment === null ? false : curriculumGroup.enrollment.isLocked}
            // curriculumRequiresPurchase={curriculumRequiresPurchase}
          />
        ))}
      </div>
    </>
  );
}

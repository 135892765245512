import { ReactElement } from 'react';
import { SearchFilterFlags } from '../../models/absorb/search';
import { CourseFilterCheckBoxesList } from './CourseFilterCheckBoxesList';
import { useSearchContext } from '../../contexts/searchContext';
import './CourseFilterCheckBoxes.css';

export function CourseFilterCheckBoxes(): ReactElement {
  const { courseEntities, setCourseEntities, setOtherEntities } = useSearchContext();

  function update(filterFlags: SearchFilterFlags) {
    setCourseEntities(filterFlags);
    setOtherEntities(filterFlags);
  }

  return <CourseFilterCheckBoxesList courseFilter={courseEntities} update={update} />;
}

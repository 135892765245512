import { ReactElement } from 'react';
import './LessonPlayerWrapper.css';

interface Props {
  children: ReactElement;
  lessonName: string;
  closeLessonPlayer: () => void;
}

export function LessonPlayerWrapper({ children, lessonName, closeLessonPlayer }: Props): ReactElement {
  return (
    <div className="lessonPlayerWrapper">
      <div className="lessonPlayerNavbar" style={{ height: '0px' }}>
        <div className="lessonPlayerLessonName">{lessonName}</div>
        <div
          className="lessonPlayerCloseButtonHolder"
          style={{
            position: 'absolute',
            top: 0,
            right: 0,
            // backgroundColor: 'black',
            // color: 'white',
            fontWeight: 'bold',
            padding: '14px',
            width: '25px',
          }}
        >
          <div className="lessonPlayerCloseButtonContainer" />
          <button
            type="button"
            className="lessonPlayerCloseButton"
            style={{
              zIndex: 1,
              width: '23px',
              height: '23px',
              position: 'absolute',
              top: 0,
              right: 2,
              padding: '23px',
              backgroundColor: '#000000',
              borderLeft: '4px solid #ffffff',
              borderBottom: '4px solid #ffffff',
              borderRadius: '0 0 0 25px',
            }}
            title="Close"
            onClick={closeLessonPlayer}
          >
            <div className="lessonPlayerCloseButtonIcon" />
          </button>
        </div>
      </div>
      {children}
    </div>
  );
}

import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { DashboardLayout } from '../dashboard/DashboardLayout';
import redAlert from '../../assets/images/icons/redAlert.svg';
import { PageTitle } from './PageTitle';

export function NotFound(): ReactElement {
  const { t } = useTranslation();

  return (
    <DashboardLayout>
      <PageTitle title={t('NotFound').replace('{0}', '')} />
      <div role="main" className="">
        <div className="">
          <div className="inlineIconAndText">
            <div className="inlineRedAlert">
              <img alt="alert" src={redAlert} />
            </div>
            <div className="inlineHeadline">{`${t('Page')}${t('NotFound').replace('{0}', '')}`}</div>
          </div>
          <div className="smallParagraphBreak">{t('NotFoundDescription')}</div>
          <Link className="textLink" to="/dashboard">
            {t('ReturnToMyDashboard')}
          </Link>
        </div>
      </div>
    </DashboardLayout>
  );
}

import { ReactElement, useEffect, useState } from 'react';
import ShowMoreText from 'react-show-more-text';
import { Avatar, Badge, styled } from '@mui/material';
import { red, grey } from '@mui/material/colors';
import { MessageData } from '../../models/absorb/message';
import { useMessageContext } from '../../contexts/messageContext';
import { convertToTimeAgoDate, parsedHtml } from '../../utils/helper';
import { SHOW_MARKASREAD_CHARACTER_LIMIT } from '../../constants/messages';
import notificationIcon from '../../assets/images/icons/blueChatIcon.svg';
import './MessageItem.css';

interface Props {
  message: MessageData;
}

export function MessageItem({ message }: Props): ReactElement {
  const { messageRead } = useMessageContext();

  const [isMessageRead, setIsMessageRead] = useState(false);

  const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
      backgroundColor: red[500],
      color: red[500],
      width: 13,
      height: 13,
      borderRadius: 50,
      boxShadow: `0 0 0 1px ${theme.palette.background.paper}`,
      '&::after': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        borderRadius: '50%',
      },
    },
    '@keyframes ripple': {
      '0%': {
        transform: 'scale(.8)',
        opacity: 1,
      },
      '100%': {
        transform: 'scale(2.4)',
        opacity: 0,
      },
    },
  }));

  useEffect(() => {
    if (message.isRead) {
      setIsMessageRead(true);
    }
  }, [message]);

  function markMessageAsRead() {
    if (message && !message.isRead && !isMessageRead) {
      messageRead(message.id, { read: true });
      setIsMessageRead(true);
    }
  }
  return (
    <div className="messageItemContainer">
      <button type="button" className="messageItemButton">
        <div className="messageItemWrapper">
          <div className="messageItemIcon">
            <StyledBadge
              overlap="circular"
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              variant="dot"
              invisible={isMessageRead}
            >
              <Avatar sx={{ width: 34, height: 34, backgroundColor: grey[50], borderRadius: 3 }} variant="rounded">
                <img src={notificationIcon} className="messageItemImage" alt={message.subject} />
              </Avatar>
            </StyledBadge>
          </div>
          <div className="messageItemTitle" title={message.subject}>
            {parsedHtml(message.subject)}
          </div>
          <div className="messageItemDescription">
            {message.message.length < SHOW_MARKASREAD_CHARACTER_LIMIT && !message.isRead ? (
              <div className="messageItemShortDescriptionWrapper">
                {parsedHtml(message.message)}
                <div className="messageItemLinkButton" onClick={markMessageAsRead}>
                  [mark as read]
                </div>
              </div>
            ) : (
              <ShowMoreText
                lines={1}
                width={350}
                more="[read more]"
                less=" [close]"
                onClick={markMessageAsRead}
                anchorClass="messageItemDescriptionShowMore"
                expanded={false}
              >
                {parsedHtml(message.message)}
              </ShowMoreText>
            )}
          </div>
          <div className="messageItemFreshness" title={new Date(message.sendDate).toDateString()}>
            {convertToTimeAgoDate(message.sendDate)}
          </div>
        </div>
      </button>
    </div>
  );
}

import { ReactElement, useState, ChangeEvent } from 'react';
import { useSearchContext } from '../../contexts/searchContext';
import './CheckBox.css';

interface Props {
  label: string;
  value: string;
  isChecked?: boolean;
  isDynamicChecked?: boolean;
  handleCheckbox: (event: ChangeEvent<HTMLInputElement>) => void;
}

export function CheckBox({ label, value, isChecked, isDynamicChecked, handleCheckbox }: Props): ReactElement {
  const [checkBoxChecked, setCheckBoxChecked] = useState(false);
  const { disableFilter } = useSearchContext();

  function onChangeHandler(e: ChangeEvent<HTMLInputElement>) {
    setCheckBoxChecked(!checkBoxChecked);
    handleCheckbox(e);
  }

  return (
    <>
      <div className="checkboxWrapper">
        <div className="checkboxStyleWrapper">
          <input
            className="checkboxStyle"
            type="checkbox"
            aria-checked="false"
            onChange={onChangeHandler}
            id={label}
            name={label}
            value={value}
            disabled={disableFilter}
            checked={isDynamicChecked ? isChecked : checkBoxChecked}
          />
        </div>
        <div className="checkboxLabel">{label}</div>
      </div>
      <div className="floatClear" />
    </>
  );
}

import { ReactElement, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';
import { CatalogCourseData } from '../models/absorb/catalog';
import { CourseChaptersData, CourseResourcesData, OnlineCourseData } from '../models/absorb/course';
import { CourseDescription } from '../components/course/CourseDescription';
import { DashboardLayout } from '../components/dashboard/DashboardLayout';
import { LessonPlayer } from '../components/lesson/LessonPlayer';
import { MyLessonData } from '../models/absorb/lessons';
import { ShareThis } from '../components/global/ShareThis';
import { OnlineCourseContent } from '../components/course/OnlineCourseContent';
import { PageTitle } from '../components/global/PageTitle';
import { TabView } from '../constants/courses';
import { ResourceContent } from '../components/course/ResourceContent';
import { AbsorbEnrollmentStatus } from '../constants/absorb';
import { enrollInCourse, fetchCourseContent, fetchMyCourseEnrollment, reEnrollInCourse } from '../services/course';
import { errorHandler, getRandomImage } from '../utils/helper';
import { fetchCourseChapters, fetchCourseResources, fetchOnlineCourse } from '../services/onlineCourses';
import { fetchLesson } from '../services/lesson';
import { CoursePageActionButton } from '../components/course/CoursePageActionButton';
import { useCourseAccessErrorRedirect } from '../hooks/useCourseAccessErrorRedirect';
import { fetchMyCatalogCourse } from '../services/catalog';
import { fetchCurriculumCourse } from '../services/curriculum';
import './CourseDetailsPage.css';

export function CourseDetailsPage(): ReactElement {
  const { t } = useTranslation();
  const location = useLocation();

  const { courseId: courseIdParam } = useParams<{ courseId: string }>();
  const courseId = courseIdParam || '';
  const [catalogCourse, setCatalogCourse] = useState<CatalogCourseData>();
  const [course, setCourse] = useState<OnlineCourseData>();
  const [courseChapters, setCourseChapters] = useState<CourseChaptersData[]>([]);
  const [courseResourcesData, setCourseResourcesData] = useState<CourseResourcesData[]>([]);
  const [courseEnrollmentStatus, setCourseEnrollmentStatus] = useState('');
  const [courseUrl, setCourseUrl] = useState('');
  const [lessonData, setLessonData] = useState<MyLessonData>();
  const [nextLessonData, setNextLessonData] = useState<MyLessonData>();
  const [showVideo, setShowVideo] = useState(false);
  const [setCourseAccessError] = useCourseAccessErrorRedirect();
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [enrollmentStatusHasChanged, setEnrollmentStatusHasChanged] = useState(false);
  const [tabView, setTabView] = useState(TabView.Course);

  useEffect(() => {
    setIsLoadingData(true);
    fetchOnlineCourse(courseId)
      .then((courseData) => {
        setCourse(courseData);
        Promise.all([
          location.pathname.split('/')[3] === 'curriculum'
            ? fetchCurriculumCourse(location.pathname.split('/')[4] || '', courseId).then(setCatalogCourse)
            : fetchMyCatalogCourse(courseId).then(setCatalogCourse),
          fetchCourseChapters(courseId).then(setCourseChapters),
          fetchCourseResources(courseId).then(setCourseResourcesData),
        ])
          .catch((err) => {
            errorHandler(err);
            setCourseAccessError(true);
          })
          .finally(() => {
            setIsLoadingData(false);
            setEnrollmentStatusHasChanged(false);
          });
      })
      .catch((err) => {
        errorHandler(err);
        setCourseAccessError(true);
      })
      .finally(() => {
        setIsLoadingData(false);
        setEnrollmentStatusHasChanged(false);
      })
      .then(() => fetchMyCourseEnrollment(courseId).then((data) => setCourseEnrollmentStatus(data.enrollmentStatus)));
  }, [courseId, enrollmentStatusHasChanged]); // eslint-disable-line react-hooks/exhaustive-deps

  // JB: stops content from showing in lesson player
  //     until data has been pulled from the absorb api
  useEffect(() => {
    setShowVideo(true);
  }, [lessonData]);

  function openLessonPlayer(lessonId: string) {
    const courseWithThisLessonId = courseChapters.find((chapter) =>
      chapter.lessons.find((lesson) => lesson.id === lessonId)
    );
    const lessonIndex = courseWithThisLessonId?.lessons.findIndex((lesson) => lesson.id === lessonId) ?? -1;
    const nextLessonIndex = lessonIndex + 1;

    if (courseWithThisLessonId && lessonIndex !== -1 && nextLessonIndex < courseWithThisLessonId.lessons.length) {
      fetchLesson(courseWithThisLessonId.lessons[nextLessonIndex].id).then(setNextLessonData).catch(errorHandler);
    } else {
      setNextLessonData(undefined);
    }

    fetchLesson(lessonId).then(setLessonData).catch(errorHandler);
    const extraParams = '&displayTitle=false';
    fetchCourseContent(courseId, lessonId)
      .then((data) => {
        setCourseUrl(data.concat(extraParams));
      })
      .catch(errorHandler);
  }

  function enrollUserInCourse() {
    setIsLoadingData(true);
    enrollInCourse({ courseId })
      .catch(errorHandler)
      .finally(() => setEnrollmentStatusHasChanged(true));
  }

  function reEnrollUserInCourse() {
    setIsLoadingData(true);
    reEnrollInCourse(courseId)
      .catch(errorHandler)
      .finally(() => setEnrollmentStatusHasChanged(true));
  }

  return (
    <>
      {showVideo && lessonData ? (
        <LessonPlayer
          courseUrl={courseUrl}
          lessonData={lessonData}
          nextLessonData={nextLessonData}
          openLessonPlayer={openLessonPlayer}
          closeLessonPlayer={() => {
            setShowVideo(false);
            setEnrollmentStatusHasChanged(true);
          }}
        />
      ) : (
        <DashboardLayout>
          <PageTitle title={course?.name} />
          <div className="courseDetailsPageContentWrapper">
            <CoursePageActionButton
              course={course}
              catalogCourse={catalogCourse}
              courseEnrollmentStatus={courseEnrollmentStatus}
              isLoadingData={isLoadingData}
              courseDetails={courseChapters}
              openPlayer={openLessonPlayer}
              enrollUserInCourse={enrollUserInCourse}
              reEnrollUserInCourse={reEnrollUserInCourse}
            />

            <ShareThis url={window.location.href} />
            <div className="courseDetailsPageContent" aria-live="assertive" role="status" />
            <div className="courseImagePrimary">
              <img
                src={
                  course?.imageUri ||
                  getRandomImage(
                    course?.id || '',
                    '/assets/images/content/default/course/defaultCourseImage',
                    'webp',
                    14
                  )
                }
                className="courseImg"
                alt={course?.name || ''}
              />
            </div>
            <div className="courseTitleAndOrDescriptionWrapper">
              {
                course ? (
                  <div className="courseTitle">{course.name}</div>
                ) : null /* TODO: course could be null because it's invalid. Need to handle "message": "Invalid course type/ID.", "term": "ErrorGeneric" from api call! */
              }
              {catalogCourse && <CourseDescription course={catalogCourse} />}
            </div>
            <div className="courseDetailTabWrapper">
              <div className={tabView === TabView.Course ? 'tabItem tabItemActive' : 'tabItem'}>
                <button
                  type="button"
                  className=""
                  onClick={() => (tabView === TabView.Course ? null : setTabView(TabView.Course))}
                >
                  <span>{t('CourseContent')}</span>
                  <div className="lightBlueHalfUnderline" />
                </button>
              </div>
              {courseResourcesData?.length > 0 && (
                <div className={tabView === TabView.Resource ? 'tabItem tabItemActive' : 'tabItem'}>
                  <button
                    type="button"
                    className=""
                    onClick={() => (tabView === TabView.Resource ? null : setTabView(TabView.Resource))}
                  >
                    <span>{t('Resources')}</span>
                    <div className="lightBlueHalfUnderline" />
                  </button>
                </div>
              )}
            </div>
            {tabView === TabView.Course && (
              <div className="courseOnlineContentWrapper" aria-hidden="false">
                <OnlineCourseContent courseDetails={courseChapters} openPlayer={openLessonPlayer} />
              </div>
            )}
            {tabView === TabView.Resource && (
              <div className="courseOnlineContentWrapper" aria-hidden="false">
                <ResourceContent
                  courseResourcesData={courseResourcesData}
                  allowDownload={courseEnrollmentStatus !== AbsorbEnrollmentStatus.Empty}
                />
              </div>
            )}
          </div>
        </DashboardLayout>
      )}
    </>
  );
}

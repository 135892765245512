import { ReactElement, useState, useEffect, MouseEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useLoginContext } from '../../contexts/loginContext';
import { useShoppingContext } from '../../contexts/shoppingContext';
import { AbsorbEnrollmentStatus } from '../../constants/absorb';
import { CatalogCourseData } from '../../models/absorb/catalog';
import { constructCourseTypePath, getEnrollmentStatus } from '../../utils/helper';
import { formatMoney } from '../../utils/currencyCodes';
import { SpinnerButton } from '../controls/SpinnerButton';
import iconBlueCheckmarkCircle from '../../assets/images/icons/blueCheckmarkCircle.svg';
import iconBlueCart from '../../assets/images/icons/cartBlueIcon.svg';
import iconWhitePlusCircle from '../../assets/images/icons/whitePlusCircle.svg';
import whitePlayCircle from '../../assets/images/icons/whitePlayCircle.svg';
import { CurriculumData } from '../../models/absorb/curriculum';
import './CurriculumPageActionButton.css';

interface Props {
  course: CatalogCourseData;
  curriculum: CurriculumData;
  courseEnrollmentStatus: string;
  isLoadingData: boolean;
  enrollUserInCurriculum: () => void;
}

export function CurriculumPageActionButton({
  course,
  curriculum,
  courseEnrollmentStatus,
  isLoadingData,
  enrollUserInCurriculum,
}: Props): ReactElement {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { cartData, addToCart } = useShoppingContext();
  const { loggedIn } = useLoginContext();

  const [addedToCart, setAddedToCart] = useState(false);

  let courseIdToStartOrResume = '';

  useEffect(() => {
    const filteredData = cartData.filter((item) => item.id === course?.id);
    setAddedToCart(filteredData.length > 0);
  }, [cartData, course?.id]);

  useEffect(() => {
    getPagePrimaryActionButtonText();
    getPagePrimaryActionButtonPrice();
    getPagePrimaryActionButtonIcon();
    getPagePrimaryActionButtonCssName();
  }, [courseEnrollmentStatus]); // eslint-disable-line react-hooks/exhaustive-deps

  function addCourseToCart(data: CatalogCourseData) {
    if (addedToCart || !loggedIn) return;
    addToCart([...cartData, data]);
  }

  function getPagePrimaryActionButtonCssName() {
    if (courseEnrollmentStatus === AbsorbEnrollmentStatus.Empty) {
      return 'pageActionAreaLinkButton';
    }
    if (courseEnrollmentStatus === AbsorbEnrollmentStatus.Complete || !isAnyCourseEnrollment()) {
      return 'pageActionAreaLinkButtonNoAction';
    }
    if (addedToCart) {
      return 'pageActionAreaLinkButtonReverse';
    }
    return 'pageActionAreaLinkButton';
  }

  function getPagePrimaryActionButtonText() {
    if (courseEnrollmentStatus === AbsorbEnrollmentStatus.Complete) {
      return 'Completed';
    }
    if (addedToCart) {
      return 'Added to Cart';
    }
    if (
      courseEnrollmentStatus === AbsorbEnrollmentStatus.NotStarted ||
      courseEnrollmentStatus === AbsorbEnrollmentStatus.InProgress
    ) {
      return courseStartOrResume();
    }
    if (
      (courseEnrollmentStatus === AbsorbEnrollmentStatus.Empty && course && course.price == null) ||
      course?.price === 0
    ) {
      return 'Enroll';
    }
    if (courseEnrollmentStatus === AbsorbEnrollmentStatus.Empty && course && course.price > 0) {
      return `Buy`;
    }
    return '';
  }

  function courseStartOrResume(): string {
    let firstIncompleteCourse: CatalogCourseData | undefined;
    let courseIndex = 1;
    if (curriculum && curriculum.curriculumGroups.length) {
      // eslint-disable-next-line no-restricted-syntax
      for (const curriculumGroup of curriculum.curriculumGroups) {
        if (curriculumGroup.enrollment?.progress !== null && curriculumGroup !== undefined) {
          // eslint-disable-next-line no-restricted-syntax
          for (const curriculumCourse of curriculumGroup.courses) {
            if (
              curriculumCourse.enrollmentStatus === AbsorbEnrollmentStatus.NotStarted ||
              curriculumCourse.enrollmentStatus === AbsorbEnrollmentStatus.InProgress
            ) {
              firstIncompleteCourse = curriculumCourse;
              break;
            }
            courseIndex += 1;
          }
          if (firstIncompleteCourse) {
            break;
          }
        }
      }
    }

    if (firstIncompleteCourse) {
      courseIdToStartOrResume = firstIncompleteCourse.id;
      return `${t(getEnrollmentStatus(firstIncompleteCourse.enrollmentStatus))} Course ${courseIndex}`;
    }
    if (isAnyCourseEnrollment()) {
      return 'Curriculum Completed';
    }
    return 'Enroll in a course to start';
  }

  function getPagePrimaryActionButtonIcon() {
    if (courseEnrollmentStatus === AbsorbEnrollmentStatus.Complete) {
      return iconBlueCheckmarkCircle;
    }
    if (addedToCart) {
      return iconBlueCart;
    }
    if (!isAnyCourseEnrollment()) {
      return iconWhitePlusCircle;
    }
    if (
      courseEnrollmentStatus === AbsorbEnrollmentStatus.NotStarted ||
      courseEnrollmentStatus === AbsorbEnrollmentStatus.InProgress ||
      courseEnrollmentStatus === AbsorbEnrollmentStatus.NotComplete
    ) {
      return whitePlayCircle;
    }
    if (
      (courseEnrollmentStatus !== AbsorbEnrollmentStatus.Complete && course && course.price == null) ||
      course?.price === 0
    ) {
      return iconWhitePlusCircle;
    }
    if (courseEnrollmentStatus !== AbsorbEnrollmentStatus.Complete && course && course.price > 0) {
      return whitePlayCircle;
    }
    return whitePlayCircle;
  }

  function getPagePrimaryActionButtonPrice() {
    if (
      !addedToCart &&
      courseEnrollmentStatus !== AbsorbEnrollmentStatus.NotStarted &&
      courseEnrollmentStatus !== AbsorbEnrollmentStatus.Complete &&
      courseEnrollmentStatus !== AbsorbEnrollmentStatus.InProgress &&
      course &&
      course.price > 0
    ) {
      return formatMoney(course?.currencyAbbreviation, course?.price).toString();
    }
    return '';
  }

  function primaryActionButtonHandler(e: MouseEvent<HTMLButtonElement>) {
    e.stopPropagation();
    if (
      courseEnrollmentStatus === AbsorbEnrollmentStatus.NotStarted ||
      courseEnrollmentStatus === AbsorbEnrollmentStatus.InProgress
    ) {
      if (!isAnyCourseEnrollment()) {
        return null;
      }
      navigate(constructCourseTypePath('course', courseIdToStartOrResume));
    }
    if (
      (courseEnrollmentStatus !== AbsorbEnrollmentStatus.Complete &&
        courseEnrollmentStatus !== AbsorbEnrollmentStatus.NotStarted &&
        courseEnrollmentStatus !== AbsorbEnrollmentStatus.InProgress &&
        course &&
        course.price == null) ||
      course?.price === 0
    ) {
      enrollUserInCurriculum();
    }
    if (courseEnrollmentStatus !== AbsorbEnrollmentStatus.Complete && course && course.price > 0) {
      addCourseToCart(course);
    }
    if (addedToCart) {
      navigate('/cart');
    }
    if (courseEnrollmentStatus === AbsorbEnrollmentStatus.Complete) {
      return null;
    }
    return null;
  }

  function isAnyCourseEnrollment(): boolean {
    return (
      curriculum.curriculumGroups.find((group) =>
        group.courses.find(
          (groupCourse) =>
            groupCourse.enrolled === true && groupCourse.enrollmentStatus !== AbsorbEnrollmentStatus.Complete
        )
      ) !== undefined
    );
  }

  return (
    <div className="pageActionAreaLinks">
      <button
        aria-label={course?.name}
        type="button"
        className={getPagePrimaryActionButtonCssName()}
        disabled={isLoadingData}
        onClick={(e) => (course ? primaryActionButtonHandler(e) : null)}
      >
        {isLoadingData ? (
          <div className="pageActionAreaLinksSpinner">
            <SpinnerButton />
          </div>
        ) : (
          <div className="pageActionAreaLinkButtonContent">
            <div className="pageActionAreaLinkButtonImage">
              <img
                className="pageActionAreaLinkButtonImageCss"
                alt="Course Action"
                src={getPagePrimaryActionButtonIcon()}
              />
            </div>
            <div className="pageActionAreaLinkButtonText">{getPagePrimaryActionButtonText()}</div>
            <div className="pageActionAreaLinkButtonTextPrice">{getPagePrimaryActionButtonPrice()}</div>
          </div>
        )}
      </button>
    </div>
  );
}

import { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PageTitle } from '../components/global/PageTitle';
import { DashboardLayout } from '../components/dashboard/DashboardLayout';
import { fetchMessages } from '../services/message';
import { MessageData } from '../models/absorb/message';
import { errorHandler } from '../utils/helper';
import { MessageItem } from '../components/notifications/MessageItem';
import { LoadMoreActionButton } from '../components/controls/LoadMoreActionButton';
import { NotificationsTab, MARK_ALL_MESSAGES_VISIBILITY_MINIMUM } from '../constants/common';
import { TabBarItem } from '../components/global/TabBarItem';
import { ActivityData } from '../models/absorb/activity';
import { fetchActivities } from '../services/activity';
import { ActivityItem } from '../components/notifications/ActivityItem';
import { useMessageContext } from '../contexts/messageContext';
import '../components/controls/LoadMoreActionButton.css';
import './NotificationsPage.css';

export function NotificationsPage(): ReactElement {
  const { unreadMessages, getMessageCounts, messageReadAll } = useMessageContext();

  const { t } = useTranslation();

  const [messages, setMessages] = useState<MessageData[]>([]);
  const [activities, setActivities] = useState<ActivityData[]>([]);
  const [totalMessageCount, setTotalMessageCount] = useState(0);
  const [totalActivityCount, setTotalActivityCount] = useState(0);
  const [isLoadingMessages, setIsLoadingMessages] = useState(false);
  const [isLoadingActivities, setIsLoadingActivities] = useState(false);
  const [messageDataOffset, setMessageDataOffset] = useState(0);
  const [activityDataOffset, setActivityDataOffset] = useState(0);
  const [activeTab, setActiveTab] = useState<string | string[]>('');

  useEffect(() => {
    setActiveTab(NotificationsTab.Messages);
    getMessageCounts();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setIsLoadingMessages(true);
    fetchMessages(messageDataOffset)
      .then((messageData) => {
        setMessages(messageDataOffset ? [...messages, ...messageData.messages] : messageData.messages);
        setTotalMessageCount(messageData.totalItems);
      })
      .catch(errorHandler)
      .finally(() => setIsLoadingMessages(false));
  }, [messageDataOffset]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setTotalMessageCount(totalMessageCount - 1);
  }, [unreadMessages]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setIsLoadingActivities(true);
    fetchActivities(activityDataOffset)
      .then((activityData) => {
        setActivities(activityDataOffset ? [...activities, ...activityData.activities] : activityData.activities);
        setTotalActivityCount(activityData.totalItems);
      })
      .catch(errorHandler)
      .finally(() => setIsLoadingActivities(false));
  }, [activityDataOffset]); // eslint-disable-line react-hooks/exhaustive-deps

  function markAllMessagesRead() {
    setIsLoadingMessages(true);
    messageReadAll();
    setMessageDataOffset(0);
    setIsLoadingMessages(false);
  }
  function loadMoreMessages() {
    setMessageDataOffset(messageDataOffset + 1);
  }

  function loadMoreActivities() {
    setActivityDataOffset(activityDataOffset + 1);
  }

  function onSelectedTab(tabName: string) {
    setActiveTab(tabName);
  }

  return (
    <DashboardLayout>
      <div className="notificationsPageContentContainer">
        <PageTitle title={t('Notifications')} />
        <div className="headerPageTitle">{t('Notifications')}</div>
        <div className="tabHeaderContainer">
          <TabBarItem activeTab={activeTab} tabName={NotificationsTab.Messages} onSelectedTab={onSelectedTab} />
          <TabBarItem activeTab={activeTab} tabName={NotificationsTab.Activity} onSelectedTab={onSelectedTab} />
          {activeTab === NotificationsTab.Messages && unreadMessages > MARK_ALL_MESSAGES_VISIBILITY_MINIMUM ? (
            <div className="tabHeaderContainerLink">
              <button
                type="submit"
                title={t('MarkAllMessagesAsRead')}
                onClick={markAllMessagesRead}
                className={
                  unreadMessages !== 0 ? 'tabHeaderContainerLinkButtonEnabled' : 'tabHeaderContainerLinkButtonDisabled'
                }
                disabled={unreadMessages === 0}
              >
                <div className="">
                  {isLoadingMessages ? (
                    <div className="buttonLoadMoreMetrics">
                      <div className="buttonLoadingContainer">
                        <div className="buttonLoadingContainerLoaderWhite" />
                      </div>
                      <div className="buttonLoadingText">
                        <div>{t('MarkAllMessagesAsRead')}</div>
                      </div>
                    </div>
                  ) : (
                    <div className="buttonLoadingText">
                      <div>{t('MarkAllMessagesAsRead')}</div>
                    </div>
                  )}
                </div>
              </button>
            </div>
          ) : (
            ''
          )}
        </div>
        {activeTab === NotificationsTab.Messages && (
          <div className="MessagesWrapper">
            <div className={activeTab === NotificationsTab.Messages ? 'sectionHeaderActive' : 'sectionHeaderInactive'}>
              {messages.map((messageItem: MessageData) => (
                <MessageItem key={messageItem.id} message={messageItem} />
              ))}
            </div>
            {messages.length < totalMessageCount ? (
              <div className="loadMoreWrapper">
                <LoadMoreActionButton
                  currentItemsCount={messages.length}
                  totalItemsCount={totalMessageCount}
                  itemsAreLoading={isLoadingMessages}
                  fetchMoreFunction={loadMoreMessages}
                  buttonLabel="Show older notifications"
                  cssClass="loadMoreButton"
                  spinnerClass="buttonLoadingContainerLoaderWhite"
                />
              </div>
            ) : null}
          </div>
        )}
        {activeTab === NotificationsTab.Activity && (
          <div className="activitiesWrapper">
            <div className={activeTab === NotificationsTab.Activity ? 'sectionHeaderActive' : 'sectionHeaderInactive'}>
              {activities.map((activityItem: ActivityData) => (
                <ActivityItem key={activityItem.id} activity={activityItem} />
              ))}
            </div>
            {activities.length < totalActivityCount ? (
              <div className="loadMoreWrapper">
                <LoadMoreActionButton
                  currentItemsCount={activities.length}
                  totalItemsCount={totalActivityCount}
                  itemsAreLoading={isLoadingActivities}
                  fetchMoreFunction={loadMoreActivities}
                  buttonLabel="Show older activities"
                  cssClass="loadMoreButton"
                  spinnerClass="buttonLoadingContainerLoaderWhite"
                />
              </div>
            ) : null}
          </div>
        )}
      </div>
    </DashboardLayout>
  );
}

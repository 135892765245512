import { ReactElement, useState, useEffect, MouseEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLoginContext } from '../../contexts/loginContext';
import { useShoppingContext } from '../../contexts/shoppingContext';
import { AbsorbEnrollmentStatus } from '../../constants/absorb';
import { enrollInCourse, fetchMyCourseEnrollment } from '../../services/course';
import { errorHandler } from '../../utils/helper';
import { formatMoney } from '../../utils/currencyCodes';
import { SpinnerButton } from '../controls/SpinnerButton';
import iconBlueCheckmarkCircle from '../../assets/images/icons/blueCheckmarkCircle.svg';
import iconBlueCart from '../../assets/images/icons/cartBlueIcon.svg';
import iconWhitePlusCircle from '../../assets/images/icons/whitePlusCircle.svg';
import whitePlayCircle from '../../assets/images/icons/whitePlayCircle.svg';
import { CatalogCourseData } from '../../models/absorb/catalog';
import './BundlePageActionButton.css';

interface Props {
  course: CatalogCourseData;
  // bundleDetails: BundleDetailsData;
}

export function BundlePageActionButton({ course }: Props): ReactElement {
  const navigate = useNavigate();

  const { cartData, addToCart } = useShoppingContext();
  const { loggedIn } = useLoginContext();

  const [addedToCart, setAddedToCart] = useState(false);
  const [courseEnrollmentStatus, setCourseEnrollmentStatus] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const filteredData = cartData.filter((item) => item.id === course.id);
    setAddedToCart(filteredData.length > 0);
  }, [cartData, course.id]);

  useEffect(() => {
    getPagePrimaryActionButtonText();
    getPagePrimaryActionButtonPrice();
    getPagePrimaryActionButtonIcon();
    getPagePrimaryActionButtonCssName();
  }, [courseEnrollmentStatus]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setIsLoading(true);
    fetchMyCourseEnrollment(course.id)
      .then((data) => {
        setCourseEnrollmentStatus(data.enrollmentStatus);
      })
      .catch((err) => {
        errorHandler(err);
      })
      .finally(() => setIsLoading(false));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  function addCourseToCart(data: CatalogCourseData) {
    if (addedToCart || !loggedIn) return;
    addToCart([...cartData, data]);
  }

  function enrollUserInCourse() {
    setIsLoading(true);
    enrollInCourse({ courseId: course.id })
      .then(() => fetchMyCourseEnrollment(course.id))
      .then((data) => {
        setIsLoading(false);
        Object.assign(cartData, { enrollmentStatus: data.enrollmentStatus, enrolled: true });
        setCourseEnrollmentStatus(data.enrollmentStatus);
      })
      .catch((error) => {
        // TODO: enrollInCourse seems to succeed, even though it always returns a 400 error,
        // so just fetching the current enrollment here in the catch as it likely changed.
        // Will have to look in back-end code to see if the issue is there.
        fetchMyCourseEnrollment(course.id).then((data) => {
          Object.assign(cartData, { enrollmentStatus: data.enrollmentStatus, enrolled: true });
          setCourseEnrollmentStatus(data.enrollmentStatus);
        });

        errorHandler(error);
        setIsLoading(false);
      })
      .finally(() => navigate('/my-courses'));
  }

  function getPagePrimaryActionButtonCssName() {
    if (courseEnrollmentStatus === AbsorbEnrollmentStatus.Complete) {
      return 'pageActionAreaLinkButtonNoAction';
    }
    if (addedToCart) {
      return 'pageActionAreaLinkButtonReverse';
    }
    return 'pageActionAreaLinkButton';
  }

  function getPagePrimaryActionButtonText() {
    if (courseEnrollmentStatus === AbsorbEnrollmentStatus.Complete) {
      return 'Completed';
    }
    if (addedToCart) {
      return 'Added to Cart';
    }
    if (
      courseEnrollmentStatus === AbsorbEnrollmentStatus.NotStarted ||
      courseEnrollmentStatus === AbsorbEnrollmentStatus.InProgress
    ) {
      return 'Enrolled';
    }
    if (
      (courseEnrollmentStatus === AbsorbEnrollmentStatus.Empty && course && course.price == null) ||
      course?.price === 0
    ) {
      return 'Enroll';
    }
    if (courseEnrollmentStatus === AbsorbEnrollmentStatus.Empty && course && course.price > 0) {
      return `Buy`;
    }
    return '';
  }

  function getPagePrimaryActionButtonIcon() {
    let icon = whitePlayCircle;

    if (courseEnrollmentStatus === AbsorbEnrollmentStatus.Complete) {
      icon = iconBlueCheckmarkCircle;
      return icon;
    }
    if (addedToCart) {
      icon = iconBlueCart;
      return icon;
    }
    if (
      courseEnrollmentStatus === AbsorbEnrollmentStatus.NotStarted ||
      courseEnrollmentStatus === AbsorbEnrollmentStatus.InProgress ||
      courseEnrollmentStatus === AbsorbEnrollmentStatus.NotComplete
    ) {
      icon = iconBlueCheckmarkCircle;
      return icon;
    }
    if (
      (courseEnrollmentStatus !== AbsorbEnrollmentStatus.Complete && course && course.price == null) ||
      course?.price === 0
    ) {
      icon = iconWhitePlusCircle;
      return icon;
    }
    if (courseEnrollmentStatus !== AbsorbEnrollmentStatus.Complete && course && course.price > 0) {
      icon = whitePlayCircle;
      return icon;
    }
    return icon;
  }

  function getPagePrimaryActionButtonPrice() {
    if (
      !addedToCart &&
      courseEnrollmentStatus !== AbsorbEnrollmentStatus.NotStarted &&
      courseEnrollmentStatus !== AbsorbEnrollmentStatus.Complete &&
      courseEnrollmentStatus !== AbsorbEnrollmentStatus.InProgress &&
      course &&
      course.price > 0
    ) {
      return formatMoney(course?.currencyAbbreviation, course?.price).toString();
    }
    return '';
  }

  function primaryActionButtonHandler(e: MouseEvent<HTMLButtonElement>) {
    e.stopPropagation();
    if (
      courseEnrollmentStatus === AbsorbEnrollmentStatus.NotStarted ||
      courseEnrollmentStatus === AbsorbEnrollmentStatus.InProgress
    ) {
      return null;
    }
    if (
      (courseEnrollmentStatus !== AbsorbEnrollmentStatus.Complete &&
        courseEnrollmentStatus !== AbsorbEnrollmentStatus.NotStarted &&
        courseEnrollmentStatus !== AbsorbEnrollmentStatus.InProgress &&
        course &&
        course.price == null) ||
      course?.price === 0
    ) {
      enrollUserInCourse();
    }
    if (courseEnrollmentStatus !== AbsorbEnrollmentStatus.Complete && course && course.price > 0) {
      addCourseToCart(course);
    }
    if (addedToCart) {
      navigate('/cart');
    }
    if (courseEnrollmentStatus === AbsorbEnrollmentStatus.Complete) {
      return null;
    }
    return null;
  }

  return (
    <div className="pageActionAreaLinks">
      <button
        aria-label={course?.name}
        type="button"
        className={getPagePrimaryActionButtonCssName()}
        disabled={isLoading}
        onClick={(e) => (course ? primaryActionButtonHandler(e) : null)}
      >
        {isLoading ? (
          <div className="pageActionAreaLinksSpinner">
            <SpinnerButton />
          </div>
        ) : (
          <div className="pageActionAreaLinkButtonContent">
            <div className="pageActionAreaLinkButtonImage">
              <img
                className="pageActionAreaLinkButtonImageCss"
                alt="Course Action"
                src={getPagePrimaryActionButtonIcon()}
              />
            </div>
            <div className="pageActionAreaLinkButtonText">{getPagePrimaryActionButtonText()}</div>
            <div className="pageActionAreaLinkButtonTextPrice">{getPagePrimaryActionButtonPrice()}</div>
          </div>
        )}
      </button>
    </div>
  );
}

import { ReactElement } from 'react';
import './GenericMediumLargeHero.css';

export function GenericMediumLargeHero(): ReactElement {
  return (
    <div className="GenericMediumLargeHeroContentWrapper">
      <div className="GenericMediumLargeHeroContent">
        <span className="GenericMediumHeroLargeContentMessage"></span>
      </div>
    </div>
  );
}

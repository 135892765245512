import { ReactElement, useState } from 'react';
import { Alert, Button, Snackbar } from '@mui/material';

export function FooterSignature(): ReactElement {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  function closeSnack() {
    setOpen(false);
  }

  return (
    <div className="signature">
      <Button className="signatureVer" onClick={handleClick}>
        &copy;
      </Button>{' '}
      2021 ENDEAVOR, All Rights Reserved
      <div>
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          open={open}
          onClose={closeSnack}
          autoHideDuration={5000}
          message="Note archived"
        >
          <Alert onClose={closeSnack} severity="info">
            Version {process.env.REACT_APP_VERSION}
            {process.env.REACT_APP_GIT_HASH ? ` (${process.env.REACT_APP_GIT_HASH})` : ''}
          </Alert>
        </Snackbar>
      </div>
    </div>
  );
}

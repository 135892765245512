import { ReactElement, useState, useEffect, MouseEvent } from 'react';
import { useMediaQuery } from 'react-responsive';
import Avatar from '@mui/material/Avatar';
import { lightBlue, grey } from '@mui/material/colors';
import { useTranslation } from 'react-i18next';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { StripeCardElementChangeEvent } from '@stripe/stripe-js';
import { useShoppingContext } from '../../contexts/shoppingContext';
import { formatToDollar } from '../../utils/currencyCodes';
import { ShoppingCartContainer } from './ShoppingCartContainer';
import { ShoppingCartPreviousStepButton } from './ShoppingCartPreviousStepButton';
import { createPaymentIntent, completePaymentAndEnrollCourses } from '../../services/payment';
import { CardSection } from './CardSection';
import { SpinnerButton } from '../controls/SpinnerButton';
import { CartSummary } from './CartSummary';
import { ScreenBreakpoints, CHECKOUT_ALLOWED } from '../../constants/common';
import { US_DOLLAR_CURRENCY } from '../../constants/shoppingCart';
import { errorHandler } from '../../utils/helper';
import { PaymentIntentData } from '../../models/stripe/clientSecret';
import { useModalContext } from '../../contexts/modalContext';
import { PaymentSuccessModal } from '../../modals/PaymentSuccessModal';
import { PageTitle } from '../global/PageTitle';
// TODO: resources for alternate payment icons:
// import creditCard from '../../assets/images/components/checkout/creditCard.svg';
// import applePay from '../../assets/images/components/checkout/applePay.svg';
// import payPal from '../../assets/images/components/checkout/payPal.svg';
import './Payment.css';

export function Payment(): ReactElement {
  const { t } = useTranslation();

  const { cartData, goToPrevStep, billingDetailsState, getCartTotal, clearCart } = useShoppingContext();
  const { showModal } = useModalContext();

  const [disabled, setDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [clientSecret, setClientSecret] = useState('');
  const [paymentError, setPaymentError] = useState('');

  const isBigScreen = useMediaQuery({ minWidth: ScreenBreakpoints.isBigScreen });
  const isDesktop = useMediaQuery({ minWidth: ScreenBreakpoints.isDesktop, maxWidth: ScreenBreakpoints.isBigScreen });
  const isWideTablet = useMediaQuery({ minWidth: 1045, maxWidth: 1366 });

  const stripe = useStripe();
  const elements = useElements();

  useEffect(() => {
    const paymentIntentData: PaymentIntentData = {
      amount: getCartTotal(),
      currency: US_DOLLAR_CURRENCY,
      courses: cartData.map((course) => course.id),
    };

    setIsLoading(true);
    createPaymentIntent(paymentIntentData)
      .then((data) => {
        setClientSecret(data.clientSecret);
      })
      .catch(errorHandler)
      .finally(() => setIsLoading(false));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  function changeHandler(e: StripeCardElementChangeEvent) {
    const isCardDetailIncomplete = !e.complete;
    setDisabled(e.empty || isCardDetailIncomplete);
    setPaymentError(e.error?.message || '');
  }

  function handleSubmit(e: MouseEvent<HTMLButtonElement>) {
    e.preventDefault();
    setPaymentSuccess(false);

    if (!stripe || !elements) {
      return;
    }

    const currentCardElement = elements.getElement(CardElement);

    if (!currentCardElement) {
      setPaymentError('ErrorGeneric');
      return;
    }

    if (clientSecret) {
      setIsLoading(true);

      const paymentDetailsObj = {
        payment_method: {
          card: currentCardElement,
          billing_details: {
            name: `${billingDetailsState.firstName} ${billingDetailsState.lastName}`,
            address: {
              city: billingDetailsState.city,
              country: billingDetailsState.countryCode,
              line1: billingDetailsState.addressLine1,
              line2: billingDetailsState.addressLine2,
              postal_code: billingDetailsState.postalCode,
            },
          },
        },
      };

      stripe
        .confirmCardPayment(clientSecret, paymentDetailsObj)
        .then(async (response) => {
          if (response?.error?.message) {
            setPaymentError(response.error.message);
          } else if (response?.paymentIntent) {
            await completePaymentAndEnrollCourses({ paymentIntentId: response.paymentIntent.id });
          }
          return response;
        })
        .then((response) => {
          if (!response?.error?.message) {
            currentCardElement.update({ disabled: true });
            clearCart();
            setPaymentSuccess(true);
            showModal(<PaymentSuccessModal paymentError={paymentError} />);
          }
        })
        .catch(errorHandler)
        .finally(() => setIsLoading(false));
    } else {
      setPaymentError(t('ErrorGeneric'));
    }
  }

  return (
    <ShoppingCartContainer>
      <PageTitle title={t('Payment')} />
      <div className="paymentPageWrapper">
        <div className="paymentPageActions">
          <div className="paymentPageIntro">
            <div className="paymentPageIntroStep">
              <div className="checkoutFormPersonalIntro">
                <Avatar sx={{ bgcolor: grey[500], width: 24, height: 24 }}>1</Avatar>
                <div className="checkoutFormPersonalIntroText">What&#39;s your contact information?</div>
              </div>
            </div>
            <div className="paymentPagePersonalInfo">
              <div className="paymentPagePersonalInfoDetails">
                {billingDetailsState.firstName} {billingDetailsState.lastName}
                <br />
                {billingDetailsState.addressLine1}
                <br />
                {billingDetailsState.phone}
              </div>
              <div className="paymentPagePersonalInfoChange">
                <ShoppingCartPreviousStepButton
                  goBack
                  linkText="Change"
                  goToPrevStep={() => goToPrevStep('/cart/checkout')}
                />
              </div>
            </div>
          </div>
          <div className="paymentPageInteraction">
            <div className="paymentPagePaymentStep">
              <div className="checkoutFormPersonalIntro">
                <Avatar sx={{ bgcolor: lightBlue[500], width: 24, height: 24 }}>2</Avatar>
                <div className="checkoutFormPersonalIntroText">Payment</div>
              </div>
            </div>
            <CardSection changeHandler={changeHandler} />
          </div>
          <button
            className={CHECKOUT_ALLOWED ? 'paymentPageSubmitButtonEnabled' : 'paymentPageSubmitButtonDisabled'}
            disabled={disabled || !CHECKOUT_ALLOWED || paymentError.trim() !== '' || isLoading || paymentSuccess}
            onClick={(e) => handleSubmit(e)}
          >
            {isLoading ? <SpinnerButton /> : <span>Pay {formatToDollar(getCartTotal())}</span>}
          </button>
          <h3 className="">{paymentSuccess ? t('Success') : paymentError}</h3>
          <div className="paymentPageMessaging">{!paymentSuccess}</div>
        </div>
        {isDesktop || isBigScreen || isWideTablet ? (
          <div className="paymentPageSummary">{!paymentSuccess && <CartSummary />}</div>
        ) : (
          ''
        )}
      </div>
    </ShoppingCartContainer>
  );
}

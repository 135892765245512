import { ReactElement, useEffect, useState, ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { ProfileField } from '../../constants/common';
import { useProfileContext } from '../../contexts/profileContext';
import { ProfileFieldsData } from '../../models/absorb/profile';
import { HtmlTextBox } from '../controls/HtmlTextBox';
import { SpinnerButton } from '../controls/SpinnerButton';
import './ProfileDetails.css';

export function ProfileDetails(): ReactElement {
  const { t } = useTranslation();

  const { loading, profile, profileFields, updateInProgress, getProfileFields, updateProfile } = useProfileContext();

  const [middleName, setMiddleName] = useState('');
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [updateProfileSuccess, setUpdateProfileSuccess] = useState(false);

  useEffect(() => {
    if (profileFields) {
      const middleNameObj = profileFields.filter((profileField) => profileField.name === ProfileField.MiddleName);

      if (middleNameObj.length === 1) {
        setMiddleName(middleNameObj[0].value);
      }
    }
  }, [loading, profileFields]);

  function onChangeFormElement(e: ChangeEvent<HTMLInputElement>) {
    setMiddleName(e.target.value);
    setUpdateProfileSuccess(false);
    if (middleName !== e.target.value) {
      setSubmitDisabled(false);
    }
    if (!middleName && e.target.value === '') {
      setSubmitDisabled(true);
    }
  }

  async function onEditSubmit() {
    if (profileFields) {
      const updatedProfile: ProfileFieldsData[] = profileFields.map((profileField: ProfileFieldsData) => {
        return profileField.name === ProfileField.MiddleName
          ? { ...profileField, value: middleName || '' }
          : profileField;
      });

      if (await updateProfile(updatedProfile)) {
        setUpdateProfileSuccess(true);
        getProfileFields();
        setSubmitDisabled(true);
      }
    }
  }

  return (
    <div className="aboutMeEdit">
      <div className="sectionTitle">About Me</div>
      <div className="aboutMeEditFieldWrapper">
        <div className="aboutMeEditFieldItemFirstName">
          <HtmlTextBox
            labelName={t('FirstName')}
            name="firstName"
            value={profile?.firstName || ''}
            id="firstName"
            errorId=""
            disabled
            required
            autoComplete="given-name"
          />
        </div>
        <div className="aboutMeEditFieldItemMiddleName">
          <HtmlTextBox
            labelName={t('MiddleName')}
            name="middleName"
            value={middleName || ''}
            id="middleName"
            errorId=""
            onChange={onChangeFormElement}
            autoComplete="additional-name"
          />
        </div>
        <div className="aboutMeEditFieldItemLastName">
          <HtmlTextBox
            labelName={t('LastName')}
            name="lastName"
            value={profile?.lastName || ''}
            id="lastName"
            errorId=""
            disabled
            required
            autoComplete="family-name"
          />
        </div>
        <div className="aboutMeEditFieldItemEmail">
          <HtmlTextBox
            labelName={t('Email')}
            name="email"
            value={profile?.emailAddress || ''}
            id="email"
            errorId=""
            disabled
            required
            autoComplete="email"
          />
        </div>
        <div className="aboutMeEditButtonWrapper">
          <button
            type="button"
            className="button-default-l"
            disabled={submitDisabled}
            title="Save Changes"
            onClick={onEditSubmit}
          >
            {updateInProgress ? <SpinnerButton /> : 'Save Changes'}
          </button>
        </div>
        <div className="aboutMeEditFieldItemFeedback">{updateProfileSuccess && t('EditProfileSuccessBlurb')}</div>
      </div>
    </div>
  );
}

import { ReactElement, useState, useEffect } from 'react';
import { fetchMessages, fetchMessagesRead, updateReadAllMessage, updateReadMessage } from '../services/message';
import { MessageData, MessageReadData } from '../models/absorb/message';
import { errorHandler } from '../utils/helper';
import { ContextProps, createContext } from '../utils/contextHelper';
import { useLoginContext } from './loginContext';
import {
  MESSAGE_CHECK_INTERVAL,
  DEFAULT_MESSAGE_RETRIEVAL_COUNT,
  DEFAULT_MESSAGEREAD_RETRIEVAL_COUNT,
} from '../constants/messages';

interface MessageContextState {
  messagesDataAll: MessageData[];
  messagesDataRead?: MessageData[];
  messagesDataAllCount: number;
  messagesDataReadCount: number;
  unreadMessages: number;
  fetchMessagesDataAll: (offset: number, limit?: number) => Promise<void>;
  fetchMessagesDataRead: (offset: number, limit?: number) => Promise<void>;
  messageRead: (id: string, readData: MessageReadData) => Promise<void>;
  messageReadAll: () => void;
  getMessageCounts: () => void;
}

const [Provider, useMessageContext] = createContext<MessageContextState>(module.filename);

export { useMessageContext };

export function MessageProvider({ children }: ContextProps): ReactElement {
  const { loggedIn } = useLoginContext();

  const [messagesDataAll, setMessageDataAll] = useState<MessageData[]>([]);
  const [messagesDataRead, setMessagesDataRead] = useState<MessageData[]>([]);
  const [messagesDataAllCount, setMessagesDataAllCount] = useState(0);
  const [messagesDataReadCount, setMessagesDataReadCount] = useState(0);
  const [unreadMessages, setUnreadMessages] = useState<number>(0);
  const messageCheckInterval = MESSAGE_CHECK_INTERVAL;

  useEffect(() => {
    if (loggedIn) {
      getMessageCounts();

      const interval = setInterval(() => {
        getMessageCounts();
      }, messageCheckInterval);

      return () => clearInterval(interval);
    }
    return undefined;
  }, [loggedIn]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setUnreadMessages(messagesDataAllCount - messagesDataReadCount);
  }, [messagesDataAllCount, messagesDataReadCount]); // eslint-disable-line react-hooks/exhaustive-deps

  function getMessageCounts() {
    fetchMessagesDataAll(0, 1).then(() => fetchMessagesDataRead(0));
  }

  async function fetchMessagesDataAll(offset: number, limit = DEFAULT_MESSAGE_RETRIEVAL_COUNT) {
    try {
      const { messages, totalItems } = await fetchMessages(offset, limit);
      setMessageDataAll(offset ? [...messagesDataAll, ...messages] : messages);
      setMessagesDataAllCount(totalItems);
    } catch (e) {
      errorHandler(e);
    }
  }

  async function fetchMessagesDataRead(offset: number, limit = DEFAULT_MESSAGEREAD_RETRIEVAL_COUNT) {
    try {
      const { messages, totalItems } = await fetchMessagesRead(offset, limit);
      setMessagesDataRead(offset ? [...messagesDataRead, ...messages] : messages);
      setMessagesDataReadCount(totalItems);
    } catch (e) {
      errorHandler(e);
    }
  }

  async function messageRead(id: string, readData: MessageReadData) {
    try {
      await updateReadMessage(readData, id).then(() => setMessagesDataReadCount(messagesDataReadCount + 1));
    } catch (e) {
      errorHandler(e);
    }
  }

  async function messageReadAll() {
    try {
      await updateReadAllMessage()
        .then(() => getMessageCounts())
        .then(() => setMessagesDataReadCount(0));
    } catch (e) {
      errorHandler(e);
    }
  }

  return (
    <Provider
      value={{
        messagesDataAll,
        messagesDataAllCount,
        messagesDataRead,
        messagesDataReadCount,
        unreadMessages,
        fetchMessagesDataAll,
        fetchMessagesDataRead,
        messageRead,
        messageReadAll,
        getMessageCounts,
      }}
    >
      {children}
    </Provider>
  );
}

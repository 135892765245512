import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import './LoadMoreActionButton.css';

interface Props {
  currentItemsCount: number;
  totalItemsCount: number;
  itemsAreLoading: boolean;
  buttonLabel?: string;
  cssClass?: string;
  spinnerClass?: string;
  fetchMoreFunction: any; // eslint-disable-line @typescript-eslint/no-explicit-any
}

export function LoadMoreActionButton({
  currentItemsCount,
  totalItemsCount,
  itemsAreLoading,
  buttonLabel = 'Load More',
  cssClass = 'globalSearchLoadMore',
  spinnerClass = 'buttonLoadingContainerLoaderBlue',
  fetchMoreFunction,
}: Props): ReactElement {
  const { t } = useTranslation();

  return (
    <button
      type="button"
      disabled={itemsAreLoading}
      title={t('LoadMore')}
      className={cssClass}
      onClick={() => {
        fetchMoreFunction();
      }}
      aria-label={`${t('LoadMore')} ${currentItemsCount}/${totalItemsCount}`}
      aria-describedby="pageSizeButton"
    >
      <div className="buttonLoadingWrapper">
        <div className="buttonLoadMoreMetrics">
          {itemsAreLoading ? (
            <div className="buttonLoadingContainer">
              <div className={spinnerClass} />
            </div>
          ) : (
            <div className="buttonLoadingContainer">
              <div></div>
            </div>
          )}
          <div>
            {buttonLabel}
            <span className="buttonLoadingContainerCount">
              {currentItemsCount}/{totalItemsCount}
            </span>
          </div>
          <div className="buttonLoadingContainer"></div>
        </div>
      </div>
    </button>
  );
}

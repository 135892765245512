import moment from 'moment-timezone';
import base64 from 'base-64';
import parse from 'html-react-parser';
import sanitizeHtml from 'sanitize-html';
import {
  isOnCatalogPage,
  isOnMyCoursesPage,
  isOnResourcesPage,
  LocalStorageItem,
  SearchSwitch,
  TIME_AGO_THRESHOLD_FOR_SHOWING_DATE,
} from '../constants/common';
import { LocalStorageUserData } from '../models/user';
import { SearchFilterFlags, SearchFilterParams } from '../models/absorb/search';
import { AbsorbCourseType, AbsorbEnrollmentStatus, CoursePlayerType } from '../constants/absorb';
// import { AbsorbCourseType, AbsorbEnrollmentStatus } from '../constants/absorb';
import {
  STATUS_ICON_COURSE_COMPLETE,
  STATUS_ICON_COURSE_ENROLL,
  STATUS_ICON_COURSE_RESUME,
  STATUS_ICON_COURSE_START,
} from '../constants/styling';
import videoLessonImage from '../assets/images/content/course/lesson/lessonTypeVideo.png';
import surveyLessonImage from '../assets/images/content/course/lesson/lessonTypeSurvey.png';
import objectLessonImage from '../assets/images/content/course/lesson/lessonTypeObject.png';
import documentLessonImage from '../assets/images/content/course/lesson/lessonTypeDocument.png';
import { LessonsData, MyLessonData } from '../models/absorb/lessons';

export function convertToTimeAgoDate(originalDate: string): string {
  // used to decide whether date should be in time ago format for date format
  const showShortDate = moment(originalDate).isAfter(moment().subtract(TIME_AGO_THRESHOLD_FOR_SHOWING_DATE, 'days'));

  moment.relativeTimeThreshold('ss', 60); // set the number of seconds to be considered 'a few seconds ago'. Default is 44
  moment.relativeTimeThreshold('m', 60); // sets the number of minutes to be considered an hour. Default is 45
  moment.relativeTimeThreshold('h', 24); // sets the number of hours to be considered a day. Default is 22
  moment.relativeTimeRounding(Math.floor); // stops fromNow rounding up (eg. 2 hours ago after only 90 min)
  return showShortDate ? moment.utc(originalDate).fromNow() : moment.utc(originalDate).format('LL');
}

// export function getTimeShortDate(originalDate: string): string {
//   return moment.utc(originalDate).local().format('LL');
// }

export function getTimeShortDateMonth3Character(originalDate: string): string {
  return moment.utc(originalDate).local().format('MMM D, YYYY');
}

export function setMomentLocaleLanguage(language: string): void {
  const lookup: { [key: string]: string } = {
    en: 'en-ca',
    no: 'nb',
    zh: 'zh-hk',
    'zh-Hant': 'zh-hk',
  };

  const momentLanguage = lookup[language] || language;

  if (momentLanguage) {
    import(`moment/locale/${momentLanguage}`);
    moment.locale(momentLanguage);
  }
}

export function formatUrl(baseUrl: string, params: SearchFilterParams): string {
  let url = baseUrl;
  const data = JSON.parse(JSON.stringify(params));

  Object.keys(data).forEach((key) => {
    const value = data[key];

    if (value) {
      url += key === 'sortBy' ? `,${value}` : `&${key}=${value}`;
    }
  });

  return url;
}

export function isLearnerTokenValid(): boolean {
  let valid = false;
  const JWT_BODY_INDEX = 1;
  const MS_PER_SECOND = 1000;

  const token = localStorage.getItem(LocalStorageItem.Token);

  if (token) {
    const body = JSON.parse(base64.decode(token.split('.')[JWT_BODY_INDEX]));
    const currentDate = new Date().getTime() / MS_PER_SECOND;

    valid = body.exp > currentDate;
  }

  if (!valid) {
    localStorage.removeItem(LocalStorageItem.Token);
  }

  return valid;
}

export function errorHandler(error: unknown): void {
  console.error(error); // eslint-disable-line no-console
}

export function updateUserScopeInLocalStorage(userId: string | null, scope: string[]): void {
  try {
    if (userId) {
      const userData: LocalStorageUserData = JSON.parse(localStorage.getItem(userId) || '{}');

      if (scope.length === 0) {
        delete userData.scope;

        if (Object.keys(userData).length === 0) {
          localStorage.removeItem(userId);
        }
      } else {
        localStorage.setItem(userId, JSON.stringify({ ...userData, scope }));
      }
    }
  } catch (e) {
    errorHandler(e);
  }
}

// export function getResourceIcon(filetype: string | undefined): string {
//   const index = filetype ? filetype.toLocaleLowerCase() : 'www';

//   const lookup: { [key: string]: string } = {
//     pdf: 'icon-resource-pdf',
//     docx: 'icon-resource-word',
//     www: 'icon-resource-other',
//   };

//   return lookup[index];
// }

// export function getCourseIcon(courseType: string): string {
//   const lookup: { [key: string]: string } = {
//     Curriculum: 'icon-curriculum',
//     OnlineCourse: 'icon-courses',
//     InstructorLedCourse: 'icon-instructor-led-course',
//     InstructorCourse: 'icon-instructor-course',
//   };

//   return lookup[courseType] || 'icon-question-mark-square-bubble';
// }

// export function getCourseStatusIconClass(courseEnrollmentStatus: string): string {
//   const lookup: { [key: string]: string } = {
//     [AbsorbEnrollmentStatus.Complete]: 'icon icon-check-mark status-module__icon_complete___1ybnA status__success',
//     [AbsorbEnrollmentStatus.NotStarted]: 'icon icon-play status-module__icon_not_started___2f8O0',
//     [AbsorbEnrollmentStatus.InProgress]: 'icon icon-resume status-module__icon_in_progress___3JNqE',
//     [AbsorbEnrollmentStatus.NotComplete]: 'icon icon-play status-module__icon_not_started___2f8O0',
//   };

//   return lookup[courseEnrollmentStatus || AbsorbEnrollmentStatus.NotStarted];
// }

export function doPasswordsMatchByLength(password: string, confirmPassword: string): boolean {
  return password.split(confirmPassword)[0] === '';
}

export function parsedHtml(htmlData: string): string | JSX.Element | JSX.Element[] {
  const sanitizedHtmlData = sanitizeHtml(htmlData);
  return parse(sanitizedHtmlData);
}

// export function createArrayOfNumbers(size: number): number[] {
//   return Array(size)
//     .fill(0)
//     .map((x, y) => x + y);
// }

// export function constructCourseTypeUrl(courseType: string, courseId: string): string {
//   return `${window.location.origin}${constructCourseTypePath(courseType, courseId)}`;
// }

export function constructCourseTypePath(courseType: string, courseId: string): string {
  return `/${convertToPathName(courseType)}/${courseId}`;
}

export function constructCurriculumCourseTypePath(courseType: string, curriculumId: string, courseId: string): string {
  return `/${convertToPathName(courseType)}/${courseId}/curriculum/${curriculumId}`;
}

function convertToPathName(courseType: string): string {
  const lookup: { [key: string]: string } = {
    [AbsorbCourseType.CourseBundle]: 'course-bundle',
    [AbsorbCourseType.Curriculum]: 'curriculum',
    [AbsorbCourseType.OnlineCourse]: 'course',
    [AbsorbCourseType.InstructorLedCourse]: 'instructor-led',
  };

  return lookup[courseType] || 'course';
}

export function setAllEntityFlags(entities: SearchFilterFlags, state: boolean): SearchFilterFlags {
  const flags: SearchFilterFlags = {};

  Object.keys(entities).forEach((entity) => {
    flags[entity] = state;
  });

  return flags;
}

export function termsAndLoginRedirectStatus(loggedIn: boolean, userMustAcceptTerms?: boolean): string {
  if (!loggedIn) {
    return '/login';
  }

  if (userMustAcceptTerms) {
    return '/terms-and-conditions';
  }

  return '/dashboard';
}

export function getCourseStatusIcon(courseEnrollmentStatus: string): string {
  const lookup: { [key: string]: string } = {
    [AbsorbEnrollmentStatus.NotStarted]: STATUS_ICON_COURSE_START,
    [AbsorbEnrollmentStatus.InProgress]: STATUS_ICON_COURSE_RESUME,
    [AbsorbEnrollmentStatus.Complete]: STATUS_ICON_COURSE_COMPLETE,
    [AbsorbEnrollmentStatus.Null]: STATUS_ICON_COURSE_ENROLL,
  };
  return lookup[courseEnrollmentStatus] || 'course';
}

// export function getTimeZone(): string {
//   const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
//   return moment().tz(timeZone).format('Z');
// }

// export function getTimeZoneAbbreviation(): string {
//   const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
//   return moment().tz(timeZone).zoneAbbr();
// }

export function appendUrlParams(params: string, param: string, delimintor = '&'): string {
  return param === '' ? params : `${params}${delimintor}${param}`;
}

export function getSearchSwitch(switchType: SearchSwitch): boolean {
  const lookup: { [key: string]: boolean } = {
    [SearchSwitch.CourseCategorySwitch]: localStorage.getItem(SearchSwitch.CourseCategorySwitch) === 'true',
    [SearchSwitch.CourseCompletedSwitch]: localStorage.getItem(SearchSwitch.CourseCompletedSwitch) === 'true',
    [SearchSwitch.CatalogCategorySwitch]: localStorage.getItem(SearchSwitch.CatalogCategorySwitch) === 'true',
    [SearchSwitch.ResourceCategorySwitch]: localStorage.getItem(SearchSwitch.ResourceCategorySwitch) === 'true',
  };

  return lookup[switchType];
}

export function getTypeOfSearchSwitch(isCompletedSwitch?: boolean): SearchSwitch {
  if (isOnMyCoursesPage()) {
    if (isCompletedSwitch) {
      return SearchSwitch.CourseCompletedSwitch;
    }
    return SearchSwitch.CourseCategorySwitch;
  }
  if (isOnCatalogPage()) {
    return SearchSwitch.CatalogCategorySwitch;
  }
  if (isOnResourcesPage()) {
    return SearchSwitch.ResourceCategorySwitch;
  }
  return SearchSwitch.CourseCompletedSwitch;
}

// export function tileProperties(tile: string): DashboardTileProperties {
//   const lookup: { [key: string]: DashboardTileProperties } = {
//     [AbsorbTileType.Catalog]: {
//       title: 'Catalog',
//       icon: 'icon-book',
//       destination: '/catalog',
//       description: 'AvailableCoursesBlurb',
//     },
//     [AbsorbTileType.Courses]: {
//       title: 'Courses',
//       icon: 'icon-courses',
//       destination: '/my-courses',
//       description: 'EnrolledCoursesBlurb',
//     },
//     [AbsorbTileType.Resources]: {
//       title: 'Resources',
//       icon: 'icon-binder',
//       destination: '/resources',
//       description: 'ResourcesBlurb',
//     },
//     [AbsorbTileType.Faq]: {
//       title: 'FAQs',
//       icon: 'icon-question-mark-square-bubble',
//       destination: '/faqs',
//       description: 'FaqBlurb',
//     },
//     [AbsorbTileType.Messages]: {
//       title: 'InBox',
//       icon: 'icon-mail',
//       destination: `/profile?tab=${ProfileTab.Messages}`,
//       description: 'YourPriorityMessages',
//     },
//     [AbsorbTileType.Resume]: {
//       title: 'Resume',
//       icon: 'icon-resume',
//       destination: '', // resume tile will use computed value for course destination
//       description: 'ResumeBlurb',
//     },
//     [AbsorbTileType.Hyperlink]: {
//       title: '', // hyperlink will always have a .title value from the api
//       icon: 'icon-hyperlink',
//       destination: '', // hyperlink will alway have a .href value from the api
//       description: '', // hyperlink will always have a .name value from the api
//     },
//   };

//   return lookup[tile];
// }

export function getEnrollmentStatus(status: string): string {
  const lookup: { [key: string]: string } = {
    [AbsorbEnrollmentStatus.NotStarted]: 'Start',
    [AbsorbEnrollmentStatus.NotComplete]: 'Start',
    [AbsorbEnrollmentStatus.InProgress]: 'Resume',
    [AbsorbEnrollmentStatus.Complete]: 'Completed',
  };

  return lookup[status];
}

export function getLessonTypeImage(lesson: LessonsData, lessonData?: MyLessonData): string {
  const lookup: { [key: string]: string } = {
    [CoursePlayerType.VideoLesson]: videoLessonImage,
    [CoursePlayerType.ThirdPartyLesson]: objectLessonImage,
    [CoursePlayerType.TaskLesson]: surveyLessonImage,
    [CoursePlayerType.ObjectLesson]:
      lessonData?.mimeType?.includes('wordprocess') || lessonData?.mimeType?.includes('pdf')
        ? documentLessonImage
        : objectLessonImage,
    [CoursePlayerType.FlashLesson]: objectLessonImage,
    [CoursePlayerType.Exam]: surveyLessonImage,
  };

  return lookup[lesson.type];
}

function sumChars(s: string): number {
  let i;
  let acc = 0;
  const n = s.length;
  for (i = 0; i < n; i += 1) {
    acc += parseInt(s[i], 36) - 9;
  }
  return acc;
}

export function getRandomImage(id: string, fileSpec: string, fileExt: string, randomImageCount: number): string {
  const random = Math.ceil((sumChars(base64.encode(id)) * Math.PI) % randomImageCount);
  return `${fileSpec}${random}.${fileExt}`;
}

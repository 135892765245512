import { ReactNode, useEffect, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useLoginContext } from '../../contexts/loginContext';
import { useShoppingContext } from '../../contexts/shoppingContext';
import { formatToDollar } from '../../utils/currencyCodes';
import { CatalogCourseData } from '../../models/absorb/catalog';
import { parsedHtml, getRandomImage } from '../../utils/helper';
import { EmptyCart } from './EmptyCart';
import { CHECKOUT_ALLOWED } from '../../constants/common';
import { CENTS_PER_DOLLAR, SALES_TAX_RATE_PERCENT } from '../../constants/shoppingCart';
// TODO: implement discount codes (Feature/E1-1009):
// import { INVALID_COUPON, KEY_ENTER } from '../../constants/common';
import blueXCircle from '../../assets/images/icons/blueXCircle.svg';
import './CartSummary.css';

interface Props {
  headerHosted?: boolean;
  closePopup?: () => void;
}

export function CartSummary({ headerHosted = false, closePopup }: Props): ReactNode {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const { loggedIn } = useLoginContext();
  const { cartData, getCartSubTotal, getCartTax, getCartTotal, removeFromCart } = useShoppingContext();

  const [cartListData, setCartListData] = useState<CatalogCourseData[]>([]);
  const [cartListDataAvailable, setCartListDataAvailable] = useState(false);
  const [showRemoveItemButtons, setShowRemoveItemButtons] = useState(false);

  // TODO: implement discount codes (Feature/E1-1009):
  // const [applyCouponButtonDisabled, setApplyCouponButtonDisabled] = useState('');
  // const [couponErrorMessage, setCouponErrorMessage] = useState('');
  // const couponDisabled = applyCouponButtonDisabled.trim() === '';

  useEffect(() => {
    if (cartData.length < 1) {
      setCartListDataAvailable(false);
    } else {
      setCartListData(cartData);
      setCartListDataAvailable(true);
    }
  }, [cartData, cartData.length]);

  useEffect(() => {
    if (!(location.pathname.includes('/cart/payment') || location.pathname.includes('/cart/checkout'))) {
      setShowRemoveItemButtons(true);
    }
  }, [location.pathname]);

  // TODO: implement discount codes (Feature/E1-1009):
  // function onKeyPress(event: KeyboardEvent<HTMLInputElement>) {
  //   if (event.key === KEY_ENTER && !couponDisabled) {
  //     setCouponErrorMessage(INVALID_COUPON);
  //   }
  // }

  function removeItem(index: number) {
    const listData: CatalogCourseData[] = [...cartListData];
    removeFromCart(listData, index);
  }

  return (
    <div className="cartContainer">
      {headerHosted ? (
        <>
          <div className="cartSubHeaderMini">Cart</div>
          <div className="cartSummaryHeaderSeperator">&nbsp;</div>
        </>
      ) : (
        <div className="cartSummaryHostedHeader">&nbsp;</div>
      )}
      {!loggedIn && <EmptyCart />}
      {cartListDataAvailable && loggedIn && (
        <div className={headerHosted ? 'checkoutSummaryHosted' : 'checkoutSummary'}>
          <div className="checkoutSummaryPurchases">
            {cartListData.map((cartItem: CatalogCourseData, index: number) => (
              <div key={cartItem.id} className="checkoutPageCartMiniCardWrapper">
                {showRemoveItemButtons && (
                  <div className="checkoutPageCartCloseButtonWrapper">
                    <button
                      type="button"
                      className="cardListRemoveMini"
                      title={`${t('Remove')}`}
                      onClick={() => removeItem(index)}
                    >
                      <img
                        alt="remove cart item"
                        className="cardListRemoveMiniImg"
                        height="24"
                        width="24"
                        src={blueXCircle}
                      />
                    </button>
                  </div>
                )}
                <div className="checkoutPageCartMiniCardImage">
                  <img
                    src={
                      cartItem.imageUrl ||
                      getRandomImage(
                        cartItem.id,
                        '/assets/images/content/default/course/defaultCourseImage',
                        'webp',
                        14
                      )
                    }
                    className="checkoutPageCartMiniImage"
                    alt={cartItem.name}
                  />
                </div>
                <div className="checkoutPageCartMiniCardName" title={cartItem.name}>
                  {cartItem.name}
                </div>
                <div className="checkoutPageMiniCardDescription">
                  {cartItem.description ? parsedHtml(cartItem.description) : parsedHtml('<p>&nbsp;</p>')}
                </div>
                <div className="checkoutPageCartMiniCardPrice">{formatToDollar(cartItem.price * CENTS_PER_DOLLAR)}</div>
              </div>
            ))}
          </div>
          {/* TODO: implement discount codes (Feature/E1-1009):
      /* <div className="cartCoupon">
        <div id="coupon_code_header" className="cartCouponHeader">
          Discount Code
        </div>
        <div className="cartCouponCodeInput">
          <input
            name="code"
            id="code"
            type="text"
            step="any"
            className="cartCouponCodeInputText"
            aria-describedby="coupon_code_errors"
            aria-labelledby="coupon_code_header"
            value={applyCouponButtonDisabled}
            onChange={(e) => {
              setApplyCouponButtonDisabled(e.target.value);
              setCouponErrorMessage('');
            }}
            onKeyPress={(e) => onKeyPress(e)}
          />
          <button
            type="button"
            className={`${couponDisabled ? 'cartCouponDisabled' : 'cartCouponEnabled'}`}
            disabled={couponDisabled}
            title={t('Apply')}
            aria-label={t('Apply')}
            onClick={() => setCouponErrorMessage(INVALID_COUPON)}
          >
            {t('Apply')}
          </button>
        </div>
        <div aria-live="assertive" id="coupon_code_errors" className="cartCouponErrorMessage">
          {t(couponErrorMessage)}
        </div>
      </div> */}
          <div className="checkoutSummaryTotals">
            <div className="checkoutSummaryTotalsSubtotalLabel">{t('SubTotal')}</div>
            <div className="checkoutSummaryTotalsSubtotalValue">{formatToDollar(getCartSubTotal())}</div>
            <div className="checkoutSummaryTotalsTaxLabel">{`${t('Tax')} (${SALES_TAX_RATE_PERCENT}%)`}</div>
            <div className="checkoutSummaryTotalsTaxValue">{formatToDollar(getCartTax())}</div>
            <div className="checkoutSummaryTotalsTotalLabel">{t('Total')}</div>
            <div className="checkoutSummaryTotalsTotalValue">{formatToDollar(getCartTotal())}</div>
            {!headerHosted ? (
              <div className="checkoutSummaryTotalsTerms">
                By purchasing you agree to{' '}
                <Link className="" title="Terms" to="/terms-and-conditions">
                  ENDEAVOR terms
                </Link>
              </div>
            ) : (
              <div className="headerCartIconMenuActionButtons">
                <button type="button" className="button-reverse-l" title="Continue Browsing" onClick={closePopup}>
                  Continue Browsing
                </button>
                <button
                  type="button"
                  className="button-default-l"
                  title="Checkout"
                  onClick={() => navigate('/cart')}
                  disabled={!CHECKOUT_ALLOWED || location.pathname === '/cart'}
                >
                  View Cart
                </button>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

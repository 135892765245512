import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  errorText: string;
  subErrorText?: string;
}

export function MessageDisplayView({ errorText, subErrorText }: Props): ReactElement {
  const { t } = useTranslation();

  return (
    <div className="errorMessageWrapper">
      <div className="errorMessage">{t(errorText)}</div>
      {subErrorText && <div className="subErrorMessage">{t(subErrorText)}</div>}
    </div>
  );
}

import { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CircularProgress from '@mui/material/CircularProgress';
import { makeStyles } from '@mui/styles';
import { blue, grey } from '@mui/material/colors';
import { MyLessonData } from '../../models/absorb/lessons';
import { fetchMyCourseEnrollment } from '../../services/course';
import { errorHandler } from '../../utils/helper';
import checkmarkBlue from '../../assets/images/icons/checkmarkBlue.svg';
import './NextLessonOverlay.css';

interface Props {
  thisLesson: MyLessonData;
  nextLesson?: MyLessonData;
  hideOverlay: () => void;
  openLessonPlayer: (id: string) => void;
  closeLessonPlayer: () => void;
}

const useStyles = makeStyles({
  root: {
    position: 'relative',
  },
  bottom: {
    color: grey[500],
  },
  top: {
    color: blue[500],
    animationDuration: '1500ms',
    marginTop: '-100px',
  },
  circle: {
    strokeLinecap: 'round',
  },
});

export function NextLessonOverlay({
  thisLesson,
  nextLesson,
  hideOverlay,
  openLessonPlayer,
  closeLessonPlayer,
}: Props): ReactElement {
  const { t } = useTranslation();

  const [percentageCompleted, setPercentageCompleted] = useState(0);

  const classes = useStyles();

  useEffect(() => {
    fetchMyCourseEnrollment(thisLesson.courseId)
      .then((data) => {
        setPercentageCompleted(data.progress);
      })
      .catch(errorHandler);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="nextLessonOverlayContainer">
      <div className="nextLessonOverlayWrapper">
        <div className="nextLessonOverlayHolder">
          <div className="nextLessonOverlayPackage">
            <div className="nextLessonOverlayIconWrapper">
              <img alt="checkmark" className="nextLessonOverlayIcon" src={checkmarkBlue} />
            </div>
            <div className="nextLessonOverlayHeadline">{t('ActivityComplete')}</div>
            <div className="nextLessonOverlayPercentComplete">
              {t('CourseCompletedPercentage').replace('{0}', percentageCompleted.toString())}
            </div>
            <div className="nextLessonOverlayCircularProgressWrapper">
              <CircularProgress
                sx={{ color: '#e4f9fc' }}
                className={classes.bottom}
                thickness={4}
                variant="determinate"
                size={100}
                value={100}
              />
              <CircularProgress
                sx={{ color: '#00cae9' }}
                className={classes.top}
                thickness={4}
                variant="determinate"
                size={100}
                classes={{
                  circle: classes.circle,
                }}
                value={percentageCompleted}
              />
              <CircularProgress
                sx={{
                  color: '#37cbec',
                  opacity: '0.8',
                  filter: 'drop-shadow(-6px 6px 15px #37cbec88)',
                }}
                className={classes.top}
                thickness={4}
                variant="determinate"
                size={100}
                classes={{
                  circle: classes.circle,
                }}
                value={percentageCompleted}
              />
            </div>
            <div className="nextLessonOverlayBodyContentWrapper">
              <div className="nextLessonOverlayContent">
                {nextLesson && (
                  <>
                    <div className="nextLessonOverlayNameHeader">{t('NextLesson')}:</div>
                    <div className="nextLessonOverlayName">{nextLesson.name}</div>
                  </>
                )}
                <div className="nextLessonOverlayButtons">
                  <div className="nextLessonOverlayCloseButtonWrapper">
                    <button
                      type="button"
                      aria-disabled="false"
                      aria-label={t('CloseActivity')}
                      onClick={closeLessonPlayer}
                      className="buttonModalReverse"
                    >
                      <div className="nextLessonOverlayCloseButtonText">{t('CloseActivity')}</div>
                      <span aria-live="polite" />
                    </button>
                  </div>
                  <div className="nextLessonOverlayPlayButtonWrapper">
                    {nextLesson && (
                      <button
                        type="button"
                        aria-disabled="false"
                        aria-label={t('NextActivity')}
                        onClick={() => {
                          hideOverlay();
                          closeLessonPlayer();
                          openLessonPlayer(nextLesson.id);
                        }}
                        className="buttonModalDefault"
                      >
                        <div className="nextLessonOverlayPlayButtonText">{t('NextActivity')}</div>
                        <span aria-live="polite" />
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

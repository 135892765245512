import { ReactElement } from 'react';
import { CardElement } from '@stripe/react-stripe-js';
import { StripeCardElementChangeEvent } from '@stripe/stripe-js';
import { CARD_ELEMENT_OPTIONS } from '../../constants/styling';
import './CardSection.css';

interface Props {
  changeHandler: (e: StripeCardElementChangeEvent) => void;
}

export function CardSection({ changeHandler }: Props): ReactElement {
  return <CardElement options={CARD_ELEMENT_OPTIONS} onChange={changeHandler} />;
}

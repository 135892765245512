// // Status Icons
export const STATUS_ICON_SHOPPING_CART = 'icon-shopping-cart';
export const STATUS_ICON_SHOPPING_CART_ADDED = 'icon-shopping-cart-added';
export const STATUS_ICON_COURSE_ENROLL = 'icon-plus-circle';
export const STATUS_ICON_COURSE_START = 'icon-play-circle';
export const STATUS_ICON_COURSE_RESUME = 'icon-resume';
export const STATUS_ICON_COURSE_COMPLETE = 'icon-check-mark';

const INVALID_ICON_COLOR = '#e44555';

export const CARD_ELEMENT_OPTIONS = {
  style: {
    invalid: {
      iconColor: INVALID_ICON_COLOR,
    },
  },
};

// dashboard settings
export const DASHBOARD_NAME_BREAKPOINT = 25;
export const DASHBOARD_NAME_REGULAR_FONTSIZE = 48;
export const DASHBOARD_NAME_LONG_FONTSIZE = 32;
export const PROFILE_NAME_BREAKPOINT = 25;
export const PROFILE_NAME_REGULAR_FONTSIZE = 36;
export const PROFILE_NAME_LONG_FONTSIZE = 20;

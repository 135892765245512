import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { DASHBOARD_SECTION_MAX_ITEMS } from '../../constants/common';
import { CatalogCourseData } from '../../models/absorb/catalog';
import { CourseCard } from './CourseCard';
import './CourseRibbon.css';

interface Props {
  courseData: CatalogCourseData[];
  linkTitle: string;
  link: string;
  viewMoreTitle: string;
}

export function CourseRibbon({ courseData, linkTitle, link, viewMoreTitle }: Props): ReactElement {
  const { t } = useTranslation();

  return (
    <div className="dashboardSection">
      <Link className="dashboardSectionHeader" aria-label={t(linkTitle)} to={link}>
        {t(linkTitle)}
      </Link>
      <div className="dashboardSectionHeaderFooter" />
      <div className="mainBodyContent">
        {courseData &&
          courseData.map((course: CatalogCourseData) => (
            <div key={course.id} className="cardWrapper">
              <CourseCard cardData={course} />
            </div>
          ))}
      </div>
      {viewMoreTitle.length > 0 && courseData.length >= DASHBOARD_SECTION_MAX_ITEMS ? (
        <div className="additionalContentLink">
          <Link to={link}>{viewMoreTitle} ➦</Link>
        </div>
      ) : (
        ''
      )}
    </div>
  );
}

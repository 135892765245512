import { ReactElement, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  fetchUserProfile,
  userProfileUpdate,
  uploadUserAvatar,
  updateUserAvatar,
  fetchProfileData,
} from '../services/profile';
import { ProfileData, ProfileFieldsData } from '../models/absorb/profile';
import {
  THIRD_INDEX,
  RESET_PASSWORD_ABSORB_DEFAULT_URL_HASH_PATH,
  RESET_PASSWORD_PROPER_PATH,
} from '../constants/common';
import { errorHandler } from '../utils/helper';
import { ContextProps, createContext } from '../utils/contextHelper';
import { useLoginContext } from './loginContext';

interface ProfileContextState {
  loading: boolean;
  profile?: ProfileData;
  profileFields: ProfileFieldsData[];
  updateInProgress?: boolean;
  imageFileId?: string;
  getProfileFields: () => void;
  updateProfile: (data: ProfileFieldsData[]) => Promise<boolean>;
  uploadPicture: (data: FormData) => void;
  updatePicture: (data: { fileId: string }) => void;
}

const [Provider, useProfileContext] = createContext<ProfileContextState>(module.filename);

export { useProfileContext };

export function ProfileProvider({ children }: ContextProps): ReactElement {
  const navigate = useNavigate();
  const location = useLocation();

  const { loggedIn, termsAndConditions } = useLoginContext();

  const [loading, setLoading] = useState(false);
  const [profile, setProfile] = useState<ProfileData>();
  const [profileFields, setProfileFields] = useState<ProfileFieldsData[]>([]);
  const [updateInProgress, setUpdateInProgress] = useState(false);
  const [imageFileId, setImageFileId] = useState('');
  const [resetPath, setResetPath] = useState('');

  useEffect(() => {
    if (location.hash.includes(RESET_PASSWORD_ABSORB_DEFAULT_URL_HASH_PATH)) {
      setResetPath(location.hash.replace(RESET_PASSWORD_ABSORB_DEFAULT_URL_HASH_PATH, RESET_PASSWORD_PROPER_PATH));
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (resetPath) {
      navigate(resetPath);
    }
  }, [resetPath]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    try {
      if (loggedIn) {
        if (termsAndConditions) {
          if (termsAndConditions.currentUserMustAcceptTermsAndConditions) {
            navigate('/terms-and-conditions');
          } else {
            if (!profile) {
              getProfile();
            }
            if (profileFields.length === 0 || profileFields[THIRD_INDEX].value !== profile?.emailAddress) {
              getProfileFields();
            }
            navigate(location.pathname === '/' ? '/dashboard' : `${location.pathname}${location.search}`);
          }
        }
      } else {
        clearProfile();
        if (!location.pathname.includes(RESET_PASSWORD_PROPER_PATH) && !resetPath) {
          navigate(location.pathname === '/' ? '/' : `/login?redirectTo=${location.pathname}`);
        }
      }
    } catch (e) {
      errorHandler(e);
      navigate('/');
    }
  }, [loggedIn, termsAndConditions]); // eslint-disable-line react-hooks/exhaustive-deps

  function getProfile() {
    fetchUserProfile().then(setProfile).catch(errorHandler);
  }

  function getProfileFields() {
    fetchProfileData().then(setProfileFields).catch(errorHandler);
  }

  async function updateProfile(data: ProfileFieldsData[]) {
    let result = false;

    setUpdateInProgress(true);

    try {
      await userProfileUpdate(data);
      result = true;
    } catch (e) {
      errorHandler(e);
    } finally {
      setUpdateInProgress(false);
    }

    return result;
  }

  function uploadPicture(data: FormData) {
    setLoading(true);
    uploadUserAvatar(data)
      .then(setImageFileId)
      .catch(errorHandler)
      .finally(() => setLoading(false));
  }

  function updatePicture(data: { fileId: string }) {
    setLoading(true);
    updateUserAvatar(data)
      .then(setImageFileId)
      .catch(errorHandler)
      .then(() => setLoading(false));
  }

  function clearProfile() {
    setProfile(undefined);
  }

  return (
    <Provider
      value={{
        loading,
        profile,
        profileFields,
        updateInProgress,
        imageFileId,
        getProfileFields,
        updateProfile,
        uploadPicture,
        updatePicture,
      }}
    >
      {children}
    </Provider>
  );
}

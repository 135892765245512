import { ReactElement, ReactNode } from 'react';
import { useLocation } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { ScreenBreakpoints } from '../../constants/common';
import { Header } from '../layout/header/Header';
import { HeaderTablet } from '../layout/header/HeaderTablet';
import { HeaderMobile } from '../layout/header/HeaderMobile';
import { Footer } from '../layout/footer/Footer';
import { CatalogHero } from '../layout/hero/CatalogHero';
import { useModalContext } from '../../contexts/modalContext';
import { SideFilter } from '../filter/SideFilter';
import { MyCoursesHero } from '../layout/hero/MyCoursesHero';
import { CourseHero } from '../layout/hero/CourseHero';
import { SearchHero } from '../search/SearchHero';
import { GenericMediumHero } from '../layout/hero/GenericMediumHero';
import { GenericMediumLargeHero } from '../layout/hero/GenericMediumLargeHero';
import { HeroCourseProgress } from '../layout/hero/HeroCourseProgress';
import { useSearchContext } from '../../contexts/searchContext';

interface Props {
  children?: ReactNode;
}

export function DashboardLayout({ children }: Props): ReactElement {
  const { modal } = useModalContext();
  const { currentSearchUri } = useSearchContext();
  const location = useLocation();
  const uri = location.pathname;

  // 0.1 used as an interval as opposed to >=
  const isBigScreen = useMediaQuery({ minWidth: ScreenBreakpoints.isBigScreen });
  const isDesktop = useMediaQuery({
    minWidth: ScreenBreakpoints.isDesktop,
    maxWidth: ScreenBreakpoints.isBigScreen - 0.1,
  });
  const isLargeTablet = useMediaQuery({
    minWidth: ScreenBreakpoints.isLargeTablet,
    maxWidth: ScreenBreakpoints.isDesktop - 0.1,
  });
  const isTablet = useMediaQuery({
    minWidth: ScreenBreakpoints.isTablet,
    maxWidth: ScreenBreakpoints.isLargeTablet - 0.1,
  });
  const isMobile = useMediaQuery({
    minWidth: ScreenBreakpoints.isMobile,
    maxWidth: ScreenBreakpoints.isTablet - 0.1,
  });
  const isTiny = useMediaQuery({ maxWidth: ScreenBreakpoints.isMobile - 0.1 });

  function getHero() {
    if (uri.includes('/catalog')) {
      return <CatalogHero />;
    }
    if (uri.includes('/course') || uri.includes('/curriculum') || uri.includes('/instructor-led')) {
      return <CourseHero />;
    }
    if (uri.includes('/my-courses')) {
      return <MyCoursesHero />;
    }
    if (uri.includes('/dashboard')) {
      return <HeroCourseProgress />;
    }
    if (currentSearchUri.includes('/global-search')) {
      return <SearchHero />;
    }
    if (uri.includes('/profile')) {
      return <GenericMediumLargeHero />;
    }
    if (uri.includes('/transcript') || uri.includes('/notifications') || uri.includes('/resources')) {
      return <GenericMediumHero />;
    }
    return '';
  }

  function getPageWrapperCss() {
    if (
      uri.includes('/course') ||
      uri.includes('/my-courses') ||
      uri.includes('/curriculum') ||
      uri.includes('/instructor-led') ||
      uri.includes('/catalog') ||
      uri.includes('/global-search')
    ) {
      return 'mainContentWrapperCourse';
    }
    if (uri.includes('/cart')) {
      return 'mainContentWrapperCart';
    }
    if (uri.includes('/profile')) {
      return 'mainContentWrapperProfile';
    }
    return 'mainContentWrapper';
  }

  function getPageContainerCss() {
    if (
      uri.includes('/course') ||
      uri.includes('/my-courses') ||
      uri.includes('/curriculum') ||
      uri.includes('/instructor-led') ||
      uri.includes('/catalog') ||
      uri.includes('global-search') ||
      uri.includes('/profile')
    ) {
      return 'mainContentContainerFilters';
    }
    return 'mainContentContainer';
  }

  function getLeftSideBar() {
    if (uri.includes('/course') || uri.includes('/curriculum')) {
      return 'leftSideBarCourse';
    }
    return 'leftSidebar';
  }

  function getRightSideBar() {
    if (uri.includes('/cart')) {
      return 'hiddenElement';
    }
    return 'rightSidebar';
  }

  return (
    <div id="bodyContainer" className={uri.includes('/cart') ? 'bodyContainerCart' : 'bodyContainer'}>
      {isDesktop || isBigScreen ? <Header /> : ''}
      {isTablet || isLargeTablet ? <HeaderTablet /> : ''}
      {isMobile || isTiny ? <HeaderMobile /> : ''}
      {getHero()}
      <div className={getPageWrapperCss()}>
        <div className={getPageContainerCss()}>
          <div id="leftSidebar" className={getLeftSideBar()}>
            {uri.includes('/catalog') ||
            uri.includes('/my-courses') ||
            uri.includes('/instructor-led') ||
            (uri.includes('/global-search') && (isDesktop || isLargeTablet || isBigScreen)) ? (
              <SideFilter />
            ) : (
              <div id="leftSidebarContainer" className="leftSidebarContainer" />
            )}
            &nbsp;
          </div>
        </div>
        {children}
        <div id="rightSidebar" className={getRightSideBar()} />
      </div>
      <Footer />
      {modal || null}
    </div>
  );
}

import { ReactElement, useEffect, useState } from 'react';
import { errorHandler } from '../../utils/helper';
import { GlobalResourceData } from '../../models/absorb/resources';
import { onFetchResourceUrl } from '../../services/globalResources';
import documentImage from '../../assets/images/content/resource/resourceCardDefault.webp';
import './GlobalResourceCard.css';

interface Props {
  resource: GlobalResourceData;
}

export function GlobalResourceCard({ resource }: Props): ReactElement {
  const [resourceUrl, setResourceUrl] = useState('');

  useEffect(() => {
    if (resourceUrl) {
      window.open(resourceUrl, '_blank');
      setResourceUrl('');
    }
  }, [resourceUrl]);

  function getResourceUrl(id: string) {
    onFetchResourceUrl({ id }).then(setResourceUrl).catch(errorHandler);
  }

  return (
    <div className="globalResourceCard">
      <div className="cardImageContainer">
        <img src={documentImage} className="globalResourceCardImage" alt={resource.name} />
      </div>
      <div className="globalResourceCardTitleHolder">
        <div className="globalResourceCardTitle">{resource.name}</div>
      </div>
      <div className="globalResourceCardAction">
        <button
          type="button"
          aria-disabled="false"
          aria-label="Download"
          onClick={() => getResourceUrl(resource.id)}
          className="globalResourceCardActionButton"
        >
          <div className="resourceCardActionButtonText">Open</div>
        </button>
      </div>
    </div>
  );
}

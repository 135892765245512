import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { isOnGlobalSearchPage, isOnMyCoursesPage } from '../../constants/common';
import { SearchFilterFlags } from '../../models/absorb/search';

import { CheckBox } from './CheckBox';

interface Props {
  courseFilter: SearchFilterFlags;
  update: (filterFlags: SearchFilterFlags) => void;
}

export function CourseFilterCheckBoxesList({ courseFilter, update }: Props): ReactElement {
  const { t } = useTranslation();

  function handleCheckBox(key: string) {
    update({ ...courseFilter, [key]: !courseFilter[key] });
  }

  function setFilterPredicate(key: string): boolean {
    if (isOnMyCoursesPage()) {
      return key !== 'CourseBundle' && key !== 'GlobalResource';
    }
    if (!isOnGlobalSearchPage()) {
      return key !== 'GlobalResource';
    }
    return true;
  }

  return (
    <>
      {Object.keys(courseFilter)
        .filter((key) => setFilterPredicate(key))
        .map((key) => (
          <li key={key}>
            <CheckBox
              label={key === 'GlobalResource' ? 'Resources' : t(key)}
              value={key}
              isDynamicChecked
              isChecked={courseFilter[key]}
              handleCheckbox={() => handleCheckBox(key)}
            />
          </li>
        ))}
    </>
  );
}

import { ReactElement } from 'react';
import { CourseFilterCheckBoxes } from './CourseFilterCheckBoxes';
import './EntitiesFilter.css';

export function EntitiesFilter(): ReactElement {
  return (
    <div className="EntitiesFilterWrapper">
      <label className="EntitiesFilterHeaderText">Filters</label>
      <ul className="EntitiesFilterCheckBoxList">
        <CourseFilterCheckBoxes />
      </ul>
    </div>
  );
}

import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { parsedHtml, getRandomImage } from '../../utils/helper';
import { InstructorLedSessionData } from '../../models/absorb/instructorLed';
import './SessionHeader.css';

interface Props {
  instructorLedSession?: InstructorLedSessionData;
}

// export function SessionHeader({ instructorLedSession }: Props): ReactElement {
export function SessionHeader({ instructorLedSession }: Props): ReactElement {
  const { t } = useTranslation();

  return (
    <div className="sessionHeaderContainer">
      {instructorLedSession && (
        <div className="sessionHeaderTimeWrapper">
          <div className="sessionHeaderInstructorLedNameLabel">
            <div className="sessionHeaderInstructorLedNameValue" title={instructorLedSession.name}>
              {instructorLedSession.name}
            </div>
            <div className="sessionHeaderStartDateWrapper">
              <span className="sessionHeaderStartDateFormatted">
                {` ${moment(instructorLedSession.currentClass.localStartDate).format('LL')} `}
              </span>
            </div>
            <div className="sessionHeaderEndTimeWrapper">
              <div className="sessionHeaderEndTimeValue">
                {`${moment(instructorLedSession.currentClass.localStartDate).format('h:mm A')} - ${moment(
                  instructorLedSession.currentClass.localEndDate
                ).format('h:mm A')}`}{' '}
                <span className="sessionHeaderEndTimeZone">{`(${instructorLedSession.currentClass.timeZoneShort})`}</span>
              </div>
            </div>
          </div>
          <div className="sessionHeaderClassInfo">
            <div className="sessionHeaderInstructorLinkContainer">
              <div className="sessionHeaderInstructorLinkWrapper">
                {instructorLedSession?.description && (
                  <div className="sessionHeaderDescription">{parsedHtml(instructorLedSession.description)}</div>
                )}

                <span className="sessionHeaderInstructorLabel">{`${t('Instructor')}:`}</span>
                <Link
                  title={`${t('FilterBy')} ${
                    instructorLedSession?.instructors ? instructorLedSession.instructors[0].fullName : ''
                  }`}
                  className="sessionHeaderInstructorLink"
                  to={`/catalog?instructor=${
                    instructorLedSession?.instructors ? instructorLedSession.instructors[0].instructorId : ''
                  }`}
                >
                  {instructorLedSession?.instructors ? instructorLedSession.instructors[0].fullName : ''}
                </Link>
              </div>
            </div>
            {instructorLedSession?.enrollmentLimit && (
              <div className="sessionHeaderLimitLabel">
                {`${t('ClassSize')}:`}
                <span className="sessionHeaderLimitValue">{instructorLedSession.enrollmentLimit}</span>
              </div>
            )}
          </div>
        </div>
      )}

      <div className="sessionHeaderImageWrapper">
        <img
          alt="Course"
          src={getRandomImage(
            instructorLedSession?.id || '',
            '/assets/images/content/default/course/defaultCourseImage',
            'webp',
            14
          )}
        />
      </div>
    </div>
  );
}

import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { InstructorLedDetailsData, InstructorLedSessionsData } from '../../models/absorb/instructorLed';
import { SessionCard } from './SessionCard';
import { SessionHeader } from './SessionHeader';
import './InstructorLedCourseContent.css';

interface Props {
  instructorLedDetails: InstructorLedDetailsData;
  instructorLedSessions: InstructorLedSessionsData;
}

export function InstructorLedCourseContent({ instructorLedDetails, instructorLedSessions }: Props): ReactElement {
  const { t } = useTranslation();

  return (
    <div className="instructorLedPageWrapper">
      <div className="instructorLedPageHeader">{t('CourseContent')}</div>
      <div className="instructorLedPageHeaderSeparator" />
      {instructorLedSessions?.sessions && instructorLedSessions.sessions.length > 0 ? (
        <div className="instructorLedPageSessionHeaderContainer">
          <div className="instructorLedPageSessionHeaderLabel">{t('MySession')}</div>
          <div className="instructorLedPageSessionContainer">
            <SessionHeader
              instructorLedSession={instructorLedSessions.sessions.find(
                (session) => session.courseId === instructorLedDetails.id
              )}
            />
            <div className="instructorLedPageSessionCardContainer">
              {instructorLedSessions.sessions.map((session) => (
                <SessionCard key={session.id} instructorLedSession={session} />
              ))}
            </div>
          </div>
        </div>
      ) : (
        <div className="instructorLedPageTitleWrapper">
          <h2 className="instructorLedPageTitle">{t('MySession')}</h2>
          <div className="instructorLedPageContent">{t('NoSessionsFound')}</div>
        </div>
      )}
    </div>
  );
}

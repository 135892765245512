import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { CurriculumData, CurriculumGroupData } from '../../models/absorb/curriculum';
import { CurriculumGroup } from './CurriculumGroup';
import './CurriculumContent.css';

interface Props {
  curriculum: CurriculumData;
  curriculumId?: string;
}

export function CurriculumContent({ curriculum, curriculumId }: Props): ReactElement {
  const { t } = useTranslation();

  return (
    <div className="curriculumContentDetailWrapper">
      <div className="curriculumContentHeader">{t('CourseContent')}</div>
      <div className="curriculumContentHeaderSeparator"></div>

      {curriculum &&
        curriculum.curriculumGroups.map((curriculumGroup: CurriculumGroupData) => (
          <CurriculumGroup key={curriculumGroup.id} curriculumGroup={curriculumGroup} curriculumId={curriculumId} />
        ))}
    </div>
  );
}

import { ReactElement } from 'react';
import './CourseHero.css';

export function CourseHero(): ReactElement {
  return (
    <div className="heroCourseContentWrapper">
      <div className="heroCourseContent">
        <span className="heroCourseContentMessage"></span>
      </div>
    </div>
  );
}

import { ReactElement, useEffect, useState, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { CatalogCourseData } from '../models/absorb/catalog';
import { DashboardContainerData } from '../models/absorb/dashboard';
import { Spinner } from '../components/controls/Spinner';
import {
  SortBy,
  DASHBOARD_SECTION_MAX_ITEMS,
  DASHBOARD_RESUME_BLOCK_REQUIRED_COURSE_COUNT,
  DASHBOARD_SHOW_FEATURED_BLOCK,
} from '../constants/common';
import { SortCoursesBy } from '../constants/courses';
import { fetchDashboard } from '../services/dashboard';
import { fetchFeaturedCourses, fetchMyCourses, fetchResumeCourse } from '../services/course';
import { fetchMyCatalogCourses } from '../services/catalog';
import { errorHandler } from '../utils/helper';
import { CourseRibbon } from '../components/course/CourseRibbon';
import { RibbonType } from '../constants/absorb';
import { PageTitle } from '../components/global/PageTitle';
import { DashboardLayout } from '../components/dashboard/DashboardLayout';
import './DashboardPage.css';

export function DashboardPage(): ReactElement {
  const { t } = useTranslation();

  const [dashboardData, setDashboardData] = useState<DashboardContainerData[]>([]);
  const [myCourseData, setMyCourseData] = useState<CatalogCourseData[]>([]);
  const [catalogData, setCatalogData] = useState<CatalogCourseData[]>([]);
  const [featuredCourses, setFeaturedCourses] = useState<CatalogCourseData[]>([]);
  const [resumableData, setResumableData] = useState<CatalogCourseData[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isResumeCoursesLoading, setIsResumeCoursesLoading] = useState(false);
  const [isMyCoursesLoading, setIsMyCoursesLoading] = useState(false);
  const [isCatalogLoading, setIsCatalogLoading] = useState(false);
  const [isFeaturedCoursesLoading, setIsFeaturedCoursesLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);

    fetchDashboard()
      .then(setDashboardData)
      .catch(errorHandler)
      .finally(() => setIsLoading(false));
    getCourseDetails();
    getCatalogDetails();
    if (DASHBOARD_SHOW_FEATURED_BLOCK) {
      getFeaturedCourses();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (myCourseData.length >= DASHBOARD_RESUME_BLOCK_REQUIRED_COURSE_COUNT) {
      getResumeCourses();
    }
  }, [myCourseData]);

  function getResumeCourses() {
    setIsResumeCoursesLoading(true);
    fetchResumeCourse({
      showCompleted: true,
      _sort: `${SortCoursesBy.Ispinned},${SortCoursesBy.EnrolledDate}`,
    })
      .then(setResumableData)
      .catch(errorHandler)
      .finally(() => setIsResumeCoursesLoading(false));
  }

  function getCourseDetails() {
    setIsMyCoursesLoading(true);

    fetchMyCourses({
      showCompleted: true,
      _limit: DASHBOARD_SECTION_MAX_ITEMS,
      _sort: `${SortCoursesBy.Ispinned},${SortCoursesBy.EnrolledDate}`,
    })
      .then((data) => setMyCourseData(data.courses))
      .catch(errorHandler)
      .finally(() => setIsMyCoursesLoading(false));
  }

  function getCatalogDetails() {
    setIsCatalogLoading(true);
    fetchMyCatalogCourses({
      showCompleted: true,
      _limit: DASHBOARD_SECTION_MAX_ITEMS,
      _sort: SortBy.EnrollmentDate,
    })
      .then((data) => setCatalogData(data.courses))
      .catch(errorHandler)
      .finally(() => setIsCatalogLoading(false));
  }

  function getFeaturedCourses() {
    setIsFeaturedCoursesLoading(true);
    fetchFeaturedCourses({
      showCompleted: true,
      _limit: DASHBOARD_SECTION_MAX_ITEMS,
      _sort: `${SortCoursesBy.Ispinned},${SortCoursesBy.EnrolledDate}`,
    })
      .then(setFeaturedCourses)
      .catch(errorHandler)
      .finally(() => setIsFeaturedCoursesLoading(false));
  }

  return (
    <div id="dashboardBodyWrapper" className="dashboardBodyWrapper">
      <DashboardLayout>
        <PageTitle title="Dashboard" />
        {isLoading ? (
          <Spinner />
        ) : (
          <div className="dashboardBodyContentWrapper">
            {dashboardData
              // .sort((n1) => -n1.position)
              .map((container: DashboardContainerData) => {
                return (
                  <Fragment key={container.id}>
                    {myCourseData.length >= DASHBOARD_RESUME_BLOCK_REQUIRED_COURSE_COUNT &&
                      container.ribbon &&
                      container.ribbon.ribbonType === RibbonType.Resume &&
                      (isResumeCoursesLoading || resumableData.length > 0) &&
                      (isResumeCoursesLoading ? (
                        <Spinner />
                      ) : (
                        <div className="dashboardSectionWrapper">
                          <CourseRibbon
                            courseData={resumableData}
                            linkTitle={t('ResumeCourses')}
                            viewMoreTitle="View all my courses"
                            link="/my-courses/?_sort=RecentActivity"
                          />
                        </div>
                      ))}

                    {container.ribbon &&
                      container.ribbon.ribbonType === RibbonType.MyCourses &&
                      (isMyCoursesLoading || myCourseData.length > 0) &&
                      (isMyCoursesLoading ? (
                        <Spinner />
                      ) : (
                        <div className="dashboardSectionWrapper">
                          <CourseRibbon
                            courseData={myCourseData}
                            linkTitle={t('MyCourses')}
                            viewMoreTitle="View all my Courses"
                            link="/my-courses"
                          />
                        </div>
                      ))}

                    {container.ribbon &&
                      (container.ribbon.ribbonType === RibbonType.catalog ||
                        container.ribbon.ribbonType === RibbonType.MyCatalog) &&
                      (isCatalogLoading || catalogData.length > 0) &&
                      (isCatalogLoading ? (
                        <Spinner />
                      ) : (
                        <div className="dashboardSectionWrapper">
                          <CourseRibbon
                            courseData={catalogData}
                            linkTitle="Discover all courses"
                            viewMoreTitle="View full catalog"
                            link="/catalog"
                          />
                        </div>
                      ))}

                    {container.ribbon &&
                      container.ribbon.ribbonType === RibbonType.Featured &&
                      (isFeaturedCoursesLoading || featuredCourses.length > 0) &&
                      (isFeaturedCoursesLoading ? (
                        <Spinner />
                      ) : (
                        <div className="dashboardSectionWrapper">
                          <CourseRibbon
                            courseData={featuredCourses}
                            linkTitle={t('FeaturedCourses')}
                            viewMoreTitle="View more Featured Courses"
                            link="/my-courses"
                          />
                        </div>
                      ))}
                  </Fragment>
                );
              })}
          </div>
        )}
      </DashboardLayout>
    </div>
  );
}

import { ReactElement } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'react-i18next';
import { Avatar } from '@mui/material';
import { ScreenBreakpoints } from '../constants/common';
import {
  PROFILE_NAME_BREAKPOINT,
  PROFILE_NAME_LONG_FONTSIZE,
  PROFILE_NAME_REGULAR_FONTSIZE,
} from '../constants/styling';
import { useProfileContext } from '../contexts/profileContext';
import { PageTitle } from '../components/global/PageTitle';
import { DashboardLayout } from '../components/dashboard/DashboardLayout';
import { ProfileDetails } from '../components/profile/ProfileDetails';
import { ProfileChangePassword } from '../components/profile/ProfileChangePassword';
import './ProfilePage.css';

export function ProfilePage(): ReactElement {
  const { t } = useTranslation();

  const { profile } = useProfileContext();

  const firstNameInitial = profile?.firstName ? profile.firstName.charAt(0).toUpperCase() : '';
  const lastNameInitial = profile?.lastName ? profile.lastName.charAt(0).toUpperCase() : '';
  const avatarText = `${firstNameInitial}${lastNameInitial}`;

  const limitedNavbarSpace = useMediaQuery({ maxWidth: ScreenBreakpoints.isTablet - 0.001 });
  const candidateForSmallNameFont =
    +(profile?.firstName?.length ?? 0) + +(profile?.lastName?.length ?? 0) >= PROFILE_NAME_BREAKPOINT;

  return (
    <DashboardLayout>
      <PageTitle title={t('UserProfile')} />
      <div className={profile?.avatar ? 'profileImageBlock' : 'profileInitialBlock'}>
        <Avatar
          sx={{
            bgcolor: '#009FD1',
            width: 198,
            height: 198,
            borderRadius: 5,
            fontSize: 90,
            fontWeight: 600,
          }}
          variant="rounded"
        >
          {profile?.avatar ? (
            <img src={profile.avatar} alt="profile" />
          ) : (
            <div className="profileinitialBlockAvatarText">{avatarText}</div>
          )}
        </Avatar>
        <div className="iconEditProfileAvatar" />
      </div>
      <div className="profileName">
        <span
          className="profileFirstName"
          style={{
            fontSize:
              candidateForSmallNameFont && limitedNavbarSpace
                ? PROFILE_NAME_LONG_FONTSIZE
                : PROFILE_NAME_REGULAR_FONTSIZE,
          }}
        >
          {profile?.firstName}{' '}
        </span>
        <span
          className="profileLastName"
          style={{
            fontSize:
              candidateForSmallNameFont && limitedNavbarSpace
                ? PROFILE_NAME_LONG_FONTSIZE
                : PROFILE_NAME_REGULAR_FONTSIZE,
          }}
        >
          {profile?.lastName}
        </span>
      </div>
      <div className="profilePageEditor">
        <ProfileDetails />
        <ProfileChangePassword />
      </div>
    </DashboardLayout>
  );
}

import { ReactElement, useState, useEffect, MouseEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useLoginContext } from '../../contexts/loginContext';
import { useShoppingContext } from '../../contexts/shoppingContext';
import {
  STATUS_ICON_SHOPPING_CART_ADDED,
  STATUS_ICON_SHOPPING_CART,
  STATUS_ICON_COURSE_ENROLL,
} from '../../constants/styling';
import { formatMoney } from '../../utils/currencyCodes';
import { CatalogCourseData } from '../../models/absorb/catalog';
import { CardViewType } from '../../constants/common';
import { AbsorbCourseType, AbsorbEnrollmentStatus, AbsorbResourceType } from '../../constants/absorb';
import { enrollInCourse, fetchMyCourseEnrollment, reEnrollInCourse } from '../../services/course';
import {
  constructCourseTypePath,
  constructCurriculumCourseTypePath,
  errorHandler,
  getCourseStatusIcon,
  getEnrollmentStatus,
} from '../../utils/helper';
import { SpinnerButton } from '../controls/SpinnerButton';
import { useModalContext } from '../../contexts/modalContext';
import './CourseCardActionButton.css';

interface Props {
  cardData: CatalogCourseData;
  isIconRequired?: boolean;
  isEnrollmentLocked?: boolean;
  curriculumRequiresPurchase?: boolean;
  isModal?: boolean;
  cardViewType: CardViewType;
  curriculum?: string;
}

export function CourseCardActionButton({
  cardData,
  isIconRequired,
  isEnrollmentLocked,
  curriculumRequiresPurchase,
  isModal = false,
  cardViewType,
  curriculum,
}: Props): ReactElement {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { loggedIn } = useLoginContext();
  const { closeModal } = useModalContext();
  const { cartData, addToCart } = useShoppingContext();

  const disableEnrollment = isEnrollmentLocked || (curriculumRequiresPurchase && cardData.enrollmentStatus === null);

  const [addedToCart, setAddedToCart] = useState(false);
  const [courseEnrollmentStatus, setCourseEnrollmentStatus] = useState(cardData.enrollmentStatus);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const filteredData = cartData.filter((item) => item.id === cardData.id);

    setAddedToCart(filteredData.length > 0);
  }, [cartData, cartData.length, cardData.id]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    getButtonIcon();
  }, [cardData.enrollmentStatus]); // eslint-disable-line react-hooks/exhaustive-deps

  function addCourseToCart(data: CatalogCourseData) {
    if (addedToCart || !loggedIn) return;
    addToCart([...cartData, data]);
  }

  function getButtonPrice() {
    return cardData.price && !addedToCart ? formatMoney(cardData?.currencyAbbreviation, cardData?.price) : '';
  }

  function getButtonText() {
    if (cardData.price) {
      return addedToCart ? `${t('AddedToCart')}` : 'Buy ';
    }

    if (cardData.courseType === AbsorbCourseType.InstructorLedCourse) {
      return `${t('LaunchInstructorLedCourse')}`;
    }

    if (checkForReEnrollment()) {
      return t('Reenroll');
    }

    if (courseEnrollmentStatus) {
      return t(getEnrollmentStatus(courseEnrollmentStatus));
    }

    if (!cardData.courseType || cardData.courseType === AbsorbResourceType.Faq) {
      return t('Read');
    }

    if (cardData?.price !== null) {
      return 'Buy ';
    }

    return t('Enroll');
  }

  function checkForReEnrollment() {
    return (
      courseEnrollmentStatus === AbsorbEnrollmentStatus.Complete &&
      cardData._links !== undefined && // eslint-disable-line no-underscore-dangle
      cardData._links.hasOwnProperty('reEnroll') // eslint-disable-line no-underscore-dangle, no-prototype-builtins
    );
  }

  function getButtonIcon() {
    let icon = STATUS_ICON_SHOPPING_CART;

    if (cardData.price) {
      icon = addedToCart ? STATUS_ICON_SHOPPING_CART_ADDED : STATUS_ICON_SHOPPING_CART;
    } else if (checkForReEnrollment()) {
      icon = STATUS_ICON_COURSE_ENROLL;
    } else {
      icon = getCourseStatusIcon(cardData.enrollmentStatus);
    }

    return icon;
  }

  function enrollUserInCourse(courseId: string) {
    setIsLoading(true);
    enrollInCourse({ courseId })
      .then(() => fetchMyCourseEnrollment(courseId))
      .then((data) => {
        setIsLoading(false);

        if (isModal) {
          closeModal();
          navigate(constructCourseTypePath(cardData.courseType, cardData.id));
        }
        Object.assign(cardData, { enrollmentStatus: data.enrollmentStatus, enrolled: true });
        setCourseEnrollmentStatus(data.enrollmentStatus);
      })
      .catch((error) => {
        // TODO: enrollInCourse seems to succeed, even though it always returns a 400 error,
        // so just fetching the current enrollment here in the catch as it likely changed.
        // Will have to look in back-end code to see if the issue is there.
        fetchMyCourseEnrollment(courseId).then((data) => {
          Object.assign(cardData, { enrollmentStatus: data.enrollmentStatus, enrolled: true });
          setCourseEnrollmentStatus(data.enrollmentStatus);
        });

        errorHandler(error);
        setIsLoading(false);
      })
      .finally(() => {
        if (cardData.courseType === AbsorbCourseType.CourseBundle) {
          navigate('/my-courses');
        }
      });
  }

  function reEnrollUserInCourse(courseId: string) {
    setIsLoading(true);
    reEnrollInCourse(courseId)
      .then(() => fetchMyCourseEnrollment(courseId))
      .then((data) => {
        setCourseEnrollmentStatus(data.enrollmentStatus);
      })
      .catch(errorHandler)
      .finally(() => setIsLoading(false));
  }

  function onClickCourse(e: MouseEvent<HTMLButtonElement>) {
    e.stopPropagation();
    if (addedToCart) {
      closeModal();
      navigate('/cart');
    } else if (cardData.price) {
      addCourseToCart(cardData);
    } else if (cardData?.enrollmentStatus === null) {
      enrollUserInCourse(cardData?.id);
    } else if (checkForReEnrollment()) {
      reEnrollUserInCourse(cardData?.id);
    } else {
      closeModal();
      if (curriculum) {
        navigate(constructCurriculumCourseTypePath(cardData.courseType, curriculum, cardData.id));
      } else {
        navigate(constructCourseTypePath(cardData.courseType, cardData.id));
      }
    }
  }

  function getCardCssVersion(classname: string) {
    let version = classname;
    if (courseEnrollmentStatus === (AbsorbEnrollmentStatus.NotStarted || AbsorbEnrollmentStatus.NotComplete)) {
      version = `${classname}InProgress`;
    }
    if (courseEnrollmentStatus === AbsorbEnrollmentStatus.Complete) {
      version = `${classname}Complete`;
    }
    if (courseEnrollmentStatus === AbsorbEnrollmentStatus.InProgress) {
      version = `${classname}InProgress`;
    }
    version = `${cardViewType}${version}`;
    return version;
  }

  return (
    <div
      className={
        addedToCart ? `${cardViewType}ActionButtonContainerInCart` : getCardCssVersion('ActionButtonContainer')
      }
    >
      <button
        type="button"
        aria-disabled={disableEnrollment ? 'true' : 'false'}
        aria-label={cardData.name}
        disabled={isLoading}
        className={addedToCart ? `${cardViewType}ActionButtonInCart` : getCardCssVersion('ActionButton')}
        onClick={(e) => (disableEnrollment ? null : onClickCourse(e))}
      >
        {isLoading ? (
          <SpinnerButton inverted />
        ) : (
          <div className={`${cardViewType}ActionButtonTextWrapper`}>
            <div className={`${cardViewType}ActionButtonText`}>
              {getButtonText()}
              <span className="genericText">{getButtonPrice()}</span>
            </div>
            <div className="cardActionButtonIcon" />
            {isIconRequired && <div className={`${getButtonIcon()}`} />}
          </div>
        )}
      </button>
    </div>
  );
}

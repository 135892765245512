import { ReactElement } from 'react';
import { HeaderLogoBranding } from './HeaderLogoBranding';
import { HeaderSearchInput } from './HeaderSearchInput';
import { HeaderMobileMenu } from './HeaderMobileMenu';
import { useLoginContext } from '../../../contexts/loginContext';

export function HeaderMobile(): ReactElement {
  const { loggedIn, termsAndConditions } = useLoginContext();

  return (
    <div>
      {loggedIn && !termsAndConditions?.currentUserMustAcceptTermsAndConditions ? (
        <div className="E1_header">
          <div className="header_left_mobile">
            <HeaderLogoBranding />
            {/* <HeaderPrimaryNavigation /> */}
          </div>
          <div className="header_center_mobile">
            <HeaderSearchInput />
          </div>
          <div className="header_right_mobile">
            <HeaderMobileMenu />
          </div>
        </div>
      ) : (
        <div className="E1_header">
          <div className="header_left">
            <HeaderLogoBranding />
          </div>
          <div className="header_center" />
          <div className="header_right" />
        </div>
      )}
    </div>
  );
}

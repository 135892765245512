import moment from 'moment';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { InstructorLedSessionData } from '../../models/absorb/instructorLed';
import './SessionCard.css';

interface Props {
  instructorLedSession: InstructorLedSessionData;
}

export function SessionCard({ instructorLedSession }: Props): ReactElement {
  const { t } = useTranslation();

  return (
    <div className="sessionCardWrapper">
      <div className="sessionCardTimingWrapper">
        <div className="sessionCardTiming">
          <span className="sessionCardDateTimeLabel">Date/Time:</span>
          <span className="sessionCardEndDateTimeLabel">
            {` ${moment(instructorLedSession.currentClass.localStartDate).format('LL')}, `}
            {`${moment(instructorLedSession.currentClass.localStartDate).format('h:mm A')} - ${moment(
              instructorLedSession.currentClass.localEndDate
            ).format('h:mm A')} `}
            <span className="sessionCardTimingTimeZone">{`(${instructorLedSession.currentClass.timeZoneShort})`}</span>
          </span>
        </div>
      </div>
      <div className="sessionCardLocationWrapper">
        <div className="sessionCardLocationValue">
          <span className="sessionCardLocationLabel">{`${t('Location')}:`}</span>
          {`${instructorLedSession.currentClass.venue?.name || ''} - `}
          <a target="_blank" rel="noopener noreferrer" href={instructorLedSession.currentClass.meetingUrl}>
            {instructorLedSession.currentClass.meetingUrl}
          </a>
        </div>
      </div>
    </div>
  );
}

import { ReactElement, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { LoginForm } from '../components/login/LoginForm';
import { LoginSplash } from '../components/login/LoginSplash';
import { useLoginContext } from '../contexts/loginContext';
import { useModalContext } from '../contexts/modalContext';
import { TimeoutModal } from '../modals/TimeoutModal';
import { ResetPasswordForm } from '../components/login/ResetPasswordForm';
import './LoginPage.css';

export function LoginPage(): ReactElement {
  const navigate = useNavigate();

  const { tokenTimedOut, loggedIn } = useLoginContext();
  const { modal, showModal } = useModalContext();

  useEffect(() => {
    if (tokenTimedOut) {
      showModal(<TimeoutModal />);
    }
  }, [tokenTimedOut]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (loggedIn) {
      navigate('/dashboard');
    }
  }, [loggedIn]); // eslint-disable-line react-hooks/exhaustive-deps

  const location = useLocation();

  return (
    <div id="login_container">
      <LoginSplash />
      <div id="actions">
        <div className="formWrapper">
          {queryString.parse(location?.search).form === 'reset-password' ? (
            <ResetPasswordForm initialEmail={`${queryString.parse(location?.search).email}`} />
          ) : (
            <LoginForm />
          )}
        </div>
      </div>
      {modal || null}
    </div>
  );
}

import { ReactElement, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { fetchGlobalSearchResults } from '../services/search';
import { errorHandler } from '../utils/helper';
import { useSearchContext } from '../contexts/searchContext';
import { SearchResultsData } from '../models/absorb/search';
import { SortCoursesBy } from '../constants/courses';
import { SEARCH_DEFAULT_PAGE_OFFSET, DisplayMessages } from '../constants/common';
import { DashboardLayout } from '../components/dashboard/DashboardLayout';
import { PageTitle } from '../components/global/PageTitle';
import { SearchResultView } from '../components/search/SearchResultView';
import { LoadMoreActionButton } from '../components/controls/LoadMoreActionButton';
import { Spinner } from '../components/controls/Spinner';
import { MessageDisplayView } from '../components/global/MessageDisplayView';
import { useModalContext } from '../contexts/modalContext';
import './GlobalSearchPage.css';

export function GlobalSearchPage(): ReactElement {
  const { t } = useTranslation();
  const { modal } = useModalContext();
  const { currentSearchUri, searchFilter, pageOffset, setDisableFilter, incrementPageOffset } = useSearchContext();

  const [searchInProgress, setSearchInProgress] = useState(true);
  const [loadingMoreInProgress, setLoadingMoreInProgress] = useState(false);
  const [globalSearchResults, setGlobalSearchResults] = useState<SearchResultsData>();

  useEffect(() => {
    if (currentSearchUri.includes('/global-search')) {
      setGlobalSearchResults(undefined);
      fetchSearchData();
    }
  }, [currentSearchUri]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (pageOffset !== SEARCH_DEFAULT_PAGE_OFFSET) {
      fetchSearchData();
    }
  }, [pageOffset]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setGlobalSearchResults(globalSearchResults);
  }, [globalSearchResults]); // eslint-disable-line react-hooks/exhaustive-deps

  function fetchSearchData() {
    if (!searchFilter.term) {
      return;
    }

    if (!loadingMoreInProgress) {
      setSearchInProgress(true);
    }

    setDisableFilter(true);

    fetchGlobalSearchResults({ ...searchFilter, sortBy: SortCoursesBy.Relevance }, pageOffset)
      .then((data) => {
        if (globalSearchResults && pageOffset > SEARCH_DEFAULT_PAGE_OFFSET) {
          setGlobalSearchResults({
            ...globalSearchResults,
            searchResults: [...globalSearchResults.searchResults, ...data.searchResults],
          });
        } else {
          setGlobalSearchResults(data);
        }
      })
      .catch(errorHandler)
      .finally(() => {
        setSearchInProgress(false);
        setDisableFilter(false);
        setLoadingMoreInProgress(false);
      });
  }

  function loadMore() {
    setLoadingMoreInProgress(true);
    incrementPageOffset();
  }

  function getSearchResultHeading(): string {
    const searchHeading = t('TotalSearchResultsFor');
    const resultCount = globalSearchResults?.totalItems?.toString() || '0';
    const resultFor = (searchFilter.term as string) || '';
    return searchHeading.replace('{0}', resultCount).replace('{1}', resultFor);
  }

  function renderSearchResultStatus() {
    if (searchInProgress) {
      return <Spinner />;
    }

    if ((globalSearchResults === undefined || globalSearchResults.totalItems === 0) && !searchInProgress) {
      return (
        <MessageDisplayView
          errorText={DisplayMessages.NoResultsMainText}
          subErrorText={DisplayMessages.NoResultsSubText}
        />
      );
    }

    return (
      <div className="globalSearchResultsReturnedItems">
        {globalSearchResults && <SearchResultView searchResult={globalSearchResults} />}
        {globalSearchResults && globalSearchResults.totalItems > globalSearchResults.searchResults.length ? (
          <LoadMoreActionButton
            currentItemsCount={globalSearchResults.searchResults.length || 0}
            totalItemsCount={globalSearchResults.totalItems}
            itemsAreLoading={loadingMoreInProgress}
            fetchMoreFunction={loadMore}
          />
        ) : null}
      </div>
    );
  }

  return (
    <DashboardLayout>
      <PageTitle title="Search" />
      <div className="globalSearchPageWrapper">
        <div className="globalSearchPageHeader">
          <h1 className="globalSearchPageHeaderText">{t('SearchResults')}</h1>
        </div>
        <div className="globalSearchPageResults">
          <div className="globalSearchPageResultsNotification">
            {globalSearchResults &&
              (searchInProgress ? null : (
                <div className="globalSearchPageParameterAndResults">
                  {globalSearchResults.totalItems && searchFilter.term ? (
                    <div className="globalSearchPageResultsHeading">{getSearchResultHeading()}</div>
                  ) : null}
                </div>
              ))}
          </div>
          <div className={` ${searchInProgress ? '' : ''}`}>{renderSearchResultStatus()}</div>
        </div>
      </div>
      {modal || null}
    </DashboardLayout>
  );
}

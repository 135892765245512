import { ReactElement } from 'react';
import Iframe from 'react-iframe';
import { CoursePlayerType } from '../../constants/absorb';
import { MyLessonData } from '../../models/absorb/lessons';
import { LessonPlayerWrapper } from './LessonPlayerWrapper';
import { ObjectLesson } from './ObjectLesson';
import { VideoLesson } from './VideoLesson';
import './LessonPlayer.css';

interface Props {
  courseUrl: string;
  lessonData: MyLessonData;
  nextLessonData?: MyLessonData;
  openLessonPlayer: (id: string) => void;
  closeLessonPlayer: () => void;
}

export function LessonPlayer({
  courseUrl,
  lessonData,
  nextLessonData,
  openLessonPlayer,
  closeLessonPlayer,
}: Props): ReactElement {
  function changeView(lessonType: string): ReactElement {
    switch (lessonType) {
      case CoursePlayerType.ThirdPartyLesson:
      case CoursePlayerType.Exam:
      case CoursePlayerType.FlashLesson:
      case CoursePlayerType.TaskLesson: {
        return (
          /* TODO: test tin can content in iframe without position="absolute", does that fix the controls that are currently offscreen. */
          <Iframe url={courseUrl} position="absolute" width="100%" className="lessonPlayerIframe" height="100%" />
        );
      }

      case CoursePlayerType.VideoLesson: {
        return (
          <VideoLesson
            lessonData={lessonData}
            nextLessonData={nextLessonData}
            openLessonPlayer={openLessonPlayer}
            closeLessonPlayer={closeLessonPlayer}
          />
        );
      }

      case CoursePlayerType.ObjectLesson: {
        return <ObjectLesson myLesson={lessonData} closeLessonPlayer={closeLessonPlayer} />;
      }

      default: {
        return <></>;
      }
    }
  }

  const renderedLessonPlayer: ReactElement = lessonData && changeView(lessonData.type);

  return (
    <div className="root__container">
      {lessonData.type === CoursePlayerType.ObjectLesson ||
      lessonData.type === CoursePlayerType.VideoLesson ||
      lessonData.type === CoursePlayerType.Exam ||
      lessonData.type === CoursePlayerType.TaskLesson ||
      lessonData.type === CoursePlayerType.ThirdPartyLesson ? (
        <LessonPlayerWrapper lessonName={lessonData.name} closeLessonPlayer={closeLessonPlayer}>
          {renderedLessonPlayer}
        </LessonPlayerWrapper>
      ) : (
        renderedLessonPlayer
      )}
    </div>
  );
}

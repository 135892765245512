import { ReactNode, ReactElement, MouseEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import { CatalogCourseData } from '../../models/absorb/catalog';
// TODO: relates to feature/E1-: { AbsorbCourseType, AbsorbResourceType } from '../../constants/absorb';
import { CourseDetailClickType } from '../../constants/common';
import { constructCourseTypePath } from '../../utils/helper';

interface Props {
  cssClass: string;
  children: ReactNode;
  cardData: CatalogCourseData;
  clickType: CourseDetailClickType;
  courseLocked?: boolean;
  // TODO:feature/E1-1002: updateTilePinnedStatus?: (isPinned: boolean, courseId: string) => void;
}

export function CourseAction({
  cssClass,
  children,
  cardData,
  clickType,
  courseLocked = false,
}: // TODO: feature/E1-1002: updateTilePinnedStatus,
Props): ReactElement {
  const navigate = useNavigate();

  function onClickCourse(e: MouseEvent<HTMLElement>, courseDetailData: CatalogCourseData) {
    e.stopPropagation();

    // TODO: feature/E1-958 (evaluate following block when there is curriculum and faq, etc)
    // if (courseDetailData.courseType && (courseDetailData?.price !== null || courseDetailData.enrolled === false)) {
    //   // not enrolled and $$
    //   if (courseDetailData.courseType === AbsorbCourseType.Curriculum) {
    //     // showModal(<CurriculumDetailModal updateTilePinnedStatus={updateTilePinnedStatus} courseData={cardData} />);
    //     // go to curriculum
    //   } else {
    //     // showModal(<CourseDetailModal updateTilePinnedStatus={updateTilePinnedStatus} courseData={cardData} />);
    //     // go to course
    //   }
    // } else if (courseDetailData.price === null && courseDetailData.courseType) {
    //   history.push(constructCourseTypePath(courseDetailData.courseType, courseDetailData.id));
    // } else if (courseDetailData.courseType === AbsorbResourceType.Faq || courseDetailData.question !== null) {
    //   // showModal(<FaqModal faqData={courseDetailData} />);
    //   // go to faq
    // }

    navigate(constructCourseTypePath(courseDetailData.courseType, courseDetailData.id));
  }

  function getElementTypeOnCourseLock() {
    if (courseLocked) {
      return <div className={cssClass}>{children}</div>;
    }
    return (
      <button className={cssClass} onClick={(e) => !courseLocked && onClickCourse(e, cardData)}>
        {children}
      </button>
    );
  }

  return (
    <>
      {clickType === CourseDetailClickType.Link ? (
        <div className={cssClass} onClick={(e) => !courseLocked && onClickCourse(e, cardData)}>
          {children}
        </div>
      ) : (
        getElementTypeOnCourseLock()
      )}
    </>
  );
}

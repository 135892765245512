import { ReactElement, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLoginContext } from '../contexts/loginContext';
import { useProfileContext } from '../contexts/profileContext';
import { DashboardLayout } from '../components/dashboard/DashboardLayout';
import { parsedHtml } from '../utils/helper';
import { Spinner } from '../components/controls/Spinner';
import { SpinnerButton } from '../components/controls/SpinnerButton';
import './TermsAndConditionsPage.css';

export function TermsAndConditionsPage(): ReactElement {
  const { t } = useTranslation();

  const {
    termsAndConditions,
    signOutInProgress,
    acceptTermsAndConditionsInProgress,
    retrieveTermsAndConditions,
    acceptTermsAndConditions,
    signOut,
  } = useLoginContext();
  const { profile } = useProfileContext();

  useEffect(() => {
    if (termsAndConditions === undefined) {
      retrieveTermsAndConditions();
    } else if (!termsAndConditions.currentUserMustAcceptTermsAndConditions) {
      window.location.href = '/dashboard';
    }
  }, [termsAndConditions]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <DashboardLayout>
      <div className="termsAndConditionsWrapper">
        {profile?.departmentName}
        {termsAndConditions !== undefined ? (
          <div className="termsAndConditionsHeader">{t('TermsAndConditions')}</div>
        ) : (
          ''
        )}
        <div className="termsAndConditionsContainer">
          {termsAndConditions?.currentUserMustAcceptTermsAndConditions ? (
            <div className="termsAndConditionsText">{parsedHtml(termsAndConditions.termsAndConditions?.text)}</div>
          ) : (
            <Spinner />
          )}
          {termsAndConditions?.currentUserMustAcceptTermsAndConditions ? (
            <div className="termsAndConditionsOptions">
              <button
                type="button"
                className="button-default-l"
                title={t('IAgreeTermsAndConditions')}
                aria-label={t('IAgreeTermsAndConditions')}
                disabled={!termsAndConditions} /* Disables agree if TaC haven't loaded yet */
                onClick={acceptTermsAndConditions}
              >
                {acceptTermsAndConditionsInProgress ? <SpinnerButton /> : t('IAgree')}
              </button>
              <button
                type="button"
                className="button-reverse-l"
                title={t('IDisagreeTermsAndConditions')}
                aria-label={t('IDisagreeTermsAndConditions')}
                onClick={signOut}
                disabled={!termsAndConditions}
              >
                {signOutInProgress ? <SpinnerButton /> : t('Disagree')}
              </button>
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
    </DashboardLayout>
  );
}

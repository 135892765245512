import { apiRequest } from '../utils/axios';
import { SearchFilterParams, SearchResultsData } from '../models/absorb/search';
import { SEARCH_DEFAULT_PAGE_OFFSET, GLOBAL_SEARCH_TAG } from '../constants/common';

export async function fetchGlobalSearchResults(
  searchFilter: SearchFilterParams,
  offset = SEARCH_DEFAULT_PAGE_OFFSET,
  tags = GLOBAL_SEARCH_TAG
): Promise<SearchResultsData> {
  const url = formatSearchUrl(`/search?tags=${tags}&_offset=${offset}&`, searchFilter);
  return apiRequest('GET', url);
}

function formatSearchUrl(baseUrl: string, searchFilter: SearchFilterParams): string {
  let url = baseUrl;
  const data = JSON.parse(JSON.stringify(searchFilter));

  Object.keys(data).forEach((key) => {
    if (data[key]) {
      url += `${url === baseUrl ? '' : '&'}${key}=${data[key]}`;
    }
  });

  if (url.includes('&sort=')) {
    url = url.replace('&sort=', '&_sort=');
  }

  return url;
}

import { ReactElement, useState, useEffect, MouseEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useLoginContext } from '../../contexts/loginContext';
import { useShoppingContext } from '../../contexts/shoppingContext';
import { AbsorbEnrollmentStatus } from '../../constants/absorb';
import { CatalogCourseData } from '../../models/absorb/catalog';
import { getEnrollmentStatus } from '../../utils/helper';
import { formatMoney } from '../../utils/currencyCodes';
import { SpinnerButton } from '../controls/SpinnerButton';
import iconBlueCheckmarkCircle from '../../assets/images/icons/blueCheckmarkCircle.svg';
import iconBlueCart from '../../assets/images/icons/cartBlueIcon.svg';
import iconWhitePlusCircle from '../../assets/images/icons/whitePlusCircle.svg';
import whitePlayCircle from '../../assets/images/icons/whitePlayCircle.svg';
import { CourseChaptersData, OnlineCourseData } from '../../models/absorb/course';
import { LessonsData } from '../../models/absorb/lessons';
import './CoursePageActionButton.css';

interface Props {
  course?: OnlineCourseData;
  catalogCourse?: CatalogCourseData;
  courseEnrollmentStatus: string;
  isLoadingData: boolean;
  courseDetails?: CourseChaptersData[];
  openPlayer: (id: string) => void;
  enrollUserInCourse: () => void;
  reEnrollUserInCourse: () => void;
}

export function CoursePageActionButton({
  course,
  catalogCourse,
  courseEnrollmentStatus,
  isLoadingData,
  courseDetails,
  openPlayer: openLessonPlayer,
  enrollUserInCourse,
  reEnrollUserInCourse,
}: Props): ReactElement {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { cartData, addToCart } = useShoppingContext();
  const { loggedIn } = useLoginContext();
  const [addedToCart, setAddedToCart] = useState(false);

  let lessonIdToStartOrResume = '';

  useEffect(() => {
    const filteredData = cartData.filter((item) => item.id === course?.id);
    setAddedToCart(filteredData.length > 0);
  }, [cartData, course?.id]);

  useEffect(() => {
    getPagePrimaryActionButtonText();
    getPagePrimaryActionButtonPrice();
    getPagePrimaryActionButtonIcon();
    getPagePrimaryActionButtonCssName();
  }, [courseEnrollmentStatus]); // eslint-disable-line react-hooks/exhaustive-deps

  function addCourseToCart(data: CatalogCourseData) {
    if (addedToCart || !loggedIn) return;
    addToCart([...cartData, data]);
  }

  function getPagePrimaryActionButtonCssName() {
    if (courseEnrollmentStatus === AbsorbEnrollmentStatus.Complete && !checkForReEnrollment()) {
      return 'pageActionAreaLinkButtonNoAction';
    }
    if (addedToCart) {
      return 'pageActionAreaLinkButtonReverse';
    }
    return 'pageActionAreaLinkButton';
  }

  function getPagePrimaryActionButtonText() {
    if (checkForReEnrollment()) {
      return t('Reenroll');
    }
    if (courseEnrollmentStatus === AbsorbEnrollmentStatus.Complete) {
      return 'Completed';
    }
    if (addedToCart) {
      return 'Added to Cart';
    }
    if (
      courseEnrollmentStatus === AbsorbEnrollmentStatus.NotStarted ||
      courseEnrollmentStatus === AbsorbEnrollmentStatus.InProgress
    ) {
      return lessonStartOrResume();
    }
    if (
      (courseEnrollmentStatus === AbsorbEnrollmentStatus.Empty && catalogCourse && catalogCourse.price == null) ||
      catalogCourse?.price === 0
    ) {
      return 'Enroll';
    }
    if (courseEnrollmentStatus === AbsorbEnrollmentStatus.Empty && catalogCourse && catalogCourse.price > 0) {
      return `Buy`;
    }
    return '';
  }

  function lessonStartOrResume(): string {
    let firstIncompleteLesson: LessonsData | undefined;
    let lessonIndex = 1;
    if (courseDetails && courseDetails.length) {
      // eslint-disable-next-line no-restricted-syntax
      for (const chapter of courseDetails) {
        if (chapter.enrollment?.progress !== null && course !== undefined) {
          // eslint-disable-next-line no-restricted-syntax
          for (const lesson of chapter.lessons) {
            if (
              lesson.enrollment?.status === AbsorbEnrollmentStatus.NotStarted ||
              lesson.enrollment?.status === AbsorbEnrollmentStatus.InProgress
            ) {
              firstIncompleteLesson = lesson;
              break;
            }
            lessonIndex += 1;
          }
          if (firstIncompleteLesson) {
            break;
          }
        }
      }
    }

    if (firstIncompleteLesson) {
      lessonIdToStartOrResume = firstIncompleteLesson.id;
      return `${t(getEnrollmentStatus(firstIncompleteLesson.enrollment.status))} Lesson ${lessonIndex}`;
    }
    return 'Course Completed';
  }

  function checkForReEnrollment() {
    return (
      courseEnrollmentStatus === AbsorbEnrollmentStatus.Complete &&
      catalogCourse &&
      catalogCourse._links !== undefined && // eslint-disable-line no-underscore-dangle
      catalogCourse._links.hasOwnProperty('reEnroll') // eslint-disable-line no-underscore-dangle, no-prototype-builtins
    );
  }

  function getPagePrimaryActionButtonIcon() {
    if (checkForReEnrollment()) {
      return iconWhitePlusCircle;
    }
    if (courseEnrollmentStatus === AbsorbEnrollmentStatus.Complete) {
      return iconBlueCheckmarkCircle;
    }
    if (addedToCart) {
      return iconBlueCart;
    }
    if (
      courseEnrollmentStatus === AbsorbEnrollmentStatus.NotStarted ||
      courseEnrollmentStatus === AbsorbEnrollmentStatus.InProgress ||
      courseEnrollmentStatus === AbsorbEnrollmentStatus.NotComplete
    ) {
      return whitePlayCircle;
    }
    if (
      (courseEnrollmentStatus !== AbsorbEnrollmentStatus.Complete && catalogCourse && catalogCourse.price == null) ||
      catalogCourse?.price === 0
    ) {
      return iconWhitePlusCircle;
    }
    if (courseEnrollmentStatus !== AbsorbEnrollmentStatus.Complete && catalogCourse && catalogCourse.price > 0) {
      return whitePlayCircle;
    }
    return whitePlayCircle;
  }

  function getPagePrimaryActionButtonPrice() {
    if (
      !addedToCart &&
      courseEnrollmentStatus !== AbsorbEnrollmentStatus.NotStarted &&
      courseEnrollmentStatus !== AbsorbEnrollmentStatus.Complete &&
      courseEnrollmentStatus !== AbsorbEnrollmentStatus.InProgress &&
      catalogCourse &&
      catalogCourse.price > 0
    ) {
      return formatMoney(catalogCourse?.currencyAbbreviation, catalogCourse?.price).toString();
    }
    return '';
  }

  function primaryActionButtonHandler(e: MouseEvent<HTMLButtonElement>) {
    e.stopPropagation();
    if (
      courseEnrollmentStatus === AbsorbEnrollmentStatus.NotStarted ||
      courseEnrollmentStatus === AbsorbEnrollmentStatus.InProgress
    ) {
      return openLessonPlayer(lessonIdToStartOrResume);
    }
    if (
      (courseEnrollmentStatus !== AbsorbEnrollmentStatus.Complete &&
        courseEnrollmentStatus !== AbsorbEnrollmentStatus.NotStarted &&
        courseEnrollmentStatus !== AbsorbEnrollmentStatus.InProgress &&
        catalogCourse &&
        catalogCourse.price == null) ||
      catalogCourse?.price === 0
    ) {
      return enrollUserInCourse();
    }
    if (checkForReEnrollment()) {
      return reEnrollUserInCourse();
    }
    if (courseEnrollmentStatus !== AbsorbEnrollmentStatus.Complete && catalogCourse && catalogCourse.price > 0) {
      return addCourseToCart(catalogCourse);
    }
    if (addedToCart) {
      navigate('/cart');
    }
    if (courseEnrollmentStatus === AbsorbEnrollmentStatus.Complete) {
      return null;
    }
    return null;
  }

  return (
    <div className="pageActionAreaLinks">
      <button
        aria-label={course?.name}
        type="button"
        className={getPagePrimaryActionButtonCssName()}
        disabled={isLoadingData}
        onClick={(e) => (course ? primaryActionButtonHandler(e) : null)}
      >
        {isLoadingData ? (
          <div className="pageActionAreaLinksSpinner">
            <SpinnerButton />
          </div>
        ) : (
          <div className="pageActionAreaLinkButtonContent">
            <div className="pageActionAreaLinkButtonImage">
              <img
                className="pageActionAreaLinkButtonImageCss"
                alt="Course Action"
                src={getPagePrimaryActionButtonIcon()}
              />
            </div>
            <div className="pageActionAreaLinkButtonText">{getPagePrimaryActionButtonText()}</div>
            <div className="pageActionAreaLinkButtonTextPrice">{getPagePrimaryActionButtonPrice()}</div>
          </div>
        )}
      </button>
    </div>
  );
}

import { ReactElement } from 'react';
import './SearchHero.css';

export function SearchHero(): ReactElement {
  return (
    <div className="searchHero">
      <div className="heroCatalogContent">
        <span className="heroCatalogContentMessage">Search</span>
      </div>
    </div>
  );
}

import { MessageReadData, MessageResponse } from '../models/absorb/message';
import { apiRequest } from '../utils/axios';
import { DEFAULT_MESSAGE_RETRIEVAL_COUNT, DEFAULT_MESSAGEREAD_RETRIEVAL_COUNT } from '../constants/messages';

export async function fetchMessages(offset: number, limit = DEFAULT_MESSAGE_RETRIEVAL_COUNT): Promise<MessageResponse> {
  return apiRequest('GET', `/my-messages/?_offset=${offset}&_limit=${limit}`);
}

export async function fetchMessagesRead(
  offset: number,
  limit = DEFAULT_MESSAGEREAD_RETRIEVAL_COUNT
): Promise<MessageResponse> {
  return apiRequest('GET', `/my-messages/read/?_offset=${offset}&_limit=${limit}`);
}

// export async function fetchHeaderMessages(): Promise<MessageResponse> {
//   return apiRequest('GET', `/my-messages/?_limit=5&read=false`);
// }

// export async function fetchMessageCount(): Promise<number> {
//   return apiRequest('GET', `/my-messages/count`);
// }

export async function updateReadMessage(payload: MessageReadData, id: string): Promise<boolean> {
  return apiRequest('PUT', `/my-messages/${id}/read/`, payload).then((data) => data.read);
}

export async function updateReadAllMessage(): Promise<void> {
  return apiRequest('POST', `/my-messages/read`);
}

import { ReactElement } from 'react';
import fbAuthLogo from '../../../assets/images/global/footer/icon_social_auth_facebook.svg';
import twAuthLogo from '../../../assets/images/global/footer/icon_social_auth_twitter.svg';
import inAuthLogo from '../../../assets/images/global/footer/icon_social_auth_instagram.svg';
import liAuthLogo from '../../../assets/images/global/footer/icon_social_auth_linkedin.svg';
import fbUnauthLogo from '../../../assets/images/global/footer/icon_social_unauth_facebook.svg';
import twUnauthLogo from '../../../assets/images/global/footer/icon_social_unauth_twitter.svg';
import inUnauthLogo from '../../../assets/images/global/footer/icon_social_unauth_instagram.svg';
import liUnauthLogo from '../../../assets/images/global/footer/icon_social_unauth_linkedin.svg';
import { useLoginContext } from '../../../contexts/loginContext';

export function FooterSocialMediaLinks(): ReactElement {
  const { loggedIn } = useLoginContext();

  return (
    <div className="social">
      <div className="social_item">
        <a target="_blank" rel="noreferrer" href="https://twitter.com/endeavor_tech">
          <img
            className={loggedIn ? 'footerAuthSocialMediaIcon' : ''}
            alt="Visit us on Twitter"
            src={loggedIn ? twAuthLogo : twUnauthLogo}
          />
        </a>
      </div>
      <div className="social_item">
        <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/company/endeavor-technologies-corp-/">
          <img
            className={loggedIn ? 'footerAuthSocialMediaIcon' : ''}
            alt="Visit us on Linked In"
            src={loggedIn ? liAuthLogo : liUnauthLogo}
          />
        </a>
      </div>
      <div className="social_item">
        <a target="_blank" rel="noreferrer" href="https://www.instagram.com/endeavortech/">
          <img
            className={loggedIn ? 'footerAuthSocialMediaIcon' : ''}
            alt="Visit us on Instagram"
            src={loggedIn ? inAuthLogo : inUnauthLogo}
          />
        </a>
      </div>
      <div className="social_item">
        <a target="_blank" rel="noreferrer" href="https://www.facebook.com/EndeavorTechnologiesCorp/">
          <img
            className={loggedIn ? 'footerAuthSocialMediaIcon' : ''}
            alt="Visit us on Facebook"
            src={loggedIn ? fbAuthLogo : fbUnauthLogo}
          />
        </a>
      </div>
    </div>
  );
}

import { ReactElement, ReactNode } from 'react';
import { useLoginContext } from '../../contexts/loginContext';
import { DashboardLayout } from '../dashboard/DashboardLayout';
import { CartSubHeader } from './CartSubHeader';
import { EmptyCart } from './EmptyCart';
import './ShoppingCartContainer.css';

interface Props {
  children: ReactNode;
}

export function ShoppingCartContainer({ children }: Props): ReactElement {
  const { loggedIn } = useLoginContext();

  return (
    <DashboardLayout>
      <div className="cartContainer">
        <CartSubHeader />
        {!loggedIn && (
          <>
            <EmptyCart />
          </>
        )}
        {children}
      </div>
    </DashboardLayout>
  );
}

import { ReactElement } from 'react';
import { Helmet } from 'react-helmet-async';
import { HELMET_TITLE } from '../../constants/common';

interface Props {
  title?: string;
}

export function PageTitle({ title }: Props): ReactElement {
  const pageTitle = '%s | '.concat(HELMET_TITLE);

  return (
    <Helmet defaultTitle={HELMET_TITLE} titleTemplate={pageTitle}>
      <title>{title}</title>
    </Helmet>
  );
}

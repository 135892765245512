import { ReactElement } from 'react';
import './SpinnerButton.css';

interface Props {
  inverted?: boolean;
}

export function SpinnerButton({ inverted = false }: Props): ReactElement {
  return (
    <div className="spinnerContainer">
      <div className={inverted ? 'spinnerLoaderInverted' : 'spinnerLoader'}></div>
    </div>
  );
}

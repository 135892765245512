import { ReactElement } from 'react';
import { CardListSortDropdown } from '../../controls/CardListSortDropdown';
import './CatalogHero.css';

export function CatalogHero(): ReactElement {
  return (
    <div className="heroCatalogContentWrapper">
      <div className="heroCatalogContent">
        <span className="heroCatalogContentMessage">All Courses</span>
        <CardListSortDropdown />
      </div>
    </div>
  );
}

// import { TAG_LIMIT } from '../constants/common';
// import { TagData } from '../models/absorb/tags';
import { CourseChaptersData, CourseResourcesData, OnlineCourseData } from '../models/absorb/course';
import { apiRequest } from '../utils/axios';

export async function fetchOnlineCourse(courseId: string): Promise<OnlineCourseData> {
  return apiRequest('GET', `/online-courses/${courseId}`);
}

export async function fetchCourseChapters(courseId: string): Promise<CourseChaptersData[]> {
  return apiRequest('GET', `/online-courses/${courseId}/chapters`).then((data) => data.chapters);
}

export async function fetchCourseResources(courseId: string): Promise<CourseResourcesData[]> {
  return apiRequest('GET', `/online-courses/${courseId}/resources`).then((data) => data.resources);
}

// export async function fetchOnlineCourseTags(courseId: string): Promise<TagData[]> {
//   return apiRequest('GET', `/online-courses/${courseId}/tags?_limit=${TAG_LIMIT}`).then((data) => data.tags);
// }

// export async function fetchOnlineCourseUploads(courseId: string): Promise<CourseUploadsData[]> {
//   return apiRequest('GET', `/online-courses/${courseId}/uploads`).then((data) => data.courseUploads);
// }

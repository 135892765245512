import { ReactElement } from 'react';
import { useMediaQuery } from 'react-responsive';
import { ScreenBreakpoints } from '../constants/common';
import { BillingDetails } from '../components/cart/BillingDetails';
import { CartSummary } from '../components/cart/CartSummary';
import { useShoppingContext } from '../contexts/shoppingContext';
import { ShoppingCartContainer } from '../components/cart/ShoppingCartContainer';
import './CheckoutPage.css';

export function CheckoutPage(): ReactElement {
  const { goToNextStep, setBillingDetailsState } = useShoppingContext();
  const isBigScreen = useMediaQuery({ minWidth: ScreenBreakpoints.isBigScreen });
  const isDesktop = useMediaQuery({
    minWidth: ScreenBreakpoints.isDesktop,
    maxWidth: ScreenBreakpoints.isBigScreen - 0.001,
  });

  return (
    <ShoppingCartContainer>
      <div className="checkoutPageHeaderSpacer" />
      <div className="checkoutPage">
        <div className="checkoutPageBillingDetails">
          <BillingDetails
            goToNextStep={() => goToNextStep('/cart/payment')}
            storeBillingDetails={setBillingDetailsState}
          />
        </div>
        {isDesktop || isBigScreen ? (
          <div className="checkoutPageSummary">
            <CartSummary />
          </div>
        ) : (
          ''
        )}
      </div>
    </ShoppingCartContainer>
  );
}

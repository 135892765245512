import { ReactElement } from 'react';
import { CatalogCourseData } from '../../models/absorb/catalog';
import { CourseCardActionButton } from './CourseCardActionButton';
import { CourseAction } from './CourseAction';
import { LinearProgressIndicator } from '../controls/LinearProgressIndicator';
import { CardViewType, CourseDetailClickType } from '../../constants/common';
import { getRandomImage } from '../../utils/helper';
import './CourseCard.css';

interface Props {
  cardData: CatalogCourseData;
}

export function CourseCard({ cardData }: Props): ReactElement {
  return (
    <div className="courseCard">
      <CourseAction cssClass="courseActionContainer" cardData={cardData} clickType={CourseDetailClickType.Link}>
        <div className="cardImageContainer">
          <img
            src={
              cardData.imageUrl ??
              getRandomImage(cardData.id, '/assets/images/content/default/course/defaultCourseImage', 'webp', 14)
            }
            className="courseCardImage"
            alt={cardData.name}
          />
        </div>
      </CourseAction>
      <div className="cardProgressIndicator">
        <LinearProgressIndicator progress={cardData.progress} />
      </div>
      <div className="cardAction">
        <CourseAction cssClass="cardTitle" cardData={cardData} clickType={CourseDetailClickType.Link}>
          <div className="cardTitleHolder">{cardData.name}</div>
        </CourseAction>
        <div className="cardActionButtonWrapper">
          <CourseCardActionButton cardData={cardData} cardViewType={CardViewType.Vertical} isIconRequired />
        </div>
      </div>
    </div>
  );
}

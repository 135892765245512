import { ReactElement } from 'react';
import { useLocation } from 'react-router-dom';
import { useShoppingContext } from '../../contexts/shoppingContext';
import './CartSubHeader.css';

export function CartSubHeader(): ReactElement {
  const { cartData } = useShoppingContext();
  const location = useLocation();

  function getHeaderPhrase() {
    if (cartData.length === 1) {
      return `${cartData.length} Course in Cart`;
    }
    return `${cartData.length} Courses in Cart`;
  }
  return <div className="cartSubHeader">{location.pathname === '/cart' ? getHeaderPhrase() : 'Checkout'}</div>;
}

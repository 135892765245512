import { ChangeEvent, ReactElement, useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useLanguageContext } from '../../contexts/languageContext';
import { forgotPassword } from '../../services/profile';
import { errorHandler } from '../../utils/helper';
import { SpinnerButton } from '../controls/SpinnerButton';
import { MINIMUM_USERNAME_CHARS } from '../../constants/common';
import { INVALID_EMAIL, USERNAME_TOO_SHORT } from '../../constants/messages';
import { REGEX_EMAIL_VALIDATION } from '../../constants/regex';

interface Props {
  initialEmail: string;
}
export function ResetPasswordForm({ initialEmail }: Props): ReactElement {
  const { t } = useTranslation();

  const { language } = useLanguageContext();

  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [resetPasswordInProgress, setResetPasswordInProgress] = useState(false);
  const [email, setEmail] = useState(initialEmail);
  const [emailErrorMessage, setEmailErrorMessage] = useState('');
  const [isResetButtonEnabled, setIsResetButtonEnabled] = useState(false);

  const firstRender = useRef(true);

  let validateInput = true;

  if (firstRender.current) {
    firstRender.current = false;
    validateInput = false;
  }

  useEffect(() => {
    if (emailErrorMessage.length || email.length === 0) {
      setIsResetButtonEnabled(false);
    } else {
      setIsResetButtonEnabled(true);
    }
  }, [emailErrorMessage, email]);

  function onSubmit() {
    if (!validateInput || shouldSubmitButtonBeEnabled(email)) {
      const data = {
        emailAddress: email,
        languageId: language,
        username: null,
      };
      setResetPasswordInProgress(true);
      forgotPassword(data)
        .then(() => {
          setShowSuccessMessage(true);
        })
        .catch(errorHandler)
        .finally(() => {
          setResetPasswordInProgress(false);
        });
    }
  }

  function onChangeEmail(e: ChangeEvent<HTMLInputElement>) {
    setEmail(e.target.value);

    if (!REGEX_EMAIL_VALIDATION.test(e.target.value)) {
      if (e.target.value.length < MINIMUM_USERNAME_CHARS) {
        setEmailErrorMessage(USERNAME_TOO_SHORT);
      } else {
        setEmailErrorMessage(INVALID_EMAIL);
      }
    } else {
      setEmailErrorMessage('');
    }

    shouldSubmitButtonBeEnabled(e.target.value);
  }

  function shouldSubmitButtonBeEnabled(emailField: string) {
    if ((emailErrorMessage.length === 0 && emailField.length > 0) || emailField.length === 0) {
      setIsResetButtonEnabled(true);
      return true;
    }
    setIsResetButtonEnabled(false);
    return false;
  }

  return (
    <>
      <div className="loginHeader">Can&apos;t log in?</div>
      {showSuccessMessage ? (
        <>
          <div className="formText">We&apos;ve sent a recovery link to your email address:</div>
          <div className="formText">{email}</div>
          <div className="formDiv">
            <button type="button" className="buttonWideBlue" onClick={onSubmit}>
              {resetPasswordInProgress ? <SpinnerButton /> : 'Resend recovery link'}
            </button>
          </div>
          <div className="horizontalLinkListBlue">
            <Link to="/login">Return to log in</Link>
          </div>
        </>
      ) : (
        <>
          <div className="loginFormRecoveryWrapper">
            <div className="loginFormRecovery">
              <div className="formText">We&apos;ll send a recovery link to:</div>
              <div className="formDiv">
                <input
                  name="email"
                  id="reduxFormInputField1"
                  type="text"
                  step="any"
                  aria-describedby="errorDiv2"
                  value={email}
                  onChange={onChangeEmail}
                  autoComplete="username"
                  className={emailErrorMessage.length <= 0 ? 'inputTextBoxValid' : 'inputTextBoxInvalid'}
                />
              </div>
              <div aria-live="assertive" className="inputFieldInvalidText" id="emailInputError">
                {email && <span>{t(emailErrorMessage)}</span>}
              </div>
            </div>
          </div>
          <div className="formDiv">
            <button type="button" className="buttonWideBlue" disabled={!isResetButtonEnabled} onClick={onSubmit}>
              {resetPasswordInProgress ? <SpinnerButton /> : 'Send Recovery Link'}
            </button>
          </div>
          <div className="horizontalLinkListBlue">
            <Link to="/login">Return to log in</Link>
          </div>
        </>
      )}
    </>
  );
}

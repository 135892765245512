import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useModalContext } from '../contexts/modalContext';
import { Modal } from './Modal';
import './Modals.css';

interface Props {
  paymentError?: string;
}

export function PaymentSuccessModal({ paymentError }: Props): ReactElement {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { closeModal } = useModalContext();

  function onClickCatalog() {
    navigate('/catalog');
    closeModal();
  }

  function onClickCourse() {
    navigate('/my-courses');
    closeModal();
  }

  return (
    <Modal closeModal={closeModal}>
      <div className="modalWrapper" role="dialog" aria-label={t('EditProfile')} aria-modal="true">
        <div className="modalWrapper" role="dialog" aria-label="Inbox" aria-modal="true">
          <div className="modalBlocker" />
          <div className="modalContainer">
            <div className="modalPerimeter">
              <div className="modalHolder">
                <div className="modalMessagingContainer">
                  <div className={paymentError ? 'modalHeaderIconCheckmark' : 'modalHeaderIconFail'} />
                  <div className="modalHeaderText">{paymentError || `${t('Success')}`}</div>
                  <div className="modalMessage">{!paymentError ? `${t('BrowseMyCoursesBlurb')}` : ''}</div>
                  <div className="modalMessageContainer">
                    <button
                      type="button"
                      className="button-reverse-m"
                      style={{ margin: '10px', width: '170px' }}
                      title="Catalog"
                      onClick={onClickCatalog}
                    >
                      Go to the Catalog
                    </button>

                    <button
                      type="button"
                      className="button-default-m"
                      style={{ margin: '10px', width: '170px' }}
                      title={t('MyCourses')}
                      onClick={onClickCourse}
                    >
                      View My Courses
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

import { ReactElement, ChangeEvent } from 'react';
import { CountryData } from '../../models/absorb/country';
import { ProvinceData } from '../../models/absorb/province';
import './HtmlDropdown.css';

interface Props {
  labelName: string;
  name: string;
  id: string;
  selectedValue: string;
  errorId: string;
  type: string;
  countryData?: CountryData[];
  provinceData?: ProvinceData[];
  disabled?: boolean;
  required?: boolean;
  onChange: (e: ChangeEvent<HTMLSelectElement>) => void;
}

export function HtmlDropdown({
  labelName,
  name,
  id,
  selectedValue,
  errorId,
  type,
  countryData,
  provinceData,
  disabled = false,
  required = false,
  onChange,
}: Props): ReactElement {
  return (
    <div className="">
      <div className="">
        <label className="" htmlFor={id}>
          {labelName}
        </label>
        {required && <span className="requiredFieldIdentifier"> *</span>}
      </div>
      <div className="">
        <select
          className="selectInputComp"
          name={name}
          id={id}
          aria-describedby={errorId}
          onChange={onChange}
          value={selectedValue}
          disabled={disabled}
        >
          <option value="disabled" hidden>
            {type}
          </option>
          {countryData?.map((country: CountryData) => (
            <option key={country.countryCode} value={country.countryCode}>
              {country.name}
            </option>
          ))}
          {provinceData?.map((province: ProvinceData) => (
            <option key={province.name} value={province.name}>
              {province.name}
            </option>
          ))}
        </select>
        <div aria-live="assertive" id={errorId} />
        <span className="" />
      </div>
    </div>
  );
}

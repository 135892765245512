import { ReactElement } from 'react';
import { useMediaQuery } from 'react-responsive';
import { HeaderLogoBranding } from './HeaderLogoBranding';
import { HeaderPrimaryNavigation } from './HeaderPrimaryNavigation';
import { HeaderSearchInput } from './HeaderSearchInput';
import { HeaderCartIcon } from './HeaderCartIcon';
import { HeaderProfilePhoto } from './HeaderProfilePhoto';
import { HeaderProfileAccess } from './HeaderProfileAccess';
import { useLoginContext } from '../../../contexts/loginContext';
import { ScreenBreakpoints } from '../../../constants/common';

export function HeaderTablet(): ReactElement {
  const { loggedIn, termsAndConditions } = useLoginContext();
  const isTablet = useMediaQuery({
    minWidth: ScreenBreakpoints.isTablet,
    maxWidth: ScreenBreakpoints.isLargeTablet - 0.001,
  });
  const isLargeTablet = useMediaQuery({
    minWidth: ScreenBreakpoints.isLargeTablet,
    maxWidth: ScreenBreakpoints.isDesktop - 0.001,
  });

  return (
    <div>
      {loggedIn && !termsAndConditions?.currentUserMustAcceptTermsAndConditions ? (
        <div className="E1_header">
          <div className="header_left_tablet">
            <HeaderLogoBranding />
            <HeaderPrimaryNavigation />
          </div>
          <div className="header_center_tablet">
            <HeaderSearchInput />
          </div>
          <div className="header_right_tablet">
            <HeaderCartIcon />
            {isTablet || isLargeTablet ? (
              <>
                <HeaderProfileAccess />
                <HeaderProfilePhoto />
              </>
            ) : (
              <>
                <HeaderProfilePhoto />
                <HeaderProfileAccess />
              </>
            )}
          </div>
        </div>
      ) : (
        <div className="E1_header">
          <div className="header_left">
            <HeaderLogoBranding />
          </div>
          <div className="header_center" />
          <div className="header_right" />
        </div>
      )}
    </div>
  );
}

import { ReactElement } from 'react';
import { CourseResourcesData } from '../../models/absorb/course';
import { ResourceCard } from './ResourceCard';

interface Props {
  courseResourcesData: CourseResourcesData[];
  allowDownload?: boolean;
}

export function ResourceContent({ courseResourcesData, allowDownload = true }: Props): ReactElement {
  return courseResourcesData.length !== 0 ? (
    <div className="">
      {courseResourcesData &&
        courseResourcesData.map((courseResource: CourseResourcesData) => (
          <div key={courseResource.id} className="">
            <ResourceCard resourceData={courseResource} allowDownload={allowDownload} />
          </div>
        ))}
    </div>
  ) : (
    <></>
  );
}

import { ReactElement } from 'react';
import { CatalogCourseData } from '../../models/absorb/catalog';
import { SearchResultData } from '../../models/absorb/search';
import { AbsorbResourceType } from '../../constants/absorb';
import { CourseCard } from '../course/CourseCard';
import { GlobalResourceCard } from '../globalResources/GlobalResourceCard';
import { GlobalResourceData } from '../../models/absorb/resources';

interface Props {
  resource: SearchResultData;
}

export function ResourceCard({ resource }: Props): ReactElement {
  return (
    <>
      {resource.resourceType === AbsorbResourceType.Course && (
        <CourseCard key={resource.resource.id} cardData={resource.resource as CatalogCourseData} />
      )}
      {resource.resourceType === AbsorbResourceType.GlobalResource && (
        <GlobalResourceCard resource={resource.resource as GlobalResourceData} />
      )}
    </>
  );
}

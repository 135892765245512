import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { AbsorbEnrollmentStatus } from '../../constants/absorb';
import { LessonsData } from '../../models/absorb/lessons';
import { getEnrollmentStatus } from '../../utils/helper';
import './LessonCardActionButton.css';

interface Props {
  lessonData: LessonsData;
  openPlayer: (id: string) => void;
}

export function LessonCardActionButton({ lessonData, openPlayer }: Props): ReactElement {
  const { t } = useTranslation();

  function getButtonText() {
    return t(getEnrollmentStatus(lessonData.enrollment.status));
  }

  function getButtonColor() {
    let color = 'lessonCardActionButtonStandard ';

    if (lessonData.enrollment?.status === AbsorbEnrollmentStatus.Complete) {
      color += 'lessonCardActionButtonComplete ';
    }

    if (!isButtonEnabled()) {
      color += 'lessonCardActionButtonDisabled ';
    }

    return color;
  }

  function isButtonEnabled() {
    const { enrollment } = lessonData;

    return (
      enrollment?.isAllowedRetake &&
      (enrollment?.maxAttempts === null ||
        (enrollment?.attempts !== undefined &&
          enrollment?.maxAttempts !== undefined &&
          enrollment.attempts < enrollment.maxAttempts))
    );
  }

  return (
    <button
      type="button"
      aria-disabled="false"
      aria-label={lessonData.name}
      disabled={!isButtonEnabled()}
      onClick={() => openPlayer(lessonData.id)}
      className={getButtonColor()}
    >
      <div className="LessonCardActionButtonText">{getButtonText()}</div>
    </button>
  );
}

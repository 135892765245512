import axios, { Method } from 'axios';
import { LEARNER_TOKEN_IS_NO_LONGER_VALID, LocalStorageItem } from '../constants/common';
import { isLearnerTokenValid } from './helper';

const API_BASE_URL = process.env.REACT_APP_BASE_URL_API;

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
export async function publicApiRequest(method: Method, url: string, data?: any): Promise<any> {
  return new Promise((resolve, reject) => {
    const request = axios({
      url: API_BASE_URL + url,
      method,
      data,
    });

    request.then((response) => resolve(response.data)).catch(reject);
  });
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
export async function apiRequest(method: Method, url: string, data?: any): Promise<any> {
  return new Promise((resolve, reject) => {
    const token = localStorage.getItem(LocalStorageItem.Token);

    if (token && isLearnerTokenValid()) {
      const request = axios({
        url: API_BASE_URL + url,
        method,
        data,
        headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' },
      });

      request.then((response) => resolve(response.data)).catch(reject);
    } else {
      reject(LEARNER_TOKEN_IS_NO_LONGER_VALID);
    }
  });
}

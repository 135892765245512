import { ReactElement, useState, useEffect, ChangeEvent } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { DashboardLayout } from '../components/dashboard/DashboardLayout';
import { PageTitle } from '../components/global/PageTitle';
import { ResetPasswordData } from '../models/absorb/password';
import { Spinner } from '../components/controls/Spinner';
import { SpinnerButton } from '../components/controls/SpinnerButton';
import { resetPassword, checkResetPasswordTokenValidity } from '../services/profile';
import { doPasswordsMatchByLength, errorHandler } from '../utils/helper';
import { REGEX_PASSWORD_VALIDATION } from '../constants/regex';
import { HtmlTextBox } from '../components/controls/HtmlTextBox';
import './ResetPasswordPage.css';

export function ResetPasswordPage(): ReactElement {
  const { urlToken: urlTokenParam } = useParams();
  const urlToken = urlTokenParam || '';
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(true);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [showErrorPopup, setShowErrorPopup] = useState(false);
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [passwordErrorLatch, setPasswordErrorLatch] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState('');
  const [confirmPasswordErrorLatch, setConfirmPasswordErrorLatch] = useState(false);
  const [resetPasswordInProgress, setResetPasswordInProgress] = useState(false);
  const [validForm, setValidForm] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    checkResetPasswordTokenValidity(urlToken)
      .then(() => {})
      .catch((err) => {
        // setShowErrorPopup(true);
        errorHandler(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [urlToken]);

  useEffect(() => {
    if (passwordErrorLatch) {
      validatePassword();
    }

    if (confirmPasswordErrorLatch) {
      validateConfirmPassword();
    }

    setValidForm(REGEX_PASSWORD_VALIDATION.test(password) && password === confirmPassword);
  }, [password, confirmPassword]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setPasswordErrorLatch(passwordErrorLatch || passwordError !== '');
  }, [passwordError]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setConfirmPasswordErrorLatch(confirmPasswordErrorLatch || confirmPasswordError !== '');
  }, [confirmPasswordError]); // eslint-disable-line react-hooks/exhaustive-deps

  function validatePassword() {
    setPasswordError(REGEX_PASSWORD_VALIDATION.test(password) ? '' : 'MinimumPassword');
  }

  function validateConfirmPassword() {
    updateConfirmPasswordError(doPasswordsMatchByLength(password, confirmPassword));
  }

  function onChangeConfirmPassword(e: ChangeEvent<HTMLInputElement>) {
    const tempConfirmPassword = e.target.value;
    if (!confirmPasswordErrorLatch) {
      updateConfirmPasswordError(doPasswordsMatchByLength(password, tempConfirmPassword));
    }
    setConfirmPassword(tempConfirmPassword);
  }

  function updateConfirmPasswordError(identical: boolean) {
    setConfirmPasswordError(identical ? '' : 'PasswordsNotMatch');
  }

  function onSubmit() {
    const data: ResetPasswordData = {
      NewPassword: password,
    };

    setResetPasswordInProgress(true);
    resetPassword(urlToken, data)
      .then(() => {
        setShowSuccessPopup(true);
      })
      .catch((err) => {
        setShowErrorPopup(true);
        errorHandler(err);
      })
      .finally(() => {
        setResetPasswordInProgress(false);
      });
  }

  return (
    <DashboardLayout>
      <PageTitle title={t('ResetPassword')} />
      <div className="ResetPasswordWrapper">
        {!isLoading && !showSuccessPopup && !showErrorPopup && (
          <div>
            <h1 className="sectionTitle">{t('ResetPassword')}</h1>
            <div className="largeParagraphBreak">{`${t('ResetPasswordDescription')} ${t('MediumPasswordHint')}`}</div>
            <form>
              <div className="resetPasswordFields">
                <HtmlTextBox
                  labelName={t('NewPassword')}
                  name="password"
                  type="password"
                  value={password}
                  id="newPassword"
                  autoComplete="new-password"
                  errorId="errorDiv2"
                  required
                  halfSize
                  onChange={(e) => setPassword(e.target.value)}
                  onBlur={validatePassword}
                />
                <div className="resetPasswordInputError" id="errorDiv2">
                  <span className="resetPasswordInputErrorText">{t(passwordError)}</span>
                </div>
              </div>
              <div className="resetPasswordChallenge">
                <HtmlTextBox
                  labelName={t('ConfirmPassword')}
                  name="confirmPassword"
                  type="password"
                  value={confirmPassword}
                  id="confirmPassword"
                  autoComplete="new-password"
                  errorId="errorDiv4"
                  required
                  halfSize
                  onChange={onChangeConfirmPassword}
                />
                <div className="resetPasswordInputChallengeError" id="errorDiv4">
                  <span className="resetPasswordInputErrorText">{t(confirmPasswordError)}</span>
                </div>
              </div>
              <div className="resetPasswordActionButtonsChange">
                <button type="button" className="button-default-l" disabled={!validForm} onClick={onSubmit}>
                  {resetPasswordInProgress ? <SpinnerButton /> : t('ResetPassword')}
                </button>
              </div>
            </form>
            <div aria-live="assertive" />
            <div className="resetPasswordSubmit">
              <Link className="" to="/">
                {t('BackToLogin')}
              </Link>
            </div>
          </div>
        )}
        {showSuccessPopup && (
          <div className="">
            <div className="">
              <div aria-live="assertive">
                <div className="resetPasswordFeedbackText">{t('ResetPasswordSuccessBlurb')}</div>
              </div>
              <div className="">
                <Link className="" to="/">
                  {t('BackToLogin')}
                </Link>
              </div>
            </div>
          </div>
        )}
        {showErrorPopup && (
          <div className="">
            <div className="">
              <div aria-live="assertive">
                <div className="">
                  <p className="resetPasswordFeedbackText">{t('ResetPasswordTokenExpired')}</p>
                </div>
              </div>
              <div className="">
                <Link className="" to="/">
                  {t('BackToLogin')}
                </Link>
              </div>
            </div>
          </div>
        )}
        {isLoading && <Spinner />}
      </div>
    </DashboardLayout>
  );
}

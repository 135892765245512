import { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { Popup } from 'reactjs-popup';

export function HeaderPrimaryNavigation(): ReactElement {
  return (
    <div id="headerPrimaryNavigation">
      <Link className="headerPrimaryNavigationMenuItem" to="/dashboard">
        Dashboard
      </Link>
      <Popup
        on={['hover', 'focus', 'click', 'right-click']}
        position="bottom left"
        className="hostControl"
        arrowStyle={{ color: 'transparent' }}
        offsetX={0}
        offsetY={20}
        trigger={
          <div className="headerContentDropdownTrigger">
            Browse
            <i className="downArrow" />
          </div>
        }
      >
        <div className="headerContentDropdown">
          <Link className="headerContentDropdownItemWrapper" title="My Courses" to="/my-courses">
            <div className="headerContentDropdownItem">
              <div className="headerContentDropdownItemLinkText">My Courses</div>
            </div>
          </Link>
          <Link className="headerContentDropdownItemWrapper" title="All Courses" to="/catalog">
            <div className="headerContentDropdownItem">
              <div className="headerContentDropdownItemLinkText">All Courses</div>
            </div>
          </Link>
          <Link className="headerContentDropdownItemWrapper" title="Resources" to="/resources">
            <div className="headerContentDropdownItem">
              <div className="headerContentDropdownItemLinkText">Resources</div>
            </div>
          </Link>
        </div>
      </Popup>
    </div>
  );
}

import { ReactElement } from 'react';
import { CourseChaptersData } from '../../models/absorb/course';
import { ChapterView } from '../lesson/ChapterView';
import './OnlineCourseContent.css';

interface Props {
  courseDetails?: CourseChaptersData[];
  openPlayer: (id: string) => void;
}

export function OnlineCourseContent({ courseDetails, openPlayer }: Props): ReactElement {
  return (
    <div className="courseDetailWrapper">
      {courseDetails &&
        courseDetails.map((courseChapter: CourseChaptersData) => (
          <ChapterView key={courseChapter.id} courseChapter={courseChapter} openPlayer={openPlayer} />
        ))}
    </div>
  );
}

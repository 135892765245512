import { ReactElement, useRef, useState } from 'react';
import { Overlay } from 'react-bootstrap';
import ReactPlayer from 'react-player';
import { VIDEO_LESSON_PROGRESS_UPDATE_FREQUENCY } from '../../constants/common';
import { MyLessonData } from '../../models/absorb/lessons';
import { postLessonAttempt, updateLessonActivity } from '../../services/lesson';
import { errorHandler } from '../../utils/helper';
import { NextLessonOverlay } from './NextLessonOverlay';
import './VideoLesson.css';

interface Props {
  lessonData: MyLessonData;
  nextLessonData?: MyLessonData;
  openLessonPlayer: (id: string) => void;
  closeLessonPlayer: () => void;
}

export function VideoLesson({ lessonData, nextLessonData, openLessonPlayer, closeLessonPlayer }: Props): ReactElement {
  const [showNextActivity, setShowNextActivity] = useState(false);

  const target = useRef();

  let isPlaying = false;
  let attemptId = '';

  function playerOnStart() {
    isPlaying = true;
    postLessonAttempt(lessonData.id)
      .then((data) => {
        attemptId = data.attemptId;
      })
      .catch(errorHandler);
  }

  function playerOnEnded() {
    isPlaying = false;
    if (attemptId.length > 0) {
      updateLessonActivity(lessonData.id, attemptId, { verb: 'Finish', status: 'Complete' })
        .then(() => {
          attemptId = '';
          setShowNextActivity(true);
        })
        .catch(errorHandler);
    }
  }

  function playerOnProgress(playedSeconds: number) {
    if (isPlaying && attemptId.length > 0) {
      updateLessonActivity(lessonData.id, attemptId, {
        verb: 'RecordVideoTimeSpent',
        time: Math.floor(playedSeconds * 1000),
      }).catch(errorHandler);
    }
  }

  return (
    <div className="reactPlayerHolder">
      <ReactPlayer
        className="reactPlayer"
        ref={target.current}
        key={lessonData.sources[0].url}
        url={lessonData.sources[0].url}
        width="100%"
        height="100%"
        onStart={playerOnStart}
        onEnded={playerOnEnded}
        progressInterval={VIDEO_LESSON_PROGRESS_UPDATE_FREQUENCY}
        onProgress={(data) => playerOnProgress(data.playedSeconds)}
        controls
        config={{
          file: {
            attributes: {
              disablePictureInPicture: 'true',
              controlslist: 'nodownload nofullscreen noremoteplayback',
            },
          },
        }}
      />
      <Overlay target={target?.current ?? null} show={showNextActivity}>
        <NextLessonOverlay
          thisLesson={lessonData}
          nextLesson={nextLessonData}
          hideOverlay={() => setShowNextActivity(false)}
          openLessonPlayer={openLessonPlayer}
          closeLessonPlayer={closeLessonPlayer}
        />
      </Overlay>
    </div>
  );
}

import { ReactElement, ChangeEvent } from 'react';
import './HtmlTextBox.css';

interface Props {
  labelName: string;
  name: string;
  id: string;
  errorId: string;
  value?: string;
  required?: boolean;
  halfSize?: boolean;
  autoComplete?: string;
  disabled?: boolean;
  type?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: ChangeEvent<HTMLInputElement>) => void;
}

export function HtmlTextBox({
  labelName,
  name,
  id,
  errorId,
  value,
  required = false,
  halfSize = false,
  autoComplete,
  disabled = false,
  type = 'text',
  onChange,
  onBlur,
}: Props): ReactElement {
  return (
    <div className="">
      <div className="">
        <label className="" htmlFor={id}>
          {labelName}
        </label>
        {required && <span className="requiredFieldIdentifier"> *</span>}
      </div>
      <input
        className={halfSize ? 'textInputCompHalf' : 'textInputComp'}
        name={name}
        id={id}
        type={type}
        step="any"
        aria-required="true"
        aria-describedby={errorId}
        value={value}
        autoComplete={autoComplete}
        onChange={onChange}
        onBlur={onBlur}
        disabled={disabled}
      />
      <div aria-live="assertive" className="" id={errorId} />
    </div>
  );
}

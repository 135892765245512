import { ReactElement, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ShoppingCartContainer } from '../components/cart/ShoppingCartContainer';
import { EmptyCart } from '../components/cart/EmptyCart';
import { CatalogCourseData } from '../models/absorb/catalog';
import { useShoppingContext } from '../contexts/shoppingContext';
import { CENTS_PER_DOLLAR, SALES_TAX_RATE_PERCENT } from '../constants/shoppingCart';
import { CHECKOUT_ALLOWED } from '../constants/common';
import { formatToDollar, formatToDollaNoCurrencyCode } from '../utils/currencyCodes';
import { constructCourseTypePath, getRandomImage } from '../utils/helper';
import { PageTitle } from '../components/global/PageTitle';
import cartRemoveIconBordered from '../assets/images/icons/cart_remove_item.svg';
import './CartPage.css';

export function CartPage(): ReactElement {
  const { t } = useTranslation();
  const { cartData, goToNextStep, getCartSubTotal, getCartTax, getCartTotal, removeFromCart } = useShoppingContext();

  const [cartListData, setCartListData] = useState<CatalogCourseData[]>([]);
  const [cartListDataAvailable, setCartListDataAvailable] = useState(false);

  useEffect(() => {
    if (cartData.length < 1) {
      setCartListDataAvailable(false);
    } else {
      setCartListData(cartData);
      setCartListDataAvailable(true);
    }
  }, [cartData, cartData.length]);

  function removeItem(index: number) {
    const listData: CatalogCourseData[] = [...cartListData];
    removeFromCart(listData, index);
  }

  return (
    <ShoppingCartContainer>
      <PageTitle title={t('ShoppingCart')} />
      {!cartListDataAvailable && <EmptyCart />}
      {cartListDataAvailable && (
        <div className="cartPage">
          <div className="cartContentCards">
            {cartListData.map((cartList: CatalogCourseData, index: number) => (
              <div key={cartList.id} className="cartContentCard">
                <div className="cartListImage">
                  <img
                    src={
                      cartList.imageUrl ||
                      getRandomImage(
                        cartList.id,
                        '/assets/images/content/default/course/defaultCourseImage',
                        'webp',
                        14
                      )
                    }
                    className="courseCardImage"
                    alt={cartList.name}
                  />
                </div>

                <div className="cartListButton">
                  <button
                    type="button"
                    className="cardListRemove"
                    title={`${t('Remove')}`}
                    onClick={() => removeItem(index)}
                  >
                    <img alt="remove cart item" height="24" width="24" src={cartRemoveIconBordered} />
                  </button>

                  <div className="cartListName">
                    <Link className="" to={constructCourseTypePath(cartList.courseType, cartList.id)}>
                      {cartList.name}
                    </Link>
                  </div>

                  <div className="cartListPrice">{formatToDollaNoCurrencyCode(cartList.price * CENTS_PER_DOLLAR)}</div>
                </div>
              </div>
            ))}
          </div>
          <div className="cartSummary">
            <div className="cartSummary">
              <div className="cartSummaryInformation">
                <div className="cartSummaryTotalLabel">Total:</div>
                <div className="cartSummaryTotalValue">{formatToDollar(getCartTotal())}</div>
                <div className="cartSummaryClear" />

                <div className="cartSummaryTaxLabel">Tax {`(${SALES_TAX_RATE_PERCENT}%)`}:</div>
                <div className="cartSummaryTaxValue">{formatToDollar(getCartTax())}</div>
                <div className="cartSummaryClear" />

                <div className="cartSummarySubtotalLabel">Subtotal:</div>
                <div className="cartSummarySubtotalValue">{formatToDollar(getCartSubTotal())}</div>
                <div className="cartSummaryClear" />

                <button
                  type="button"
                  title={t('ContinueToCheckout')}
                  className={CHECKOUT_ALLOWED ? 'cartSummaryCheckoutButton' : 'cartSummaryCheckoutButtonDisabled'}
                  onClick={() => goToNextStep('/cart/checkout')}
                  disabled={!CHECKOUT_ALLOWED}
                >
                  Checkout
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </ShoppingCartContainer>
  );
}

import { ReactElement } from 'react';
import './LinearProgressIndicator.css';

interface Props {
  progress: number;
}

export function LinearProgressIndicator({ progress = 0 }: Props): ReactElement {
  const percentageCompleted = Math.floor(progress);
  return (
    <div className="progressBarWrapper">
      <div className="progressBar" style={{ width: `${percentageCompleted}%` }} />
    </div>
  );
}

import { ReactElement, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { Box, List, ListItem, ListItemText, Divider, IconButton } from '@mui/material';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import { makeStyles } from '@mui/styles';
import { Button } from 'react-bootstrap';
import { useLoginContext } from '../../../contexts/loginContext';
import headerMenuMobile from '../../../assets/images/global/header/headerMenuMobile.svg';
import headerMenuMobileCart from '../../../assets/images/global/header/headerCartUnpopulated.svg';
import headerMenuMobileClose from '../../../assets/images/global/header/headerMenuMobileClose.svg';

const useStyles = makeStyles({
  list: {
    width: 280,
    backgroundColor: '#1D2332',
    color: '#fff',
    marginTop: '21px',
    marginLeft: '32px',
  },
});

export function HeaderMobileMenu(): ReactElement {
  const { signOut } = useLoginContext();
  const classes = useStyles();
  const navigate = useNavigate();
  const [open, setOpen] = useState<boolean>(false);

  function handleClick(): void {
    setOpen(!open);
  }

  return (
    <div className="headerMobileMenuWrapper">
      <div>
        <IconButton edge="start" color="inherit" aria-label="open drawer" onClick={() => setOpen(true)}>
          <img alt="menu" src={headerMenuMobile} />
        </IconButton>
        <SwipeableDrawer anchor="right" open={open} onClose={() => setOpen(false)} onOpen={() => {}}>
          <div className={classes.list}>
            <Button className="headerMenuMobileCloseButton" onClick={handleClick}>
              <img alt="close menu" src={headerMenuMobileClose} />
            </Button>
            <Box textAlign="right" p={2}>
              <Link className="headerMenuMobileMenuIconOpen" to="/cart">
                <img alt="cart" src={headerMenuMobileCart} />
              </Link>
            </Box>
            <Divider />
            <List>
              <ListItem button className="headerMobileMenuPrimaryLink" onClick={() => navigate('/dashboard')}>
                <ListItemText primary="Dashboard" />
              </ListItem>
              <ListItem button className="headerMobileMenuPrimaryLink" onClick={() => navigate('/my-courses')}>
                <ListItemText primary="My Courses" />
              </ListItem>
              <ListItem button className="headerMobileMenuPrimaryLink" onClick={() => navigate('/catalog')}>
                <ListItemText primary="All Courses" />
              </ListItem>
              <div className="headerMobileMenuDivider" />
              <ListItem button className="headerMobileMenuSecondaryLink" onClick={() => navigate('/cart')}>
                <ListItemText primary="View cart" />
              </ListItem>
              <ListItem button className="headerMobileMenuSecondaryLink" onClick={() => navigate('/profile')}>
                <ListItemText primary="Edit Profile" />
              </ListItem>
              <ListItem button className="headerMobileMenuSecondaryLink" onClick={() => navigate('/notifications')}>
                <ListItemText primary="Notifications" />
              </ListItem>
              <ListItem button className="headerMobileMenuSecondaryLink" onClick={() => navigate('/transcript')}>
                <ListItemText primary="Transcript" />
              </ListItem>
              <ListItem button className="headerMobileMenuSecondaryLink" onClick={signOut}>
                <ListItemText primary="Sign out" />
              </ListItem>
            </List>
          </div>
        </SwipeableDrawer>
      </div>
    </div>
  );
}

// export enum AbsorbCourseRating {
//   One = 1,
//   Two = 2,
//   Three = 3,
//   Four = 4,
//   MaximumStars = 5,
//   DefaultValue = 0,
//   HalfStar = 0.5,
// }

// export enum DisplayContentView {
//   Cards = 'cards',
//   List = 'list',
//   Detailed = 'detailed',
// }

export enum TabView {
  Course = 'course',
  Resource = 'resource',
}

export enum SortCoursesBy {
  Name = 'name',
  Ispinned = '-ispinned',
  Relevance = '-relevance',
  EnrolledDate = '-enrolledDate',
}

// export const RESPONSIVE_COURSE_RIBBON = {
//   superLargeDesktop: {
//     breakpoint: { max: 4000, min: 3000 },
//     items: 6,
//   },
//   largeDesktop: {
//     breakpoint: { max: 3000, min: 1400 },
//     items: 6,
//   },
//   desktop: {
//     breakpoint: { max: 1400, min: 1180 },
//     items: 5,
//   },
//   smallWindow: {
//     breakpoint: { max: 1180, min: 980 },
//     items: 4,
//   },
//   tablet: {
//     breakpoint: { max: 980, min: 740 },
//     items: 3,
//   },
//   largeMobile: {
//     breakpoint: { max: 740, min: 400 },
//     items: 2,
//   },
//   mobile: {
//     breakpoint: { max: 400, min: 0 },
//     items: 1,
//   },
// };

import { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './ShoppingCartPreviousStepButton.css';

interface Props {
  goBack?: boolean;
  linkText?: string;
  goToPrevStep?: () => void;
}

export function ShoppingCartPreviousStepButton({
  goBack = false,
  linkText = 'Back',
  goToPrevStep,
}: Props): ReactElement {
  const { t } = useTranslation();

  return (
    <div className="returnToPreviousStepWrapper">
      {goBack && goToPrevStep ? (
        <span className="returnToPreviousStep" onClick={goToPrevStep}>
          {linkText}
        </span>
      ) : (
        <Link className="" to="/catalog">
          {t('ContinueShopping')}
        </Link>
      )}
    </div>
  );
}

import { ReactElement, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useIdleTimer } from 'react-idle-timer';
import QueryString from 'query-string';
import md5 from 'md5';
import {
  LocalStorageItem,
  USER_ACTIVITY_TIMEOUT_MS,
  TERMS_AND_CONDITIONS_ONLY_SUPPORTED_LANGUAGE as TERMS_AND_CONDITIONS_ONLY_SUPPORTED_LANGUAGE_IS_ENGLISH,
} from '../constants/common';
import { LoginData } from '../models/absorb/login';
import {
  acceptSiteTermsAndConditions,
  fetchUserProfile,
  fetchTermsAndConditions,
  userLogin,
  userLogout,
} from '../services/profile';
import { errorHandler, isLearnerTokenValid, updateUserScopeInLocalStorage } from '../utils/helper';
import { ContextProps, createContext } from '../utils/contextHelper';
import { TermsAndConditionsData } from '../models/absorb/termsAndConditions';

interface LoginContextState {
  loggedIn: boolean;
  scope?: string[];
  error?: string;
  tokenTimedOut: boolean;
  signInInProgress?: boolean;
  signOutInProgress?: boolean;
  acceptTermsAndConditionsInProgress: boolean;
  termsAndConditions?: TermsAndConditionsData;
  signIn: (data: LoginData) => void;
  signOut: () => void;
  checkLearnerToken: () => void;
  retrieveTermsAndConditions: () => void;
  acceptTermsAndConditions: () => void;
}

const [Provider, useLoginContext] = createContext<LoginContextState>(module.filename);

export { useLoginContext };

export function LoginProvider({ children }: ContextProps): ReactElement {
  const navigate = useNavigate();
  const location = useLocation();

  const [loggedIn, setLoggedIn] = useState(true);
  const [scope, setScope] = useState<string[]>([]);
  const [error, setError] = useState('');
  const [tokenTimedOut, setTokenTimedOut] = useState(false);
  const [signInInProgress, setSignInInProgress] = useState(false);
  const [signOutInProgress, setSignOutInProgress] = useState(false);
  const [acceptTermsAndConditionsInProgress, setAcceptTermsAndConditionsInProgress] = useState(false);
  const [termsAndConditions, setTermsAndConditions] = useState<TermsAndConditionsData>();

  useEffect(() => {
    if (isLearnerTokenValid()) {
      setLoggedIn(true);
    }
  }, []);

  useEffect(() => {
    setLoggedIn(isLearnerTokenValid());
    setTokenTimedOut(false);
  }, [termsAndConditions]);

  useEffect(() => {
    setSignInInProgress(false);
  }, [loggedIn]);

  useIdleTimer({
    timeout: USER_ACTIVITY_TIMEOUT_MS,
    onIdle: userActivityTimeout,
  });

  function userActivityTimeout() {
    if (loggedIn) {
      setTokenTimedOut(true);
      signOut();
    }
  }

  function checkLearnerToken() {
    if (loggedIn && !isLearnerTokenValid()) {
      setTokenTimedOut(true);
      signOut();
    }
  }

  function signIn(loginData: LoginData) {
    setSignInInProgress(true);
    userLogin(loginData)
      .then((user) => {
        if (user.redirectUrl) {
          navigate(user.redirectUrl);
        } else {
          localStorage.setItem(LocalStorageItem.Token, user.token);
          fetchUserProfile()
            .then(async (userProfileData) => {
              const idFromProfileData = md5(userProfileData.username);
              localStorage.setItem(LocalStorageItem.UserId, idFromProfileData);
              await retrieveTermsAndConditions();
              updateUserScopeInLocalStorage(idFromProfileData, user.scope);
              setScope(user.scope);
              setError('');
              const queryParams = QueryString.parse(location.search);
              navigate(queryParams.redirectTo?.toString() || '/dashboard');
            })
            .catch((e) => {
              errorHandler(e);
            })
            .finally(() => {
              setSignInInProgress(false);
            });
        }
      })
      .catch((e) => {
        errorHandler(e);
        setError(e?.response?.data);
      })
      .finally(() => {
        setSignInInProgress(false);
      });
  }

  function signOut() {
    if (isLearnerTokenValid()) {
      setSignOutInProgress(true);
      userLogout()
        .then(() => cleanupOnSignOut())
        .catch((e) => {
          errorHandler(e);
          setError(e?.response?.data);
        })
        .finally(() => {
          setSignOutInProgress(false);
        });
    } else {
      cleanupOnSignOut();
    }
  }

  function cleanupOnSignOut() {
    updateUserScopeInLocalStorage(localStorage.getItem(LocalStorageItem.UserId), []);

    localStorage.removeItem(LocalStorageItem.UserId);
    localStorage.removeItem(LocalStorageItem.Token);

    location.pathname = '/';
    setLoggedIn(false);
    setTokenTimedOut(false);
    setError('');
  }

  async function retrieveTermsAndConditions() {
    const terms = await fetchTermsAndConditions(TERMS_AND_CONDITIONS_ONLY_SUPPORTED_LANGUAGE_IS_ENGLISH);
    setTermsAndConditions(terms);
    return terms;
  }

  function acceptTermsAndConditions() {
    setAcceptTermsAndConditionsInProgress(true);
    acceptSiteTermsAndConditions({ Accepted: true })
      .then(() => retrieveTermsAndConditions())
      .then((terms) => {
        if (terms && !terms.currentUserMustAcceptTermsAndConditions) {
          window.location.href = '/dashboard';
        }
      })
      .catch(errorHandler)
      .finally(() => {
        setAcceptTermsAndConditionsInProgress(false);
      });
  }

  return (
    <Provider
      value={{
        loggedIn,
        scope,
        error,
        tokenTimedOut,
        signInInProgress,
        signOutInProgress,
        acceptTermsAndConditionsInProgress,
        termsAndConditions,
        signIn,
        signOut,
        checkLearnerToken,
        retrieveTermsAndConditions,
        acceptTermsAndConditions,
      }}
    >
      {children}
    </Provider>
  );
}

import { ReactElement, MouseEvent, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Avatar } from '@mui/material';
import { grey } from '@mui/material/colors';
import { constructCourseTypePath, convertToTimeAgoDate, parsedHtml } from '../../utils/helper';
import { ActivityData } from '../../models/absorb/activity';
import { CatalogCourseData } from '../../models/absorb/catalog';
import { fetchMyCatalogCourse } from '../../services/catalog';
import notificationIcon from '../../assets/images/icons/logoBlueOnBlackNoText.webp';
import './MessageItem.css';

interface Props {
  activity: ActivityData;
}

export function ActivityItem({ activity }: Props): ReactElement {
  const navigate = useNavigate();

  const [courseData, setCourseData] = useState<CatalogCourseData>();

  useEffect(() => {
    fetchMyCatalogCourse(activity.object.id).then(setCourseData);
  }, [activity]);

  function onClickActivity(e: MouseEvent<HTMLElement>, activityCourseDetails?: CatalogCourseData) {
    e.stopPropagation();
    if (activityCourseDetails) {
      navigate(constructCourseTypePath(activityCourseDetails.courseType, activityCourseDetails.id));
    }
  }

  return (
    <div className="messageItemContainer">
      <button
        className="messageItemButton"
        style={{ cursor: 'pointer' }}
        onClick={(e) => onClickActivity(e, courseData)}
      >
        <div className="messageItemWrapper">
          <div className="activityItemIcon">
            <Avatar sx={{ bgcolor: grey[50], width: 44, height: 44, borderRadius: 1 }} variant="rounded">
              <img
                src={courseData?.imageUrl || notificationIcon}
                className="messageItemImage"
                alt={activity.object.name}
              />
            </Avatar>
          </div>
          <div className="messageItemTitle" title={activity.object.name}>
            {parsedHtml(activity.object.name)}
          </div>
          <div className="messageItemDescription" title={activity.summary}>
            {parsedHtml(activity.summary)}
            <br />
            <br />
          </div>
          <div className="messageItemFreshness" title={new Date(activity.published).toDateString()}>
            {convertToTimeAgoDate(activity.published)}
          </div>
        </div>
      </button>
    </div>
  );
}

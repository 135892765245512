import { ReactElement, useState, useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { errorHandler, parsedHtml, getRandomImage } from '../utils/helper';
import { CurriculumDetailsData, CurriculumData } from '../models/absorb/curriculum';
import { fetchCurriculumDetails, fetchCurriculum, fetchCurriculumTags } from '../services/curriculum';
import { TagData } from '../models/absorb/tags';
import { DashboardLayout } from '../components/dashboard/DashboardLayout';
import { CurriculumContent } from '../components/curriculum/CurriculumContent';
import { TagList } from '../components/global/TagList';
import { ShareThis } from '../components/global/ShareThis';
import { CurriculumPageActionButton } from '../components/curriculum/CurriculumPageActionButton';
import { fetchMyCatalogCourse } from '../services/catalog';
import { CatalogCourseData } from '../models/absorb/catalog';
import { useCourseAccessErrorRedirect } from '../hooks/useCourseAccessErrorRedirect';
import { enrollInCourse, fetchMyCourseEnrollment } from '../services/course';
import './CurriculumPage.css';

export function CurriculumPage(): ReactElement {
  const location = useLocation();
  const { courseId: courseIdParam } = useParams<{ courseId: string }>();
  const courseId = courseIdParam || '';

  const [curriculumDetails, setCurriculumDetails] = useState<CurriculumDetailsData>();
  const [catalogCourse, setCatalogCourse] = useState<CatalogCourseData>();
  const [curriculum, setCurriculum] = useState<CurriculumData>();
  const [courseEnrollmentStatus, setCourseEnrollmentStatus] = useState('');
  const [curriculumTags, setCurriculumTags] = useState<TagData[]>([]);
  const [loadingInProgress, setLoadingInProgress] = useState(true);
  const [setCourseAccessError] = useCourseAccessErrorRedirect();
  const [enrollmentStatusHasChanged, setEnrollmentStatusHasChanged] = useState(false);

  useEffect(() => {
    setLoadingInProgress(true);

    fetchCurriculumDetails(courseId)
      .then((curriculumDetailsData) => {
        setCurriculumDetails(curriculumDetailsData);

        Promise.all([
          fetchMyCatalogCourse(courseId).then(setCatalogCourse),
          fetchCurriculum(courseId).then(setCurriculum),
          fetchCurriculumTags(courseId).then(setCurriculumTags),
        ])
          .catch((err) => {
            errorHandler(err);
            setCourseAccessError(true);
          })
          .finally(() => {
            setLoadingInProgress(false);
            setEnrollmentStatusHasChanged(false);
          });
      })
      .catch((err) => {
        errorHandler(err);
        setCourseAccessError(true);
      })
      .finally(() => {
        setLoadingInProgress(false);
        setEnrollmentStatusHasChanged(false);
      })
      .then(() => fetchMyCourseEnrollment(courseId).then((data) => setCourseEnrollmentStatus(data.enrollmentStatus)));
  }, [courseId, enrollmentStatusHasChanged]); // eslint-disable-line react-hooks/exhaustive-deps

  function enrollUserInCurriculum() {
    setLoadingInProgress(true);
    enrollInCourse({ courseId })
      .catch(errorHandler)
      .finally(() => setEnrollmentStatusHasChanged(true));
  }

  return (
    <DashboardLayout>
      <div className="curriculumPageWrapper">
        {catalogCourse && curriculum && (
          <CurriculumPageActionButton
            course={catalogCourse}
            courseEnrollmentStatus={courseEnrollmentStatus}
            isLoadingData={loadingInProgress}
            curriculum={curriculum}
            enrollUserInCurriculum={enrollUserInCurriculum}
          />
        )}
        <ShareThis url={window.location.href} />
        <div className="curriculumPageImagePrimary">
          <img
            src={
              curriculumDetails?.imageUri ||
              getRandomImage(
                curriculumDetails?.id || '',
                '/assets/images/content/default/course/defaultCourseImage',
                'webp',
                14
              )
            }
            className="imageCourse"
            alt={curriculumDetails?.name || ''}
          />
        </div>
        <div className="curriculumPageTitleWrapper">
          {
            curriculumDetails ? (
              <div className="curriculumPageTitle">{curriculumDetails.name}</div>
            ) : null /* TODO: curriculum could be null because it's invalid. */
          }
        </div>
        {(curriculumTags?.length > 0 || curriculumDetails?.description) && (
          <div className="curriculumPageIntroWrapper">
            <div className="curriculumPageTags">
              <TagList tags={curriculumTags} />
            </div>
            <div className="curriculumPageDescription">{parsedHtml(curriculumDetails?.description || '')}</div>
          </div>
        )}
        <div className="curriculumPageContent">
          {curriculum && (
            <CurriculumContent curriculumId={location.pathname.split('/')[2] || ''} curriculum={curriculum} />
          )}
        </div>
      </div>
    </DashboardLayout>
  );
}

import { apiRequest } from '../utils/axios';
import { CatalogCourseData, CatalogCoursesData, CategoriesData } from '../models/absorb/catalog';
import { COURSES_PER_PAGE } from '../constants/common';
import { CourseEnrollmentData } from '../models/absorb/course';
import { formatUrl } from '../utils/helper';
import { SearchFilterParams } from '../models/absorb/search';
import { TranscriptResponseData } from '../models/absorb/transcripts';

export async function fetchMyCourses(params: SearchFilterParams, offset = 0): Promise<CatalogCoursesData> {
  const parameters = params;
  if (parameters.sort) {
    delete parameters.sort;
  }
  const url = formatUrl(`/my-courses?_offset=${offset}`, parameters);
  const apiResult = await apiRequest('GET', url);
  const embeddedContent = apiResult?._embedded; // eslint-disable-line no-underscore-dangle
  if (embeddedContent === null || embeddedContent === undefined) {
    return apiResult;
  }
  delete apiResult._embedded; // eslint-disable-line no-underscore-dangle
  return { ...apiResult, ...embeddedContent };
}

// export async function pinCourse(courseId: string): Promise<void> {
//   return apiRequest('POST', `/my-course-pins/${courseId}`);
// }

// export async function unPinCourse(courseId: string): Promise<void> {
//   return apiRequest('DELETE', `/my-course-pins/${courseId}`);
// }

export async function enrollInCourse(data: { courseId: string }): Promise<void> {
  return apiRequest('POST', `/my-enrollments`, data);
}

export async function reEnrollInCourse(courseId: string): Promise<void> {
  return apiRequest('POST', `/my-enrollments/${courseId}/re-enroll`);
}

export async function fetchMyCourseEnrollment(courseId: string): Promise<CourseEnrollmentData> {
  return apiRequest('GET', `/my-course-enrollments/${courseId}`);
}

// export async function fetchCurriculumProgress(courseId: string): Promise<CurriculumProgressDetailsData> {
//   return apiRequest('GET', `/my-course-enrollments/${courseId}/curricula-progress`);
// }

export async function fetchResumeCourse(params: SearchFilterParams, offset = 0): Promise<CatalogCourseData[]> {
  const parameters = params;
  if (parameters.sort) {
    delete parameters.sort;
  }
  const url = formatUrl(`/my-resumable-courses?_offset=${offset}`, parameters);
  return apiRequest('GET', url).then((data) => data.courses);
}

export async function fetchFeaturedCourses(params: SearchFilterParams, offset = 0): Promise<CatalogCourseData[]> {
  const parameters = params;
  if (parameters.sort) {
    delete parameters.sort;
  }
  const url = formatUrl(`/my-featured-courses?_offset=${offset}`, parameters);
  return apiRequest('GET', url).then((data) => data.courses);
}

export function fetchCourseContent(courseId: string, lessonId: string): Promise<string> {
  return apiRequest('GET', `/content?courseId=${courseId}&lessonId=${lessonId}`);
}

export async function fetchMyEnrollments(
  limit?: number,
  sort?: string,
  offset?: number
): Promise<TranscriptResponseData> {
  return apiRequest('GET', `/my-enrollments?_limit=${limit}&_sort=${sort}&_offset=${offset}`);
}

export async function fetchMyCourseCategories(params: SearchFilterParams): Promise<CategoriesData[]> {
  const parameters = params;
  if (parameters.sort) {
    delete parameters.sort;
  }
  const url = formatUrl(`/my-courses-categories?_limit=${COURSES_PER_PAGE}`, parameters);
  return apiRequest('GET', url).then((data) => data.categories);
}

// export async function fetchMyCoursesTags(): Promise<TagData[]> {
//   return apiRequest('GET', '/my-courses/tags').then((data) => data.tags);
// }

import { ReactElement, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { DashboardLayout } from '../components/dashboard/DashboardLayout';
import { CourseCard } from '../components/course/CourseCard';
import { Spinner } from '../components/controls/Spinner';
import { useSearchContext } from '../contexts/searchContext';
import { MessageDisplayView } from '../components/global/MessageDisplayView';
import { LoadMoreActionButton } from '../components/controls/LoadMoreActionButton';
import { CategoriesData, CatalogCourseData } from '../models/absorb/catalog';
import { fetchMyCatalogCourseCategories, fetchMyCatalogCourses } from '../services/catalog';
import { COURSE_NOT_FOUND_PARAMS, ROOT_CATEGORY, DisplayMessages } from '../constants/common';
import { errorHandler } from '../utils/helper';
import { PageTitle } from '../components/global/PageTitle';
import redAlert from '../assets/images/icons/redAlert.svg';
import './CatalogPage.css';

export function CatalogPage(): ReactElement {
  const { categoryId } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const {
    searchFilter,
    pageOffset,
    sortBy,
    currentSearchUri,
    previousSearchUri,
    setDisableFilter,
    incrementPageOffset,
  } = useSearchContext();

  const [catalogData, setCatalogData] = useState<CatalogCourseData[]>([]);
  const [catalogCategories, setCatalogCategories] = useState<CategoriesData[]>([]);
  const [selectedCategoryData, setSelectedCategoryData] = useState<CategoriesData>();
  const [isLoading, setIsLoading] = useState(true);
  const [moreIsLoading, setMoreIsLoading] = useState(false);
  const [totalCourses, setTotalCourses] = useState(0);
  const [showCourseErrorModal, setShowCourseErrorModal] = useState(false);

  const selectedCategory = categoryId ?? (searchFilter.showCategories && ROOT_CATEGORY);

  useEffect(() => {
    // JB: added ? to end of 'includes' to prevent double api calls,
    // as currentSearchUri goes from /catalog to /catalog?sort=name on first load
    // and filters also change this to /catalog?courseTypes=Curriculum&sort=name
    if (currentSearchUri.includes('/catalog?')) {
      if (!moreIsLoading) {
        setIsLoading(true);
        setDisableFilter(true);
      }

      fetchMyCatalogCourses(
        {
          _sort: sortBy,
          showCompleted: true,
          ...searchFilter,
          category: selectedCategory,
        },
        pageOffset
      )
        .then((data) => {
          setCatalogData(pageOffset ? [...catalogData, ...data.courses] : data.courses);
          setTotalCourses(data.totalItems);
        })
        .catch(errorHandler)
        .finally(() => {
          setIsLoading(false);
          setDisableFilter(false);
          setMoreIsLoading(false);
        });
    }
  }, [currentSearchUri, pageOffset]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (currentSearchUri.includes('/catalog?')) {
      fetchMyCatalogCourseCategories({
        _sort: sortBy,
        showCompleted: true,
        ...searchFilter,
        parent: ROOT_CATEGORY,
      })
        .then(setCatalogCategories)
        .catch(errorHandler);
    }
  }, [currentSearchUri]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const filteredCategory = catalogCategories.find((categoryItem) => categoryItem.id === categoryId);
    setSelectedCategoryData(filteredCategory);
  }, [catalogCategories, categoryId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (location.search === `${'?'}${COURSE_NOT_FOUND_PARAMS}`) {
      setShowCourseErrorModal(true);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  function loadMore() {
    setMoreIsLoading(true);
    incrementPageOffset();
  }

  return (
    <DashboardLayout>
      <PageTitle title={t('Catalog')} />
      <div className="catalogPageContainer">
        {selectedCategoryData && (
          <button
            type="button"
            aria-label={t('Catalog')}
            className="catalogPageBackButton"
            title={t('Catalog')}
            onClick={() => navigate(previousSearchUri)}
          />
        )}

        <div className="catalogPageWrapper">
          {showCourseErrorModal && (
            <div className="catalogPageNoResultsContainer">
              <div className="catalogPageNoResultsIcon">
                <img className="catalogPageNoResultsImage" alt="" src={redAlert} />
              </div>
              <div className="catalogPageNoResultsHeader">{t('NoResultsID')}</div>
              <div className="catalogPageNoResultsBtnWrapper">
                <button
                  type="button"
                  className="catalogPageNoResultsButton"
                  title={t('Close')}
                  onClick={() => setShowCourseErrorModal(false)}
                >
                  <span className="catalogPageNoResultsButtonText">{t('Close')}</span>
                </button>
              </div>
            </div>
          )}
          <div className="mainBodyContent">
            {
              // eslint-disable-next-line no-nested-ternary
              isLoading ? (
                <div className="catalogPageLoadingIndicator">
                  <Spinner />
                </div>
              ) : catalogData && catalogData.length === 0 ? (
                <MessageDisplayView errorText={DisplayMessages.NoResultsMainText} />
              ) : (
                <div className="cardContainer">
                  {catalogData.map((catalogCourse: CatalogCourseData) => (
                    <div key={catalogCourse.id} className="cardWrapper">
                      <CourseCard cardData={catalogCourse} />
                    </div>
                  ))}
                </div>
              )
            }
          </div>
        </div>
        {catalogData && catalogData.length < totalCourses && !isLoading ? (
          <LoadMoreActionButton
            currentItemsCount={catalogData.length}
            totalItemsCount={totalCourses}
            itemsAreLoading={moreIsLoading}
            fetchMoreFunction={loadMore}
          />
        ) : null}
      </div>
    </DashboardLayout>
  );
}

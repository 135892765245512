import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  activeTab: string | string[];
  tabName: string;
  onSelectedTab: (headertext: string) => void;
}

export function TabBarItem({ activeTab, tabName, onSelectedTab }: Props): ReactElement {
  const { t } = useTranslation();

  return (
    <div
      className={`tabItem ${activeTab === tabName ? 'tabItemActive' : ''}`}
      id={tabName}
      onClick={() => onSelectedTab(tabName)}
    >
      <button type="button" className="" aria-current="page">
        <span className="">{t(tabName)}</span>
        <div className="lightBlueHalfUnderline" />
      </button>
    </div>
  );
}

import { ReactElement } from 'react';
import { useMediaQuery } from 'react-responsive';
import { ScreenBreakpoints } from '../../../constants/common';
import {
  DASHBOARD_NAME_BREAKPOINT,
  DASHBOARD_NAME_LONG_FONTSIZE,
  DASHBOARD_NAME_REGULAR_FONTSIZE,
} from '../../../constants/styling';
import { useLoginContext } from '../../../contexts/loginContext';
import { useProfileContext } from '../../../contexts/profileContext';
import './HeroCourseProgress.css';

export function HeroCourseProgress(): ReactElement {
  const { profile } = useProfileContext();
  const { termsAndConditions } = useLoginContext();

  const limitedNavbarSpace = useMediaQuery({ maxWidth: ScreenBreakpoints.isTablet - 0.001 });
  const candidateForSmallNameFont =
    +(profile?.firstName?.length ?? 0) + +(profile?.lastName?.length ?? 0) >= DASHBOARD_NAME_BREAKPOINT;

  return (
    <div
      className={termsAndConditions?.currentUserMustAcceptTermsAndConditions ? '' : 'heroProfileCourseProgressWrapper'}
    >
      <div className="heroProfileCourseProgressProfile">
        <div className="heroProfileCourseProgressIntro">
          {termsAndConditions?.currentUserMustAcceptTermsAndConditions ? '' : 'Welcome back'}
        </div>
        <span
          className="heroProfileCourseProgressNameFirstName"
          style={{
            fontSize:
              candidateForSmallNameFont && limitedNavbarSpace
                ? DASHBOARD_NAME_LONG_FONTSIZE
                : DASHBOARD_NAME_REGULAR_FONTSIZE,
          }}
        >
          {profile?.firstName}{' '}
        </span>
        <span
          className="heroProfileCourseProgressNameLastName"
          style={{
            fontSize:
              candidateForSmallNameFont && limitedNavbarSpace
                ? DASHBOARD_NAME_LONG_FONTSIZE
                : DASHBOARD_NAME_REGULAR_FONTSIZE,
          }}
        >
          {profile?.lastName}
        </span>
      </div>
    </div>
  );
}

import { ReactElement } from 'react';
import { SearchResultData } from '../../models/absorb/search';
import { ResourceCard } from '../resource/ResourceCard';

interface Props {
  searchResults: SearchResultData[] | undefined;
}
export function SearchResultCardView({ searchResults }: Props): ReactElement {
  return (
    <div className="searchResultsContainer">
      {searchResults &&
        searchResults.map((searchResult: SearchResultData) => (
          <div key={searchResult.resource.id} className="searchResultItem">
            <ResourceCard resource={searchResult} />
          </div>
        ))}
    </div>
  );
}

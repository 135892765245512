import { ReactElement } from 'react';
import { TagData } from '../../models/absorb/tags';

interface Props {
  tags: TagData[];
}

export function TagList({ tags }: Props): ReactElement {
  return (
    <div className="tagList">
      {tags &&
        tags.map((tag: TagData) => (
          <button key={tag.id} type="button" className="tagListItem">
            {tag.name}
          </button>
        ))}
    </div>
  );
}

import { ChangeEvent, ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { REGEX_PASSWORD_VALIDATION } from '../../constants/regex';
import { ChangePasswordErrorResponse, ChangePasswordValidationErrors } from '../../models/absorb/password';
import { changePassword } from '../../services/profile';
import { doPasswordsMatchByLength } from '../../utils/helper';
import { HtmlTextBox } from '../controls/HtmlTextBox';
import { SpinnerButton } from '../controls/SpinnerButton';
import { useLoginContext } from '../../contexts/loginContext';
import './ProfileDetails.css';
import './ProfileChangePassword.css';

export function ProfileChangePassword(): ReactElement {
  const { t } = useTranslation();

  const { signOut } = useLoginContext();

  const [success, setSuccess] = useState(false);
  const [changePasswordInProgress, setChangePasswordInProgress] = useState(false);
  const [firstAttempt, setFirstAttempt] = useState(true);
  const [isFormValid, setIsFormValid] = useState(false);
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [newPasswordError, setNewPasswordError] = useState('');
  const [newPasswordErrorLatchFlag, setNewPasswordErrorLatchFlag] = useState(false);
  const [confirmPasswordError, setConfirmPasswordError] = useState('');
  const [confirmPasswordErrorLatchFlag, setConfirmPasswordErrorLatchFlag] = useState(false);
  const [serverSideValidationErrors, setServerSideValidationErrors] = useState<ChangePasswordValidationErrors[]>([]);

  useEffect(() => {
    const valid =
      currentPassword !== '' &&
      newPassword !== '' &&
      confirmPassword !== '' &&
      REGEX_PASSWORD_VALIDATION.test(newPassword) &&
      newPassword === confirmPassword;

    if (newPasswordError !== '' || newPasswordErrorLatchFlag) {
      validateNewPassword();
    }

    if (confirmPasswordError !== '' || confirmPasswordErrorLatchFlag || newPasswordError || newPasswordErrorLatchFlag) {
      validatePasswordMatches();
    }

    setIsFormValid(valid);
  }, [firstAttempt, currentPassword, newPassword, confirmPassword]); // eslint-disable-line react-hooks/exhaustive-deps

  function validateNewPassword() {
    const validPassword = REGEX_PASSWORD_VALIDATION.test(newPassword);
    setNewPasswordError(validPassword ? '' : t('MediumPasswordHint'));
    setNewPasswordErrorLatchFlag(validPassword || newPasswordErrorLatchFlag);
  }

  function validatePasswordMatches() {
    if (newPassword.length === confirmPassword.length) {
      updateConfirmPasswordError(newPassword === confirmPassword);
    }
  }

  function onChangeConfirmPassword(e: ChangeEvent<HTMLInputElement>) {
    const tempConfirmPassword = e.target.value;
    updateConfirmPasswordError(doPasswordsMatchByLength(newPassword, tempConfirmPassword));
    setConfirmPassword(tempConfirmPassword);
  }

  function updateConfirmPasswordError(identical: boolean) {
    setConfirmPasswordError(identical ? '' : t('PasswordsNotMatch'));
    setConfirmPasswordErrorLatchFlag(identical || confirmPasswordErrorLatchFlag);
  }

  function handleCurrentPassswordChange(e: ChangeEvent<HTMLInputElement>) {
    setSuccess(false);
    setCurrentPassword(e.target.value);
    setServerSideValidationErrors([]);
  }

  function onSubmit() {
    setChangePasswordInProgress(true);
    if (isFormValid) {
      const changePasswordData = {
        CurrentPassword: currentPassword,
        NewPassword: newPassword,
        VerifyNewPassword: confirmPassword,
      };

      setSuccess(false);
      changePassword(changePasswordData)
        .then(() => {
          setServerSideValidationErrors([]);
          setSuccess(true);
        })
        .catch((error: ChangePasswordErrorResponse) => {
          if (error?.response?.data?.validations['0']) {
            setServerSideValidationErrors(error.response.data.validations[0]);
          }
        })
        .finally(() => {
          setFirstAttempt(false);
          setChangePasswordInProgress(false);
        });
    }
  }

  return (
    <div className="ProfileChangePasswordWrapper">
      <form>
        <div className="sectionTitle">Change Password</div>
        <div className="profileChangePasswordFields">
          <div className="profileChangePasswordCurrent">
            <HtmlTextBox
              labelName={t('EditLearnerCurrentPassword')}
              name="currentPassword"
              type="password"
              value={currentPassword}
              id="currentPassword"
              autoComplete="current-password"
              errorId=""
              required
              onChange={(e) => handleCurrentPassswordChange(e)}
            />
          </div>
          <div className="profileChangePasswordNew">
            <HtmlTextBox
              labelName={t('NewPassword')}
              name="newPassword"
              type="password"
              value={newPassword}
              id="newPassword"
              autoComplete="new-password"
              errorId="errorDiv37"
              required
              halfSize
              onChange={(e) => setNewPassword(e.target.value)}
              onBlur={validateNewPassword}
            />
            {newPasswordError && (
              <div aria-live="assertive" className="profileChangePasswordNewError" id="errorDiv37">
                <span className="">{newPasswordError}</span>
              </div>
            )}
          </div>
          <div className="profileChangePasswordChallenge">
            <HtmlTextBox
              labelName={t('ConfirmPassword')}
              name="confirmPassword"
              type="password"
              value={confirmPassword}
              id="confirmPassword"
              autoComplete="new-password"
              errorId="errorDiv39"
              required
              halfSize
              onChange={onChangeConfirmPassword}
            />
            {confirmPasswordError && (
              <div aria-live="assertive" className="profileChangePasswordInputError" id="errorDiv39">
                <span className="">{confirmPasswordError}</span>
              </div>
            )}
          </div>
        </div>
        <div className="profileChangePasswordSubmitWrapper">
          <div className="profileChangePasswordActionButtons">
            <div className="profileChangePasswordActionButtonsSignOut">
              <button type="button" className="button-reverse-l" title="Sign Out" onClick={signOut}>
                Sign Out
              </button>
            </div>
            <div className="profileChangePasswordActionButtonsChange">
              <button
                type="button"
                className="button-default-l"
                title="Change Password"
                disabled={!isFormValid}
                onClick={onSubmit}
              >
                {changePasswordInProgress ? <SpinnerButton /> : 'Change Password'}
              </button>
            </div>
          </div>
        </div>
        <div className="profileChangePasswordSubmitFeedback">
          <div id="profileChangePasswordSubmitFeedbackPositive" className="profileChangePasswordSubmitFeedbackPositive">
            {success && t('PasswordHasBeenSuccessfullyChanged')}
          </div>
          <div id="profileChangePasswordSubmitFeedbackNegative" className="profileChangePasswordSubmitFeedbackNegative">
            {serverSideValidationErrors?.length > 0 &&
              serverSideValidationErrors.map((error: ChangePasswordValidationErrors) => (
                <p key={error.field}>{error.message}</p>
              ))}
          </div>
        </div>
      </form>
    </div>
  );
}

import { ReactElement, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { DashboardLayout } from '../components/dashboard/DashboardLayout';
import { CourseCard } from '../components/course/CourseCard';
import { LoadMoreActionButton } from '../components/controls/LoadMoreActionButton';
import { MessageDisplayView } from '../components/global/MessageDisplayView';
import { Spinner } from '../components/controls/Spinner';
import { fetchMyCourses, fetchMyCourseCategories } from '../services/course';
import { CategoriesData, CatalogCourseData } from '../models/absorb/catalog';
import { useSearchContext } from '../contexts/searchContext';
import { COURSES_PER_PAGE, ROOT_CATEGORY, DisplayMessages } from '../constants/common';
import { SortCoursesBy } from '../constants/courses';
import { errorHandler } from '../utils/helper';
import { PageTitle } from '../components/global/PageTitle';

export function MyCoursesPage(): ReactElement {
  const { categoryId } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    currentSearchUri,
    previousSearchUri,
    searchFilter,
    pageOffset,
    sortBy,
    setDisableFilter,
    incrementPageOffset,
  } = useSearchContext();

  const [isLoading, setIsLoading] = useState(true);
  const [courseCategories, setCourseCategories] = useState<CategoriesData[]>([]);
  const [selectedCategoryData, setSelectedCategoryData] = useState<CategoriesData>();
  const [moreIsLoading, setMoreIsLoading] = useState(false);
  const [totalCourses, setTotalCourses] = useState(0);
  const [myCourseData, setMyCourseData] = useState<CatalogCourseData[]>([]);

  const sort = `${SortCoursesBy.Ispinned},${sortBy || SortCoursesBy.EnrolledDate},${SortCoursesBy.Name}`;
  const selectedCategory = categoryId ?? (searchFilter.showCategories && ROOT_CATEGORY);

  useEffect(() => {
    // JB: added ? to end of 'includes' to prevent double api calls,
    // as currentSearchUri goes from /my-courses to /my-courses?sort=name on first load
    // and filters also change this to /my-courses?courseTypes=Curriculum&sort=name
    if (currentSearchUri.includes('/my-courses?')) {
      if (!moreIsLoading) {
        setIsLoading(true);
        setDisableFilter(true);
      }
      fetchMyCourses(
        {
          _sort: sort,
          _limit: COURSES_PER_PAGE,
          showCompleted: true,
          ...searchFilter,
          category: selectedCategory,
        },
        pageOffset
      )
        .then((data) => {
          setMyCourseData(pageOffset ? [...myCourseData, ...data.courses] : data.courses);
          setTotalCourses(data.totalItems);
        })
        .catch(errorHandler)
        .finally(() => {
          setIsLoading(false);
          setDisableFilter(false);
          setMoreIsLoading(false);
        });
    }
  }, [currentSearchUri, pageOffset]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (currentSearchUri.includes('/my-courses?')) {
      fetchMyCourseCategories({
        _sort: sort,
        showCompleted: true,
        ...searchFilter,
        parent: ROOT_CATEGORY,
      })
        .then(setCourseCategories)
        .catch(errorHandler);
    }
  }, [currentSearchUri]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const filteredCategory = courseCategories.find((categoryItem) => categoryItem.id === categoryId);
    setSelectedCategoryData(filteredCategory);
  }, [courseCategories, categoryId]); // eslint-disable-line react-hooks/exhaustive-deps

  function loadMore() {
    setMoreIsLoading(true);
    incrementPageOffset();
  }

  return (
    <DashboardLayout>
      <PageTitle title={t('MyCourses')} />
      <div className="myCoursesPageContainer">
        <div>
          {selectedCategoryData && (
            <button
              type="button"
              aria-label={t('MyCourses')}
              className="myCoursesPageBackButton"
              title={t('MyCourses')}
              onClick={() => navigate(previousSearchUri)}
            />
          )}
        </div>
        <div className="myCoursesPageWrapper">
          {
            // eslint-disable-next-line no-nested-ternary
            isLoading ? (
              <div className="myCoursesPageLoadingIndicator">
                <Spinner />
              </div>
            ) : myCourseData && myCourseData.length === 0 ? (
              <MessageDisplayView errorText={DisplayMessages.NoResultsMainText} />
            ) : (
              <div className="mainBodyContent">
                <div className="cardContainer">
                  {myCourseData &&
                    myCourseData.map((course: CatalogCourseData) => (
                      <div key={course.id} className="cardWrapper">
                        {/* TODO: feature/E1-1002: <CourseCard cardData={course} updatePinnedStatus={updatePinnedStatus} /> */}
                        <CourseCard cardData={course} />
                      </div>
                    ))}
                </div>
              </div>
            )
          }
        </div>
        {myCourseData && myCourseData.length < totalCourses ? (
          <LoadMoreActionButton
            currentItemsCount={myCourseData.length}
            totalItemsCount={totalCourses}
            itemsAreLoading={moreIsLoading}
            fetchMoreFunction={loadMore}
          />
        ) : null}
      </div>
    </DashboardLayout>
  );
}

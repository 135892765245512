import { ReactElement, useEffect, useState } from 'react';
import ShowMoreText from 'react-show-more-text';
import { LessonsData, MyLessonData } from '../../models/absorb/lessons';
import { errorHandler, getLessonTypeImage, parsedHtml } from '../../utils/helper';
import { LessonCardActionButton } from './LessonCardActionButton';
import { LinearProgressIndicator } from '../controls/LinearProgressIndicator';
import { fetchLesson } from '../../services/lesson';
import './LessonCard.css';

interface Props {
  lesson: LessonsData;
  progress: number;
  openPlayer: (id: string) => void;
}

export function LessonCard({ lesson, progress, openPlayer }: Props): ReactElement {
  const [lessonData, setLessonData] = useState<MyLessonData>();

  useEffect(() => {
    fetchLesson(lesson.id).then(setLessonData).catch(errorHandler);
  }, [lesson.id]);

  return (
    <div className="chapterWrapper">
      <div key={lesson.id} className="lessonWrapper">
        <div className="lessonCardColumnOne">
          <div id="lessonInfo" className="lessonInfo">
            <div title={lesson.name} className="lessonName">
              {lesson.name}
            </div>
            <div className="lessonDescription">
              <ShowMoreText
                lines={3}
                more=" [Show more]"
                less="[Show less]"
                anchorClass="lessonDescriptionShowMoreLink"
                expanded={false}
              >
                {lesson.description?.length > 0 && parsedHtml(lesson.description)}
              </ShowMoreText>
            </div>
          </div>
          <div className="lessonProgress">
            {lesson.enrollment?.status && (
              <>
                <div className="lessonProgressTitle">YOUR PROGRESS</div>
                <LinearProgressIndicator progress={progress} />
              </>
            )}
          </div>
        </div>
        <div className="lessonCardColumnTwo">
          <div id="lessonImage" className="lessonCardImageWrapper">
            <img alt="lesson graphic" className="lessonCardImage" src={getLessonTypeImage(lesson, lessonData)} />
          </div>
          {lesson.enrollment?.status ? (
            <div className="lessonCardActionButton">
              <LessonCardActionButton lessonData={lesson} openPlayer={openPlayer} />
            </div>
          ) : (
            <div className="lessonCardActionButton"></div>
          )}
        </div>
      </div>
    </div>
  );
}

/* eslint-disable no-nested-ternary */
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import Iframe from 'react-iframe';
import { MyLessonData } from '../../models/absorb/lessons';
import { postLessonAttempt, updateLessonActivity } from '../../services/lesson';
import { errorHandler } from '../../utils/helper';
import './ObjectLesson.css';
import { PlayingInNewWindowOverlay } from './PlayingInNewWindowOverlay';

interface Props {
  myLesson: MyLessonData;
  closeLessonPlayer: () => void;
}

export function ObjectLesson({ myLesson, closeLessonPlayer }: Props): ReactElement {
  const { t } = useTranslation();

  function onLessonObjectAccessed() {
    postLessonAttempt(myLesson.id)
      .then((data) => {
        updateLessonActivity(myLesson.id, data.attemptId, { verb: 'Finish', status: 'Complete' });
      })
      .catch(errorHandler);
    return true;
  }

  return (
    <div className="objectLessonWrapper">
      {myLesson.usePopup && !myLesson.mimeType.includes('wordprocess') ? (
        <PlayingInNewWindowOverlay myLesson={myLesson} />
      ) : !myLesson.mimeType.includes('wordprocess') ? (
        <Iframe
          url={myLesson.source}
          width="100%"
          className="lessonPlayerIframe"
          height="100%"
          onLoad={onLessonObjectAccessed}
        />
      ) : (
        <div className="objectLessonContainer">
          <span className="objectLessonIcon" />
          <div className="objectLessonInstructions">{t('RequiresDownloadFile')}</div>
          <div className="objectLessonDownloadLink">
            <a
              className="button-reverse-xl objectLessonCloseIcon"
              onClick={onLessonObjectAccessed}
              href={myLesson.source}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('DownloadFile')}
            </a>
          </div>
          <div className="objectLessonCloseWindowButton">
            <span className="objectLessonCloseWindowButtonText" onClick={closeLessonPlayer}>
              <span className="objectLessonCloseWindowButtonIcon" />
              {t('CloseThisWindow')}
            </span>
            {` ${t('OnceCompletedActivity')} `}
          </div>

          <div className="objectLessonDownloadButtonWrapper">
            <button type="button" className="button-default-xl objectLessonCloseButton" onClick={closeLessonPlayer}>
              Close Window
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useLoginContext } from '../contexts/loginContext';
import { useModalContext } from '../contexts/modalContext';
import { Modal } from './Modal';
import './Modals.css';

export function TimeoutModal(): ReactElement {
  const { t } = useTranslation();

  const { signOut } = useLoginContext();
  const { closeModal } = useModalContext();

  function closeAction() {
    closeModal();
    signOut();
  }

  return (
    <Modal closeModal={closeModal}>
      <div className="modalWrapper" role="dialog" aria-label="Inbox" aria-modal="true">
        <div className="modalBlocker" />
        <div className="modalContainer">
          <div className="modalPerimeter">
            <div className="modalHolder">
              <div className="modalMessagingContainer">
                <div className="modalHeaderIconClock" />
                <div className="modalHeaderText">{t('Timeout')}</div>
                <div className="modalMessage">{t('TimeoutPostMessage')}</div>
                <button type="button" onClick={closeAction} className="buttonRegularBlue">
                  {t('OK')}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

import { ReactElement } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import { Payment } from '../components/cart/Payment';

let stripePromise: Stripe | PromiseLike<Stripe | null> | null;

if (process.env.REACT_APP_STRIPE_PUBLIC_KEY) {
  stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
} else {
  throw new Error('Sorry, an error has occurred - StripePromise Not Found');
}

export function PaymentPage(): ReactElement {
  return (
    <Elements stripe={stripePromise}>
      <Payment />
    </Elements>
  );
}

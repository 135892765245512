import { ReactElement, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import { useTranslation } from 'react-i18next';
import { Popup } from 'reactjs-popup';
import { Badge } from '@mui/material';
import { red } from '@mui/material/colors';
import { MAX_ITEMS_IN_USER_CART } from '../../../constants/common';
import { CartSummary } from '../../cart/CartSummary';
import { useLoginContext } from '../../../contexts/loginContext';
import { useShoppingContext } from '../../../contexts/shoppingContext';
import './HeaderCartIcon.css';

export function HeaderCartIcon(): ReactElement {
  const location = useLocation();
  const { t } = useTranslation();

  const termsAndConditionsPage = location.pathname === '/terms-and-conditions';

  const { loggedIn, termsAndConditions, checkLearnerToken } = useLoginContext();
  const { cartData, goToFirstStep } = useShoppingContext();
  const [cartIsPopulated, setCartIsPopulated] = useState(false);

  useEffect(() => {
    if (cartData.length > 0) {
      setCartIsPopulated(true);
    }
  }, [cartData]);

  useEffect(() => {
    document.addEventListener('mouseup', checkLearnerToken);
    return () => document.removeEventListener('mouseup', checkLearnerToken);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="headerCartIconContainer">
      {loggedIn &&
      cartData.length > 0 &&
      !termsAndConditionsPage &&
      !termsAndConditions?.currentUserMustAcceptTermsAndConditions ? (
        <Popup
          on={['hover', 'focus', 'click', 'right-click']}
          offsetX={120}
          offsetY={65}
          trigger={
            <Link
              className="headerCartButtonLink"
              title={t('ViewShoppingCart')}
              to="/cart"
              onClick={() => {
                goToFirstStep();
              }}
            >
              <div className={cartIsPopulated ? 'headerCartIcon' : 'headerCartIconEmptyCart'}>
                <button
                  type="button"
                  onClick={() => {
                    goToFirstStep();
                  }}
                  className="headerCartButton"
                  title={t('ShoppingCart')}
                >
                  <Badge
                    className={cartIsPopulated ? 'badgeCartPopulated' : 'badgeCartEmpty'}
                    max={MAX_ITEMS_IN_USER_CART}
                    showZero={false}
                    overlap="circular"
                  />
                  <Avatar sx={{ fontSize: 16, fontWeight: 700, bgcolor: red[500], width: 24, height: 24 }}>
                    {cartData.length}
                  </Avatar>
                </button>
              </div>
            </Link>
          }
          position="left bottom"
          className="hostControl"
        >
          {<CartSummary headerHosted closePopup={() => {}} />}
        </Popup>
      ) : (
        <div className="headerCartIconEmptyCart">
          <div className="headerCartIconItemsEmptyCart">
            <span className="headerCartButtonSpanEmptyCart" />
          </div>
        </div>
      )}
    </div>
  );
}

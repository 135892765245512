import { ReactElement } from 'react';
import { TailSpin } from 'react-loader-spinner';

interface Props {
  size?: number;
  color?: string;
}

export function Spinner({ size = 100, color = '#303335' }: Props): ReactElement {
  return (
    <div
      className="loadingIndicator"
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <TailSpin color={color} height={size} width={size} />
    </div>
  );
}

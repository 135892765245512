import { ReactElement, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { DashboardLayout } from '../components/dashboard/DashboardLayout';
import { MessageDisplayView } from '../components/global/MessageDisplayView';
import { fetchResources } from '../services/globalResources';
import { GlobalResourceData } from '../models/absorb/resources';
import { errorHandler } from '../utils/helper';
import { DisplayMessages } from '../constants/common';
import { PageTitle } from '../components/global/PageTitle';
import { Spinner } from '../components/controls/Spinner';
import { GlobalResourceCard } from '../components/globalResources/GlobalResourceCard';
import './GlobalResourcesPage.css';

export function GlobalResourcesPage(): ReactElement {
  const { t } = useTranslation();

  const [resources, setResources] = useState<GlobalResourceData[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    fetchResources({ tags: '', name: '', category: '' })
      .then((resource) => {
        setResources(resource);
      })
      .catch(errorHandler)
      .finally(() => {
        setIsLoading(false);
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <DashboardLayout>
      <PageTitle title={t('Resources')} />
      <div className="courseDetailsPageContentWrapper">
        <div className="HeaderPageTitle">{t('Resources')}</div>
        {isLoading ? (
          <Spinner />
        ) : (
          <div className="resourceContentWrapper" area-hidden="false">
            {resources && resources.length === 0 ? (
              <MessageDisplayView errorText={DisplayMessages.NoResultsMainText} />
            ) : (
              <div className="resourceCardContainer">
                {resources.map((resource) => (
                  <GlobalResourceCard key={resource.id} resource={resource} />
                ))}
              </div>
            )}
          </div>
        )}
      </div>
    </DashboardLayout>
  );
}

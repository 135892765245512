import { ReactElement } from 'react';
import ShowMoreText from 'react-show-more-text';
import { parsedHtml } from '../../utils/helper';
import lessonImage from '../../assets/images/content/course/lesson/sample.webp';
import { CourseResourcesData } from '../../models/absorb/course';
import './ResourceCard.css';

interface Props {
  resourceData: CourseResourcesData;
  allowDownload?: boolean;
}

export function ResourceCard({ resourceData, allowDownload = true }: Props): ReactElement {
  function openResourceURL(url: string) {
    window.open(url, '_blank');
  }

  return (
    <div className="chapterWrapper">
      <div key={resourceData.id} className="lessonWrapper">
        <div className="lessonCardColumnOne">
          <div id="lessonInfo" className="lessonInfo">
            <div title={resourceData.name} className="lessonName">
              {resourceData.name}
            </div>
            <div className="lessonDescription">
              <ShowMoreText
                lines={3}
                more=" [Show more]"
                less="[Show less]"
                anchorClass="lessonDescriptionShowMoreLink"
                expanded={false}
              >
                {resourceData.description?.length > 0 && parsedHtml(resourceData.description)}
              </ShowMoreText>
            </div>
          </div>
        </div>
        <div className="lessonCardColumnTwo">
          <div id="lessonImage" className="lessonCardImageWrapper">
            <img alt="course graphic" className="lessonCardImage" src={lessonImage} />
          </div>
          {allowDownload && (
            <button
              type="button"
              aria-disabled="false"
              aria-label="Download"
              onClick={() => openResourceURL(resourceData.uri)}
              className="resourceCardActionButtonStandard"
            >
              <div className="resourceCardActionButtonText">Download</div>
            </button>
          )}
          <div className="lessonCardActionButton" />
        </div>
      </div>
    </div>
  );
}

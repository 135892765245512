import { ReactElement } from 'react';
import { HeaderLogoBranding } from './HeaderLogoBranding';
import { HeaderPrimaryNavigation } from './HeaderPrimaryNavigation';
import { HeaderSearchInput } from './HeaderSearchInput';
import { HeaderCartIcon } from './HeaderCartIcon';
import { HeaderProfilePhoto } from './HeaderProfilePhoto';
import { HeaderProfileAccess } from './HeaderProfileAccess';
import { useLoginContext } from '../../../contexts/loginContext';

export function Header(): ReactElement {
  const { loggedIn, termsAndConditions } = useLoginContext();

  return (
    <div>
      {loggedIn && !termsAndConditions?.currentUserMustAcceptTermsAndConditions ? (
        <div className="E1_header">
          <div className="header_left">
            <HeaderLogoBranding />
            <HeaderPrimaryNavigation />
          </div>
          <div className="header_center">
            <HeaderSearchInput />
          </div>
          <div className="header_right">
            <HeaderCartIcon />
            <HeaderProfilePhoto />
            <HeaderProfileAccess />
          </div>
        </div>
      ) : (
        <div className="E1_header">
          <div className="header_left">
            <HeaderLogoBranding />
          </div>
          <div className="header_center"></div>
          <div className="header_right"></div>
        </div>
      )}
    </div>
  );
}

import { ReactElement, useState, KeyboardEvent } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { REGEX_SEARCH_FILTER_NO_SPECIAL_CHARS } from '../../../constants/regex';
import { useLoginContext } from '../../../contexts/loginContext';
import { useSearchContext } from '../../../contexts/searchContext';
import { KEY_ENTER, SEARCH_TERM } from '../../../constants/common';

export function HeaderSearchInput(): ReactElement {
  const navigate = useNavigate();
  const location = useLocation();
  const uri = location.pathname;

  const { loggedIn } = useLoginContext();
  const { searchTerm, setSearchTerm, resetPageOffset } = useSearchContext();

  const [term, setTerm] = useState(searchTerm);

  function onKeyPress(e: KeyboardEvent<HTMLDivElement>) {
    if (e.key === KEY_ENTER) {
      if (loggedIn) {
        setSearchTerm(term);
        resetPageOffset();
        navigate(`/global-search?term=${term}`);
      }
    }
  }

  return (
    <div className="headerSearchInput">
      <input
        id="search"
        className="searchInputField"
        placeholder={SEARCH_TERM} // TODO: needs translation: {t('Search')}
        aria-label="Search"
        defaultValue={uri.includes('/global-search') ? searchTerm : ''}
        onChange={(e) => setTerm(e.target.value.replace(REGEX_SEARCH_FILTER_NO_SPECIAL_CHARS, ''))}
        onKeyPress={(e) => onKeyPress(e)}
        autoComplete="off"
      />
    </div>
  );
}

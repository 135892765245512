import { ReactElement } from 'react';
import e1logoIcon from '../../assets/images/global/branding/whiteLogoMini.svg';
import './LoginSplash.css';
import { FooterSignature } from '../layout/footer/FooterSignature';
import { FooterSocialMediaLinks } from '../layout/footer/FooterSocialMediaLinks';

export function LoginSplash(): ReactElement {
  return (
    <div id="branding">
      <div className="E1_header_unauth">
        <div className="headerLogo">
          <div className="headerLogoBranding">
            <img alt="ENDEAVOR ONE" src={e1logoIcon} />
          </div>
          <div className="headerLogoMark">ENDEAVOR</div>
        </div>
      </div>
      <div className="E1_footer_unauth">
        <FooterSignature />
        <FooterSocialMediaLinks />
      </div>
      <div className="headerLogoBrandingClear"></div>
    </div>
  );
}

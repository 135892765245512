import { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { ScreenBreakpoints, HELMET_TITLE, HELMET_TITLE_SHORT } from '../../../constants/common';
import { useLoginContext } from '../../../contexts/loginContext';
import { termsAndLoginRedirectStatus } from '../../../utils/helper';

import e1logoIconWhite from '../../../assets/images/global/branding/whiteLogoMini.svg';
import e1logoIconBlue from '../../../assets/images/global/branding/logoBlueSmall.svg';
import e1LogoBlueTextWhite from '../../../assets/images/global/branding/headerLogoBlueTextWhite.svg';

export function HeaderLogoBranding(): ReactElement {
  const { loggedIn, termsAndConditions } = useLoginContext();

  const isTablet = useMediaQuery({
    minWidth: ScreenBreakpoints.isTablet,
    maxWidth: ScreenBreakpoints.isLargeTablet - 0.1,
  });
  const isMobile = useMediaQuery({
    minWidth: ScreenBreakpoints.isMobile,
    maxWidth: ScreenBreakpoints.isTablet - 0.1,
  });
  const isTiny = useMediaQuery({ maxWidth: ScreenBreakpoints.isMobile - 0.1 });

  function getLogo() {
    if (isMobile || isTiny) {
      return e1logoIconWhite;
    }
    if (isTablet) {
      return e1logoIconBlue;
    }
    return e1LogoBlueTextWhite;
  }

  return (
    <div className="headerLogo">
      <div className="headerLogoBranding">
        <Link
          to={termsAndLoginRedirectStatus(loggedIn, termsAndConditions?.currentUserMustAcceptTermsAndConditions)}
          target="_self"
          title={HELMET_TITLE}
          className="headerLogoBranding"
        >
          <img src={getLogo()} title={HELMET_TITLE_SHORT} alt={HELMET_TITLE_SHORT} />
        </Link>
      </div>
      <div className="headerLogoBrandingClear"></div>
    </div>
  );
}

import { ReactElement } from 'react';
import { CatalogCourseData } from '../../models/absorb/catalog';
import { CardViewType } from '../../constants/common';
import { CourseCardActionButton } from '../course/CourseCardActionButton';
import { parsedHtml, getRandomImage } from '../../utils/helper';
import { LinearProgressIndicator } from '../controls/LinearProgressIndicator';
import './CourseListCard.css';

interface Props {
  course: CatalogCourseData;
  progress: number;
  curriculum?: string;
}

export function CourseListCard({ course, progress, curriculum }: Props): ReactElement {
  return (
    <div className="lmsItemGroupWrapper">
      <div key={course.id} className="lmsItemColumnOne">
        <div id="courseInfo" className="lmsItemInfo">
          <div title={course.name} className="lmsItemName">
            {course.name}
          </div>
          <div className="lmsItemDescription">{course.description?.length > 0 && parsedHtml(course.description)}</div>
        </div>
        {course.enrolled ? (
          <div className="lmsItemProgress">
            <div className="lmsItemProgressTitle">YOUR PROGRESS</div>
            <LinearProgressIndicator progress={progress} />
          </div>
        ) : (
          ''
        )}
      </div>
      <div className="lmsItemColumnTwo">
        <div id="courseImage" className="lmsItemImageWrapper">
          <img
            src={
              course.imageUrl ??
              getRandomImage(course.id, '/assets/images/content/default/course/defaultCourseImage', 'webp', 14)
            }
            className="lmsItemImage"
            alt={course.name || ''}
          />
        </div>
        <div className="lmsItemActionButton">
          <CourseCardActionButton cardData={course} curriculum={curriculum} cardViewType={CardViewType.Horizontal} />
        </div>
      </div>
    </div>
  );
}

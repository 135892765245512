import { ReactElement } from 'react';
import { SearchResultsData } from '../../models/absorb/search';
import { SearchResultCardView } from './SearchResultCardView';

interface Props {
  searchResult: SearchResultsData;
}

export function SearchResultView({ searchResult }: Props): ReactElement {
  return <SearchResultCardView searchResults={searchResult.searchResults} />;
}

import { ReactElement, useState, useRef, ChangeEvent, KeyboardEvent, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { ACCESS_MODE, UserType } from '../../constants/absorb';
import { KEY_ENTER, MINIMUM_PASSWORD_CHARS, MINIMUM_USERNAME_CHARS, SHOW_MOBILE_LOGIN } from '../../constants/common';
import { INVALID_EMAIL, INVALID_PASSWORD, PASSWORD_TIP, USERNAME_TOO_SHORT } from '../../constants/messages';
import { REGEX_EMAIL_VALIDATION, REGEX_PASSWORD_VALIDATION } from '../../constants/regex';
import { useLoginContext } from '../../contexts/loginContext';
import { LoginData } from '../../models/absorb/login';
import { SpinnerButton } from '../controls/SpinnerButton';
import './LoginForm.css';

export function LoginForm(): ReactElement {
  const { t } = useTranslation();

  const { error, signInInProgress, signIn } = useLoginContext();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoginButtonEnabled, setIsLoginButtonEnabled] = useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = useState('');
  const [passwordErrorMessage, setPasswordErrorMessage] = useState('');

  const isSmallFootprint = useMediaQuery({ maxWidth: SHOW_MOBILE_LOGIN });

  const firstRender = useRef(true);

  let validateInput = true;

  useEffect(() => {
    if (emailErrorMessage.length + passwordErrorMessage.length === 0 || email.length + password.length === 0) {
      setIsLoginButtonEnabled(true);
    } else {
      setIsLoginButtonEnabled(false);
    }
  }, [emailErrorMessage, passwordErrorMessage, email, password]);

  if (firstRender.current) {
    firstRender.current = false;
    validateInput = false;
  }

  function onPressEnter(event: KeyboardEvent<HTMLDivElement>) {
    // 'keypress' event misbehaves on mobile so we track 'Enter' key via 'keydown' event
    if (event.key === KEY_ENTER) {
      event.preventDefault();
      event.stopPropagation();
      onSubmit();
    }
  }

  function onSubmit() {
    if (!validateInput || shouldSubmitButtonBeEnabled(email, password)) {
      const data: LoginData = {
        username: email,
        password,
        accessMode: ACCESS_MODE,
        persist: false,
        scope: [UserType.Learner, UserType.Admin, UserType.Instructor],
      };

      signIn(data);
    }
  }

  function shouldSubmitButtonBeEnabled(emailField: string, passwordField: string) {
    if (
      (emailErrorMessage.length + passwordErrorMessage.length === 0 &&
        emailField.length > 0 &&
        passwordField.length > 0) ||
      emailField.length + passwordField.length === 0
    ) {
      setIsLoginButtonEnabled(true);
      return true;
    }
    setIsLoginButtonEnabled(false);
    return false;
  }

  function onChangeEmail(e: ChangeEvent<HTMLInputElement>) {
    setEmail(e.target.value);

    if (!REGEX_EMAIL_VALIDATION.test(e.target.value)) {
      if (e.target.value.length < MINIMUM_USERNAME_CHARS) {
        setEmailErrorMessage(USERNAME_TOO_SHORT);
      } else {
        setEmailErrorMessage(INVALID_EMAIL);
      }
    } else {
      setEmailErrorMessage('');
    }

    shouldSubmitButtonBeEnabled(e.target.value, password);
  }

  function onChangePassword(e: ChangeEvent<HTMLInputElement>) {
    setPassword(e.target.value);

    if (!REGEX_PASSWORD_VALIDATION.test(e.target.value)) {
      if (e.target.value.length < MINIMUM_PASSWORD_CHARS) {
        setPasswordErrorMessage(INVALID_PASSWORD);
      } else {
        setPasswordErrorMessage(PASSWORD_TIP);
      }
    } else {
      setPasswordErrorMessage('');
    }

    shouldSubmitButtonBeEnabled(email, e.target.value);
  }

  return (
    <>
      <div className="loginHeader">
        {isSmallFootprint ? (
          <>Get Started</>
        ) : (
          <>
            Welcome,
            <br />
            please login
          </>
        )}
      </div>
      <form className="loginForm">
        {error && <p className="formSubmitError">{t('InvalidLoginBlurb')}</p>}
        <div className="loginFormUsernameWrapper">
          <div className="loginFormUsername">
            <div className="formText">{t('Email')}</div>
            <div className="formDiv">
              <input
                name="email"
                id="reduxFormInputField1"
                type="text"
                step="any"
                aria-describedby="errorDiv2"
                value={email}
                onChange={onChangeEmail}
                autoComplete="username"
                className={emailErrorMessage.length <= 0 ? 'inputTextBoxValid' : 'inputTextBoxInvalid'}
              />
            </div>
            <div aria-live="assertive" className="inputFieldInvalidText" id="emailInputError">
              {email && <span>{t(emailErrorMessage)}</span>}
            </div>
          </div>
        </div>
        <div className="loginFormPasswordWrapper">
          <div className="loginFormPassword">
            <div className="formText">{t('Password')}</div>
            <div className="formDiv">
              <input
                name="password"
                id="reduxFormInputField3"
                type="password"
                step="any"
                aria-describedby="errorDiv4"
                value={password}
                onChange={onChangePassword}
                onKeyDown={onPressEnter}
                autoComplete="current-password"
                className={passwordErrorMessage.length <= 0 ? 'inputTextBoxValid' : 'inputTextBoxInvalid'}
              />
            </div>
            <div aria-live="assertive" className="inputFieldInvalidText" id="passwordInputError">
              {password && <span>{t(passwordErrorMessage)}</span>}
            </div>
          </div>
        </div>
        <div id="divLoginButton">
          <button
            type="button"
            className={signInInProgress ? 'buttonWideBlueWait' : 'buttonWideBlue'}
            disabled={!isLoginButtonEnabled || signInInProgress}
            onClick={onSubmit}
          >
            {signInInProgress ? <SpinnerButton /> : t('Login')}
          </button>
        </div>
      </form>
      <div className="ancillaryLinksWrapper">
        <div className="horizontalLinkListBlue">
          <Link to={`/login?form=reset-password&email=${encodeURIComponent(email)}`}>Can&apos;t Login?</Link>
          {/* <Link to="/">Not You?</Link> TODO: re-enable at a later date when MVP stage is complete.
          <Link to="/">Sign up for an account</Link> */}
        </div>
      </div>
    </>
  );
}

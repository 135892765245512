import { ReactElement } from 'react';
import './GenericMediumHero.css';

export function GenericMediumHero(): ReactElement {
  return (
    <div className="GenericMediumHeroContentWrapper">
      <div className="GenericMediumHeroContent">
        <span className="GenericMediumHeroContentMessage"></span>
      </div>
    </div>
  );
}

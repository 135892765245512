import { ReactElement, useState } from 'react';
import { Popover } from '@mui/material';
import { errorHandler } from '../../utils/helper';
import openExternalLink from '../../assets/images/icons/whiteOpenExternalLink.svg';

interface Props {
  url: string;
}

export function ShareThis({ url }: Props): ReactElement {
  const [anchorElement, setAnchorElement] = useState<HTMLButtonElement | null>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElement(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorElement(null);
  };

  let open = Boolean(anchorElement);
  const id = open ? 'shareThis' : undefined;

  function copyToClipboard() {
    navigator.clipboard
      .writeText(url)
      .then(() => {
        open = true;
      })
      .catch((err) => {
        open = false;
        errorHandler(err);
      });
  }

  return (
    <div className="shareThisWrapper">
      <button className="shareThisButton" onClick={handleClick}>
        <div className="shareThisButtonIcon">
          <img alt="" src={openExternalLink} className="shareThisButtonIcon" />
        </div>
        <div className="shareThisButtonText">Share Course</div>
      </button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorElement}
        onClick={copyToClipboard}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
      >
        <div className="shareThisButtonContentWrapper">
          <div className="shareThisButtonContentText">Copy link to the clipboard</div>
          <div className="shareThisButtonContentAction">
            <button title="Close" onClick={handleClose} className="shareThisButtonClose">
              copy
            </button>
          </div>
        </div>
      </Popover>
    </div>
  );
}

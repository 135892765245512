// import { TagData } from '../models/absorb/tags';
import { GlobalResourceData } from '../models/absorb/resources';
import { apiRequest } from '../utils/axios';
import { formatUrl } from '../utils/helper';
import { SearchFilterParams } from '../models/absorb/search';
import { GLOBAL_RESOURCES_PER_PAGE } from '../constants/common';
// import { CategoriesData } from '../models/absorb/catalog';

export async function fetchResources(params: SearchFilterParams): Promise<GlobalResourceData[]> {
  const url = formatUrl(`/my-global-resources?limit=${GLOBAL_RESOURCES_PER_PAGE}`, params);
  return apiRequest('GET', url).then((data) => data.resources);
}

export async function onFetchResourceUrl(postData: { id: string }): Promise<string> {
  return apiRequest('POST', '/my-global-resources/open', postData).then((data) => data.uri);
}

// export async function fetchResourcesTags(): Promise<TagData[]> {
//   return apiRequest('GET', `/my-global-resources/tags`).then((data) => data.tags);
// }

// export async function fetchMyResourcesCategories(params: SearchFilterParams): Promise<CategoriesData[]> {
//   const url = formatUrl(`/my-global-resource-categories?_limit=${GLOBAL_RESOURCES_PER_PAGE}`, params);
//   return apiRequest('GET', url).then((data) => data.categories);
// }

import { ReactElement } from 'react';
import { CatalogCourseData } from '../../models/absorb/catalog';
import { parsedHtml } from '../../utils/helper';
import { TagList } from '../global/TagList';
import './CourseDescription.css';

interface Props {
  course: CatalogCourseData;
}

export function CourseDescription({ course }: Props): ReactElement {
  return (
    <div className="courseIntroWrapper">
      {course.tags.length > 0 && (
        <div className="courseTags">
          <TagList tags={course.tags} />
        </div>
      )}
      {course.description && <div className="courseDescription">{parsedHtml(course.description)}</div>}
    </div>
  );
}

import { ReactElement } from 'react';
import { EntitiesFilter } from './EntitiesFilter';
// import { ShowCategoriesSwitch } from './ShowCategoriesSwitch';
import { ResetFilter } from './ResetFilter';
import './SideFilter.css';

export function SideFilter(): ReactElement {
  return (
    <span className="SideFilter">
      <form>
        {/* TODO: feature/E1-997: <ShowCategoriesSwitch /> */}
        <EntitiesFilter />
        <ResetFilter />
      </form>
    </span>
  );
}

import { ReactElement } from 'react';
import { useLocation } from 'react-router-dom';
import { FooterSignature } from './FooterSignature';
import { FooterSocialMediaLinks } from './FooterSocialMediaLinks';
import { useLoginContext } from '../../../contexts/loginContext';

export function Footer(): ReactElement {
  const { loggedIn } = useLoginContext();
  const location = useLocation();

  return (
    <>
      {location.pathname.includes('/cart') ? '' : <div className="E1_footer_gap">&nbsp;</div>}
      <div className={loggedIn ? 'E1_footer' : 'E1_footer_unauth'}>
        <FooterSignature />
        <FooterSocialMediaLinks />
      </div>
    </>
  );
}

// export const MINIMUM_CHAR = 'MinCharsError';
// export const PASSWORD_HINT = 'MediumPasswordHint';
export const PASSWORD_TIP = 'MinimumPassword';
// export const PASSWORD_MISMATCH = 'PasswordsNotMatch';
export const INVALID_EMAIL = 'EmailNotValid';
export const INVALID_PASSWORD = 'PasswordNotMeetRequirements';
export const USERNAME_TOO_SHORT = 'UsernameTooShort';
// export const PRIORITY_MESSAGE = 'priority';
// export const SYSTEM_MESSAGE = 'system';
export const MESSAGE_CHECK_INTERVAL = 300000;
export const DEFAULT_MESSAGE_RETRIEVAL_COUNT = 8;
export const DEFAULT_MESSAGEREAD_RETRIEVAL_COUNT = 1;
export const SHOW_MARKASREAD_CHARACTER_LIMIT = 50;

import { ReactElement, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { errorHandler, parsedHtml, getRandomImage } from '../utils/helper';
import { fetchBundleCourses, fetchBundleDetails } from '../services/bundle';
import { fetchMyCatalogCourse } from '../services/catalog';
import { DashboardLayout } from '../components/dashboard/DashboardLayout';
import { TagList } from '../components/global/TagList';
import { Spinner } from '../components/controls/Spinner';
import { ShareThis } from '../components/global/ShareThis';
import { CatalogCourseData } from '../models/absorb/catalog';
import { BundleCoursesData, BundleDetailsData } from '../models/absorb/bundle';
import './BundlePage.css';
import { BundleContent } from '../components/bundle/BundleContent';
import { BundlePageActionButton } from '../components/bundle/BundlePageActionButton';
import { useCourseAccessErrorRedirect } from '../hooks/useCourseAccessErrorRedirect';

export function BundlePage(): ReactElement {
  const { courseId: courseIdParam } = useParams<{ courseId: string }>();
  const courseId = courseIdParam || '';
  const [catalogCourse, setCatalogCourse] = useState<CatalogCourseData>();
  const [bundleDetails, setBundleDetails] = useState<BundleDetailsData>();
  const [bundleCourses, setBundleCourses] = useState<BundleCoursesData>();
  const [loadingInProgress, setLoadingInProgress] = useState(true);
  const [setCourseAccessError] = useCourseAccessErrorRedirect();

  useEffect(() => {
    fetchMyCatalogCourse(courseId)
      .then(setCatalogCourse)
      .catch((err) => {
        errorHandler(err);
        setCourseAccessError(true);
      });
  }, [courseId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setLoadingInProgress(true);

    fetchBundleDetails(courseId)
      .then((bundleDetailsData) => {
        setBundleDetails(bundleDetailsData);

        Promise.all([fetchBundleCourses(courseId).then(setBundleCourses)])
          .catch((err) => {
            errorHandler(err);
            setCourseAccessError(true);
          })
          .finally(() => setLoadingInProgress(false));
      })
      .catch((err) => {
        errorHandler(err);
        setLoadingInProgress(false);
        setCourseAccessError(true);
      });
  }, [courseId]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <DashboardLayout>
      {loadingInProgress ? (
        <Spinner />
      ) : (
        <div className="curriculumPageWrapper">
          {catalogCourse && bundleDetails && <BundlePageActionButton course={catalogCourse} />}
          <ShareThis url={window.location.href} />
          <div className="bundlePageImagePrimary">
            <img
              src={
                bundleDetails?.imageUri ||
                getRandomImage(
                  bundleDetails?.id || '',
                  '/assets/images/content/default/course/defaultCourseImage',
                  'webp',
                  14
                )
              }
              className="imageCourse"
              alt={bundleDetails?.name || ''}
            />
          </div>
          <div className="bundlePageTitleWrapper">
            {
              bundleDetails ? (
                <div className="bundlePageTitle">{bundleDetails.name}</div>
              ) : null /* TODO: bundle could be null because it's invalid. */
            }
          </div>
          {((catalogCourse?.tags && catalogCourse.tags.length > 0) || bundleDetails?.description) && (
            <div className="bundlePageIntroWrapper">
              <div className="bundlePageTags">
                <TagList tags={catalogCourse ? catalogCourse.tags : []} />
              </div>
              <div className="bundlePageDescription">{parsedHtml(bundleDetails?.description || '')}</div>
            </div>
          )}
          <div className="bundlePageContent">{bundleCourses && <BundleContent bundleCourses={bundleCourses} />}</div>
        </div>
      )}
    </DashboardLayout>
  );
}

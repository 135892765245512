import { ReactElement, Fragment, useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { errorHandler, getTimeShortDateMonth3Character } from '../utils/helper';
import { TranscriptData, TranscriptResponseData } from '../models/absorb/transcripts';
import { useProfileContext } from '../contexts/profileContext';
import { API_RESULT_LIMIT, SortBy } from '../constants/common';
import { LoadMoreActionButton } from '../components/controls/LoadMoreActionButton';
import { PageTitle } from '../components/global/PageTitle';
import { fetchMyEnrollments } from '../services/course';
import { DashboardLayout } from '../components/dashboard/DashboardLayout';
import { Spinner } from '../components/controls/Spinner';
import { SpinnerButton } from '../components/controls/SpinnerButton';
import sortIcon from '../assets/images/global/icon/icon_sort.svg';
import printHeaderLogo from '../assets/images/global/branding/logoBlueSmall.svg';
import './TranscriptsPage.css';

export function TranscriptsPage(): ReactElement {
  const { t } = useTranslation();

  const { profile } = useProfileContext();

  const componentRef = useRef(null);

  const [sortBy, setSortBy] = useState<string>(SortBy.CourseName);
  const [enrollmentData, setEnrollmentData] = useState<TranscriptData[]>([]);
  const [ascendingOrder, setAscendingOrder] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [isMyEnrollmentsLoading, setIsMyEnrollmentsLoading] = useState(true);
  const [offset, setOffset] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [isPrintReady, setIsPrintReady] = useState(false);
  const [printButtonDisabled, setPrintButtonDisabled] = useState(false);

  useEffect(() => {
    const style = document.createElement('style');
    style.innerHTML = `@page {size: ${'landscape'}}`;
    style.id = 'page-orientation';
    document.head.appendChild(style);
  }, []);

  useEffect(() => {
    if (isPrintReady) {
      window.print();
      setOffset(Math.ceil(totalItems / API_RESULT_LIMIT) - 1);
    }
  }, [isPrintReady, totalItems]);

  useEffect(() => {
    if (
      ((offset !== Math.ceil(totalItems / API_RESULT_LIMIT) || offset === 0) && enrollmentData.length < totalItems) ||
      enrollmentData.length + totalItems === 0
    ) {
      const limit = API_RESULT_LIMIT;
      const sort = ascendingOrder ? sortBy : `-${sortBy}`;
      getTranscriptBatch(limit, sort, offset);
    }
  }, [offset]); // eslint-disable-line react-hooks/exhaustive-deps

  function setSortingParams(columnName: string) {
    setOffset(0);
    if (sortBy === columnName) {
      setAscendingOrder(!ascendingOrder);
    } else {
      setAscendingOrder(true);
    }
    setSortBy(columnName);
  }

  function fetchMoreTranscript() {
    setIsMyEnrollmentsLoading(true);
    setOffset(offset + 1);
  }

  function getClassName(sortType: string): string {
    const defaultClass = '';
    const sortedOrderClass = ascendingOrder ? 'icon icon-arrow-up' : 'icon icon-arrow-down';

    const lookup: { [key: string]: string } = {
      [sortType]: sortedOrderClass,
    };

    return lookup[sortBy] || defaultClass;
  }

  async function getTranscriptBatch(limit: number, sort: string, start: number) {
    if (
      ((offset !== Math.ceil(totalItems / API_RESULT_LIMIT) || offset === 0) && enrollmentData.length < totalItems) ||
      enrollmentData.length + totalItems === 0
    ) {
      let fetchResult = false;
      await fetchMyEnrollments(limit, sort, start)
        .then((response: TranscriptResponseData) => {
          if (response.offset === 0) {
            setEnrollmentData(response.enrollments);
          } else {
            setEnrollmentData((enrollments) => [...enrollments, ...response.enrollments]);
          }
          setTotalItems(response.totalItems);
          setIsMyEnrollmentsLoading(false);
          fetchResult = true;
        })
        .catch((err) => {
          errorHandler(err);
          fetchResult = true;
        })
        .finally(() => {
          setIsLoading(false);
          return fetchResult;
        });
    }
  }

  async function loadAllTranscripts() {
    const calls = Math.ceil(totalItems / API_RESULT_LIMIT);
    for (let i = offset + 1; i < calls; i += 1) {
      await Promise.all([getTranscriptBatch(API_RESULT_LIMIT, sortBy, i)]); // eslint-disable-line no-await-in-loop
    }
  }

  async function printTranscript() {
    setIsPrintReady(false);
    setPrintButtonDisabled(true);
    await loadAllTranscripts();
    setPrintButtonDisabled(false);
    setIsPrintReady(true);
  }

  return (
    <DashboardLayout>
      {isLoading ? (
        <Spinner />
      ) : (
        <div className="printWrapper" ref={componentRef}>
          <PageTitle title={t('Transcript')} />
          <div className="printHeaderWrapper">
            <div className="printHeader">
              <div className="printHeaderLogo">
                <img alt="logo" src={printHeaderLogo} />
              </div>
              <div className="printHeaderText">ENDEAVOR</div>
            </div>
            <div className="printHeaderDts">{Date()}</div>
          </div>
          <div className="transcriptsPageHeader">
            <div className="headerPageTitle">{t('Transcript')}</div>
          </div>
          <div className="">
            <div className="headerPagePrintButtonWrapper">
              <div className="headerPagePrintButtonSpacer" />
              <div className="headerPagePrintButton">
                <button
                  type="submit"
                  onClick={printTranscript}
                  title="Print"
                  className={
                    printButtonDisabled ? 'tabHeaderContainerLinkButtonDisabled' : 'tabHeaderContainerLinkButtonEnabled'
                  }
                  disabled={printButtonDisabled}
                >
                  <div className="">
                    <div className="buttonLoadingText">
                      <div className="printButtonTextWrapper">
                        {printButtonDisabled ? (
                          <div className="printWaitSpinner">
                            <SpinnerButton />
                          </div>
                        ) : (
                          ''
                        )}
                        <div>Print Transcript</div>
                      </div>
                    </div>
                  </div>
                </button>
              </div>
            </div>
          </div>
          <div className="transcriptContainer">
            <div className="statsBoxWrapper">
              <div className="sectionHeader">Stats</div>
              <div className="statsBox">
                <div className="statsBoxItem">
                  <div className="statsBoxTitle">{t('TranscriptStatusName')}</div>
                  <div className="statsBoxText">{profile && `${profile.firstName} ${profile.lastName}`}</div>
                </div>
                <div className="statsBoxItem">
                  <div className="statsBoxTitle">{t('EmailAddress')}</div>
                  <div className="statsBoxText">{profile && profile.emailAddress}</div>
                </div>
                <div className="statsBoxItem">
                  <div className="statsBoxTitle">{t('Department')}</div>
                  <div className="statsBoxText">{profile && profile.departmentName}</div>
                </div>
              </div>
            </div>

            {enrollmentData.length > 0 && (
              <div className="transcriptsDataTable">
                <div id="enrollments" className="">
                  <div className="sectionHeader">{t('Courses')}</div>
                  <div className="transcriptCoursesDataTable" role="table">
                    <div role="rowgroup">
                      <div className="transcriptRowHeader" role="row">
                        <div className="transcriptRowHeaderTitle" role="columnheader">
                          <button
                            className="transcriptRowHeaderSortButton"
                            onClick={() => setSortingParams(SortBy.CourseName)}
                          >
                            <div className="transcriptRowHeaderItemButtonContainer">
                              <div>{t('CourseTitle')}</div>
                              <div className="transcriptRowHeaderItemButtonContainerImage">
                                <span className={getClassName(SortBy.CourseName)} />
                                <img className="sortIcon" alt="Sort by this column" src={sortIcon} />
                              </div>
                            </div>{' '}
                          </button>
                        </div>
                        <div className="transcriptRowHeaderStatus" role="columnheader">
                          <button
                            className="transcriptRowHeaderSortButton"
                            onClick={() => setSortingParams(SortBy.EnrollmentStatus)}
                          >
                            <div className="transcriptRowHeaderItemButtonContainer">
                              {t('Status')}
                              <div className="transcriptRowHeaderItemButtonContainerImage">
                                <span className={getClassName(SortBy.EnrollmentStatus)} />
                                <img className="sortIcon" alt="Sort by this column" src={sortIcon} />
                              </div>
                            </div>
                          </button>
                        </div>
                        <div className="transcriptRowHeaderScore" role="columnheader">
                          <button
                            className="transcriptRowHeaderSortButton"
                            onClick={() => setSortingParams(SortBy.ExamScore)}
                          >
                            <div className="transcriptRowHeaderItemButtonContainer">
                              {t('Score')}
                              <div className="transcriptRowHeaderItemButtonContainerImage">
                                <span className={getClassName(SortBy.ExamScore)} />
                                <img className="sortIcon" alt="Sort by this column" src={sortIcon} />
                              </div>
                            </div>
                          </button>
                        </div>
                        <div className="transcriptRowHeaderEnrollment" role="columnheader">
                          <button
                            className="transcriptRowHeaderSortButton"
                            onClick={() => setSortingParams(SortBy.EnrollmentDate)}
                          >
                            <div className="transcriptRowHeaderItemButtonContainer">
                              {t('EnrollmentDate')}
                              <div className="transcriptRowHeaderItemButtonContainerImage">
                                <span className={getClassName(SortBy.EnrollmentDate)} />
                                <img className="sortIcon" alt="Sort by this column" src={sortIcon} />
                              </div>
                            </div>
                          </button>
                        </div>
                        <div className="transcriptRowHeaderCompletion" role="columnheader">
                          <button
                            className="transcriptRowHeaderSortButton"
                            onClick={() => setSortingParams(SortBy.CompletionDate)}
                          >
                            <div className="transcriptRowHeaderItemButtonContainer">
                              {t('CompletionDate')}
                              <div className="transcriptRowHeaderItemButtonContainerImage">
                                <span className={getClassName(SortBy.CompletionDate)} />
                                <img className="sortIcon" alt="Sort by this column" src={sortIcon} />
                              </div>
                            </div>
                          </button>
                        </div>
                        <div className="transcriptRowHeaderCredits" role="columnheader">
                          <button
                            className="transcriptRowHeaderSortButton"
                            onClick={() => setSortingParams(SortBy.EducationalCredits)}
                          >
                            <div className="transcriptRowHeaderItemButtonContainer">
                              {t('Credits')}
                              <div className="transcriptRowHeaderItemButtonContainerImage">
                                <img className="sortIcon" alt="Sort by this column" src={sortIcon} />
                              </div>
                            </div>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="transcriptsDataTableContent" role="rowgroup">
                      {enrollmentData.map((enrollment: TranscriptData) => (
                        <Fragment key={enrollment.id}>
                          <div className="transcriptRowDataWrapper">
                            <div className="transcriptRowData" role="row">
                              <div className="transcriptRowTitle" role="cell">
                                {enrollment.courseName}
                              </div>
                              <div className="transcriptRowStatus" role="cell">
                                <div className="transcriptRowStatusText">{t(enrollment.enrollmentStatus)}</div>
                              </div>
                              <div className="transcriptRowScore" role="cell">
                                {enrollment.examScore ? <>{`${enrollment.examScore}%`}</> : null}
                              </div>
                              <div className="transcriptRowEnrollment" role="cell">
                                {getTimeShortDateMonth3Character(enrollment.enrollmentDate)}{' '}
                              </div>
                              <div className="transcriptRowCompletion" role="cell">
                                {enrollment.completionDate ? (
                                  <>{getTimeShortDateMonth3Character(enrollment.completionDate)}</>
                                ) : null}
                              </div>
                              <div className="transcriptRowCredits" role="cell">
                                {enrollment.educationalCredits ? <>{enrollment.educationalCredits}</> : null}
                              </div>
                            </div>
                          </div>
                          <div className="transcriptRowSpacer">&nbsp;</div>
                        </Fragment>
                      ))}
                    </div>
                    <div className="transcriptsLoadMoreWrapper">
                      {totalItems && enrollmentData.length < totalItems && (
                        <LoadMoreActionButton
                          currentItemsCount={enrollmentData.length}
                          totalItemsCount={totalItems}
                          itemsAreLoading={isMyEnrollmentsLoading}
                          fetchMoreFunction={fetchMoreTranscript}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </DashboardLayout>
  );
}

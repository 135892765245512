import { ReactElement, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import FileSaver from 'file-saver';
import { DashboardLayout } from '../components/dashboard/DashboardLayout';
import { errorHandler, parsedHtml } from '../utils/helper';
import { fetchInstructorLedDetails, fetchInstructorLedSessions, fetchCalendarLink } from '../services/instructorLed';
import { InstructorLedDetailsData, InstructorLedSessionsData } from '../models/absorb/instructorLed';
import { PageTitle } from '../components/global/PageTitle';
import { InstructorLedCourseContent } from '../components/instructorLed/InstructorLedCourseContent';
import { Spinner } from '../components/controls/Spinner';
import defaultCourseCardImage from '../assets/images/components/search/defaultCardColor.png';
import iconCalendarWhite from '../assets/images/icons/calendarWhiteIcon.svg';
import './InstructorLedPage.css';
import { useCourseAccessErrorRedirect } from '../hooks/useCourseAccessErrorRedirect';

export function InstructorLedPage(): ReactElement {
  const { t } = useTranslation();

  const { courseId: courseIdParam } = useParams<{ courseId: string }>();
  const courseId = courseIdParam || '';

  const [isLoading, setIsLoading] = useState(true);
  const [instructorLedDetails, setInstructorLedDetails] = useState<InstructorLedDetailsData>();
  const [instructorLedSessions, setInstructorLedSessions] = useState<InstructorLedSessionsData>();
  const [setCourseAccessError] = useCourseAccessErrorRedirect();

  useEffect(() => {
    fetchInstructorLedDetails(courseId)
      .then((instructorLedDetailsData) => {
        setInstructorLedDetails(instructorLedDetailsData);
        Promise.all([fetchInstructorLedSessions(courseId).then(setInstructorLedSessions)])
          .catch(errorHandler)
          .finally(() => setIsLoading(false));
      })
      .catch((err) => {
        errorHandler(err);
        setIsLoading(false);
        setCourseAccessError(true);
      });
  }, [courseId]); // eslint-disable-line react-hooks/exhaustive-deps

  function downloadCalendar() {
    if (instructorLedDetails?.id) {
      if (instructorLedSessions?.sessions && instructorLedSessions.sessions.length > 0) {
        fetchCalendarLink(instructorLedDetails.id, instructorLedSessions?.sessions[0].id)
          .then((data: BlobPart) => {
            const blob = new Blob([data], { type: 'text/calendar; charset=utf-8' });
            FileSaver.saveAs(blob, `${instructorLedDetails.name}`);
          })
          .catch(errorHandler);
      }
    }
  }

  return (
    <DashboardLayout>
      <PageTitle title={t('InstructorLedCourse')} />
      {isLoading ? (
        <Spinner />
      ) : (
        <div className="instructorLedPageContainer">
          <div className="sessionHeaderDownloadCalendarLinkWrapper">
            <button
              type="button"
              aria-disabled="false"
              aria-label={t('AddToOutlook')}
              className="sessionHeaderDownloadCalendarLink"
              onClick={downloadCalendar}
            >
              <div className="sessionHeaderDownloadCalendarLinkContent">
                <div className="sessionHeaderDownloadCalendarLinkButtonImage">
                  <img alt="Add to Calendar" src={iconCalendarWhite} />
                </div>
                <div className="sessionHeaderDownloadCalendarLinkButtonText">{t('AddToOutlook')}</div>
              </div>
            </button>
          </div>
          <div className="instructorLedImagePrimaryWrapper">
            <img
              src={instructorLedDetails?.imageUri || defaultCourseCardImage}
              className="instructorLedImagePrimary"
              alt={instructorLedDetails?.name}
            />
          </div>
          <div className="">
            {instructorLedDetails ? <div className="instructorLedTitle">{instructorLedDetails.name}</div> : null}
            {instructorLedDetails?.description && (
              <div className="instructorLedIntroWrapper">
                <div className="instructorLedDescription">{parsedHtml(instructorLedDetails.description)}</div>
              </div>
            )}
          </div>
          <div className="">
            {instructorLedDetails && instructorLedSessions && (
              <InstructorLedCourseContent
                instructorLedDetails={instructorLedDetails}
                instructorLedSessions={instructorLedSessions}
              />
            )}
          </div>
        </div>
      )}
    </DashboardLayout>
  );
}

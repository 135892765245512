import { ReactElement, useEffect, useState } from 'react';
import Avatar from '@mui/material/Avatar';
import { Link } from 'react-router-dom';
import { Badge, Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';
import { lightBlue } from '@mui/material/colors';
import Zoom from '@mui/material/Zoom';
import { useProfileContext } from '../../../contexts/profileContext';
import { useMessageContext } from '../../../contexts/messageContext';
import './HeaderProfilePhoto.css';

export function HeaderProfilePhoto(): ReactElement {
  const { profile } = useProfileContext();
  const { unreadMessages } = useMessageContext();

  const [showNotificationDot, setShowNotificationDot] = useState(false);
  const firstNameInitial = profile?.firstName ? profile.firstName.charAt(0).toUpperCase() : '';
  const lastNameInitial = profile?.lastName ? profile.lastName.charAt(0).toUpperCase() : '';
  const avatarText = `${firstNameInitial}${lastNameInitial}`;

  const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
      backgroundColor: '#E4333E',
      color: '#FFFFFF',
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      border: '2px solid #ffffff',
      borderRadius: '50%',
      width: '14px',
      height: '14px',
      position: 'absolute',
      top: '0px',
      right: '0px',
      transform: 'scale(1)',
      animation: 'pulse 2s infinite',

      '@keyframes pulse': {
        '0%': {
          transform: 'scale(.95)',
          boxShadow: '0 0 0 0 rgba(0, 0, 0, 0.8)', // 255, 0, 0, 0.8
        },
        '70%': {
          transform: 'scale(1)',
          boxShadow: '0 0 0 10px rgba(0, 0, 0, 0)',
        },

        '100%': {
          transform: 'scale(0.95)',
          boxShadow: '0 0 0 0 rgba(0, 0, 0, 0)',
        },
      },
    },
  }));

  useEffect(() => {
    if (unreadMessages > 0) {
      setShowNotificationDot(true);
    } else {
      setShowNotificationDot(false);
    }
  }, [unreadMessages]);

  return (
    <Link to="/notifications" className="profilePhotoLink">
      <div className="profilePhoto">
        <Tooltip
          TransitionComponent={Zoom}
          title={unreadMessages ? 'You have messages' : 'Notifications'}
          placement="bottom"
          enterDelay={500}
          leaveDelay={200}
        >
          {showNotificationDot ? (
            <StyledBadge overlap="circular" anchorOrigin={{ vertical: 'top', horizontal: 'right' }} variant="dot">
              <Avatar sx={{ bgcolor: lightBlue[500] }}>
                {profile?.avatar ? (
                  <img src={profile.avatar} className="profileImageSmall" alt="profile" />
                ) : (
                  avatarText
                )}
              </Avatar>
            </StyledBadge>
          ) : (
            <Avatar sx={{ bgcolor: lightBlue[500] }}>
              {profile?.avatar ? <img src={profile.avatar} className="profileImageSmall" alt="profile" /> : avatarText}
            </Avatar>
          )}
        </Tooltip>
      </div>
    </Link>
  );
}

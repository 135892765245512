import { ReactElement, useEffect } from 'react';
import { MyLessonData } from '../../models/absorb/lessons';
import { postLessonAttempt, updateLessonActivity } from '../../services/lesson';
import { errorHandler } from '../../utils/helper';
import './PlayingInNewWindowOverlay.css';

interface Props {
  myLesson: MyLessonData;
}

export function PlayingInNewWindowOverlay({ myLesson }: Props): ReactElement {
  useEffect(() => {
    window.open(
      myLesson.source,
      '_blank',
      `width=${window.screen.availWidth - 100},height=${window.screen.availHeight - 100}`
    );
    postLessonAttempt(myLesson.id)
      .then((data) => {
        updateLessonActivity(myLesson.id, data.attemptId, { verb: 'Finish', status: 'Complete' });
      })
      .catch(errorHandler);
  }, [myLesson.id]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="newWindowOverlayContainer">
      <div>Your course is playing in a new window.</div>
      <div>If you do not see the course, please ensure you have allowed pop-ups in your browser for this web page.</div>
    </div>
  );
}

import { ReactElement } from 'react';
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';

import { HelmetProvider } from 'react-helmet-async';
import { LanguageProvider } from './contexts/languageContext';
import { LoginProvider } from './contexts/loginContext';
import { ModalProvider } from './contexts/modalContext';
import { ProfileProvider } from './contexts/profileContext';
import { MessageProvider } from './contexts/messageContext';
import { SearchProvider } from './contexts/searchContext';
import { ShoppingProvider } from './contexts/shoppingContext';

import { PrivateRoute } from './components/utils/PrivateRoute';

import { CartPage } from './pages/CartPage';
import { CatalogPage } from './pages/CatalogPage';
import { CheckoutPage } from './pages/CheckoutPage';
import { CourseDetailsPage } from './pages/CourseDetailsPage';
import { CurriculumPage } from './pages/CurriculumPage';
import { DashboardPage } from './pages/DashboardPage';
import { GlobalSearchPage } from './pages/GlobalSearchPage';
import { LoginPage } from './pages/LoginPage';
import { MyCoursesPage } from './pages/MyCoursesPage';
import { PaymentPage } from './pages/PaymentPage';
import { InstructorLedPage } from './pages/InstructorLedPage';
import { TermsAndConditionsPage } from './pages/TermsAndConditionsPage';
import { TranscriptsPage } from './pages/TranscriptsPage';
import { NotificationsPage } from './pages/NotificationsPage';
import { BundlePage } from './pages/BundlePage';
import { ProfilePage } from './pages/ProfilePage';
import { NotFound } from './components/global/NotFound';
import { ResetPasswordPage } from './pages/ResetPasswordPage';
import { GlobalResourcesPage } from './pages/GlobalResourcesPage';

function App(): ReactElement {
  return (
    <Router>
      <HelmetProvider>
        <LanguageProvider>
          <I18nextProvider i18n={i18next}>
            <LoginProvider>
              <ProfileProvider>
                <ShoppingProvider>
                  <ModalProvider>
                    <MessageProvider>
                      <SearchProvider>
                        <Routes>
                          <Route path="/" element={<LoginPage />} />
                          <Route path="/login" element={<LoginPage />} />
                          <Route path="/reset-password/:urlToken" element={<ResetPasswordPage />} />

                          <Route path="/cart" element={<PrivateRoute />}>
                            <Route index element={<CartPage />} />
                          </Route>
                          <Route path="/cart/checkout" element={<PrivateRoute />}>
                            <Route index element={<CheckoutPage />} />
                          </Route>
                          <Route path="/cart/payment" element={<PrivateRoute />}>
                            <Route index element={<PaymentPage />} />
                          </Route>
                          <Route path="/catalog/:categoryId?" element={<PrivateRoute />}>
                            <Route index element={<CatalogPage />} />
                          </Route>
                          <Route path="/course/:courseId" element={<PrivateRoute />}>
                            <Route index element={<CourseDetailsPage />} />
                          </Route>
                          <Route path="/course/:courseId/curriculum/:curriculumId" element={<PrivateRoute />}>
                            <Route index element={<CourseDetailsPage />} />
                          </Route>
                          <Route path="/course-bundle/:courseId" element={<PrivateRoute />}>
                            <Route index element={<BundlePage />} />
                          </Route>
                          <Route path="/curriculum/:courseId" element={<PrivateRoute />}>
                            <Route index element={<CurriculumPage />} />
                          </Route>
                          <Route path="/dashboard" element={<PrivateRoute />}>
                            <Route index element={<DashboardPage />} />
                          </Route>
                          <Route path="/global-search" element={<PrivateRoute />}>
                            <Route index element={<GlobalSearchPage />} />
                          </Route>
                          <Route path="/instructor-led/:courseId" element={<PrivateRoute />}>
                            <Route index element={<InstructorLedPage />} />
                          </Route>
                          <Route path="/my-courses" element={<PrivateRoute />}>
                            <Route index element={<MyCoursesPage />} />
                          </Route>
                          <Route path="/notifications" element={<PrivateRoute />}>
                            <Route index element={<NotificationsPage />} />
                          </Route>
                          <Route path="/profile" element={<PrivateRoute />}>
                            <Route index element={<ProfilePage />} />
                          </Route>
                          <Route path="/terms-and-conditions" element={<PrivateRoute />}>
                            <Route index element={<TermsAndConditionsPage />} />
                          </Route>
                          <Route path="/transcript" element={<PrivateRoute />}>
                            <Route index element={<TranscriptsPage />} />
                          </Route>
                          <Route path="/resources/:categoryId?" element={<PrivateRoute />}>
                            <Route index element={<GlobalResourcesPage />} />
                          </Route>
                          <Route path="*" element={<NotFound />} />
                        </Routes>
                      </SearchProvider>
                    </MessageProvider>
                  </ModalProvider>
                </ShoppingProvider>
              </ProfileProvider>
            </LoginProvider>
          </I18nextProvider>
        </LanguageProvider>
      </HelmetProvider>
    </Router>
  );
}

export default App;

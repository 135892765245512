import { BundleCoursesData, BundleDetailsData } from '../models/absorb/bundle';
import { apiRequest } from '../utils/axios';

export async function fetchBundleDetails(courseId: string): Promise<BundleDetailsData> {
  return apiRequest('GET', `/course-bundles/${courseId}`);
}

export async function fetchBundleCourses(courseId: string): Promise<BundleCoursesData> {
  return apiRequest('GET', `/course-bundles/${courseId}/courses`);
}

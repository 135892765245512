import { ReactElement, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { FormControl, MenuItem, Select, InputLabel, outlinedInputClasses } from '@mui/material';
import styled from 'styled-components';
import { useSearchContext } from '../../contexts/searchContext';
import { SortCoursesBy } from '../../constants/courses';
import whiteCaretDownMini from '../../assets/images/icons/whiteCaretDownMini.png';
import './CardListSortDropdown.css';

const StyledSelect = styled(Select)`
  & .${outlinedInputClasses.notchedOutline} {
    border-color: white;
    border-width: 1px;
    border-radius: 8px;
  }
  &:hover .${outlinedInputClasses.notchedOutline} {
    border-color: white;
    border-width: 1px;
    border-radius: 8px;
  }
  &.${outlinedInputClasses.focused} .${outlinedInputClasses.notchedOutline} {
    border-color: white;
    border-width: 1px;
    border-radius: 8px;
  }
  & .MuiSelect-icon {
    fill: white;
    color: white;
    font-weight: bolder;
    opacity: 1;
    background-color: transparent;
    background-image: url(${whiteCaretDownMini});
    background-repeat: no-repeat;
    margin-top: 7px;
  }
  & .MuiSelect-iconOpen {
    margin-top: -5px;
  }
  '&:before': {
    border-color: color;
  }
  ,
'&:after': {
    border-color: white;
  }
  min-width: 75px;
`;
export function CardListSortDropdown(): ReactElement {
  const location = useLocation();
  const uri = location.pathname;
  const [fullSort, setFullSort] = useState(true);
  const { sortBy, setSortBy, resetPageOffset } = useSearchContext();
  // TODO: feature/E1-1002 const sort = `${SortCoursesBy.Ispinned},${sortBy || SortCoursesBy.Name}`;

  const selectElem = document.getElementById('muiSearchSort');

  useEffect(() => {
    if (uri.includes('/my-courses')) {
      setFullSort(false);
    }
    return () => {
      setSortBy('name' as SortCoursesBy);
      if (uri.includes('/my-courses')) {
        setFullSort(false);
      }
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (
      selectElem &&
      selectElem?.innerHTML !== 'Alphabetical' &&
      selectElem?.innerHTML !== 'Newest' &&
      selectElem?.innerHTML !== 'Price Low to High' &&
      selectElem?.innerHTML !== 'Price High to Low'
    ) {
      setSortBy('name' as SortCoursesBy);
    }
  }, [selectElem, setSortBy]);

  function onChangeFilter(filterValue: SortCoursesBy) {
    resetPageOffset();
    setSortBy(filterValue);
  }

  const divStyle = {
    color: 'white',
  };

  return (
    <div className="cardListSortWrapper">
      <FormControl>
        <InputLabel style={divStyle} id="muiSearchSortLabel" className="muiSearchSortLabel">
          Sort
        </InputLabel>
        <StyledSelect
          id="muiSearchSort"
          labelId="muiSearchSortLabel"
          value={sortBy || ''}
          name="Sort"
          label="Sort"
          defaultValue="name"
          className="muiSearchSort"
          variant="outlined"
          onChange={(e) => onChangeFilter(e.target.value as SortCoursesBy)}
        >
          <MenuItem className="muiSearchSortItem" value="name">
            Alphabetical
          </MenuItem>
          <MenuItem className="muiSearchSortItem" value="-creationDate">
            Newest
          </MenuItem>
          {fullSort ? (
            <MenuItem className="muiSearchSortItem" value="-price">
              Price High to Low
            </MenuItem>
          ) : (
            ''
          )}
          {fullSort ? (
            <MenuItem className="muiSearchSortItem" value="price">
              Price Low to High
            </MenuItem>
          ) : (
            ''
          )}
        </StyledSelect>
      </FormControl>
    </div>
  );
}

import { ReactElement } from 'react';
import { CardListSortDropdown } from '../../controls/CardListSortDropdown';
import './MyCoursesHero.css';

export function MyCoursesHero(): ReactElement {
  return (
    <div className="heroMyCoursesContentWrapper">
      <div className="heroMyCoursesContent">
        <span className="heroMyCoursesContentMessage">My Courses</span>
        <CardListSortDropdown />
      </div>
    </div>
  );
}

import { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export function EmptyCart(): ReactElement {
  const { t } = useTranslation();

  return (
    <div className="fullSizeMessaging">
      <div className="">{t('ShoppingCartEmpty')}</div>
      <br />
      <Link to="/catalog">{t('ContinueShopping')}</Link>
      <span>{` ${t('ToAddItemsToCart')}`}</span>
    </div>
  );
}

import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { BundleCoursesData } from '../../models/absorb/bundle';
import { CatalogCourseData } from '../../models/absorb/catalog';
import { CourseListCard } from '../curriculum/CourseListCard';
import './BundleContent.css';

interface Props {
  bundleCourses: BundleCoursesData;
}

export function BundleContent({ bundleCourses }: Props): ReactElement {
  const { t } = useTranslation();

  return (
    <div className="bundleContentDetailWrapper">
      <div className="bundleContentHeader">{t('BundleContent')}</div>
      <div className="bundleContentHeaderSeparator"></div>

      {bundleCourses &&
        bundleCourses.courses.map((course: CatalogCourseData) => (
          <CourseListCard key={course.id} course={course} progress={course.progress} />
        ))}
    </div>
  );
}

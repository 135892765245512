import { ReactElement } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useLoginContext } from '../../contexts/loginContext';
import { TermsAndConditionsPage } from '../../pages/TermsAndConditionsPage';

export function PrivateRoute(): ReactElement {
  const { loggedIn, termsAndConditions } = useLoginContext();

  if (loggedIn) {
    if (termsAndConditions && !termsAndConditions.currentUserMustAcceptTermsAndConditions) {
      return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <Outlet />
      );
    }

    return <TermsAndConditionsPage />;
  }

  // state: { from: props.location },

  return <Navigate to="/login" />;
}

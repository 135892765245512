import { ReactElement } from 'react';
import { useLocation } from 'react-router-dom';
import './ResetFilter.css';

export function ResetFilter(): ReactElement {
  const location = useLocation();
  const uri = location.pathname;
  type ButtonEvent = React.MouseEvent<HTMLButtonElement>;

  function resetFilters(e: ButtonEvent) {
    e.preventDefault();

    const list = document.querySelectorAll('input[type=checkbox]');
    // eslint-disable-next-line func-names
    Array.prototype.forEach.call(list, function (checkbox) {
      // eslint-disable-next-line no-param-reassign
      checkbox.checked = false;
    });

    if (uri.includes('/global-search')) {
      const searchString = (document.getElementById('search') as HTMLInputElement).value;
      window.location.href = `/global-search?term=${searchString}`;
    } else {
      document.forms[0].submit();
    }
  }

  return (
    <div className="filterResetButtonWrapper">
      <button
        className="filterResetButton"
        onClick={resetFilters}
        value="Reset Filter"
        type="submit"
        name="Reset Filter"
      >
        Reset Filter
      </button>
    </div>
  );
}

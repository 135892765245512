import { CENTS_PER_DOLLAR, MAX_DECIMAL_PLACES, US_DOLLAR_CURRENCY } from '../constants/shoppingCart';

export function formatMoney(currency: string | undefined, amount: number): string {
  let result = '';
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency,
  });

  if (amount !== null) {
    result = formatter.format(amount);
  }

  return result;
}

export function formatToDollar(amountInCents: number): string {
  return `$${(amountInCents / CENTS_PER_DOLLAR).toFixed(MAX_DECIMAL_PLACES)} ${US_DOLLAR_CURRENCY}`;
}

export function formatToDollaNoCurrencyCode(amountInCents: number): string {
  return `$${(amountInCents / CENTS_PER_DOLLAR).toFixed(MAX_DECIMAL_PLACES)}`;
}
